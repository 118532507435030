<template>
<div>
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <b-overlay :show="codesLoading" variant="white">
    <b-table-simple :borderless="borderless" :outlined="outlined" :class="tableClass" :style="tableStyle">
        <b-thead>
            <b-tr>
                <b-th>
                    ICD-10
                </b-th>
                <b-th>
                    Description
                </b-th>
                <b-th v-if="claimId">
                    Pointer
                </b-th>
                <b-th>
                </b-th>
            </b-tr>
        </b-thead>
        <b-tbody v-if="codes.length > 0">
            <b-tr v-for="(code, index) in codes" :key="index">
                <b-td>
                    <div class="mt-2">
                        {{code.code || "-"}}
                    </div>
                </b-td>
                <b-td style="overflow-wrap:break-word">
                    <div class="mt-2">
                        {{code.description || "N/A"}}
                    </div>
                </b-td>
                <b-td v-if="claimId">
                    <div class="mt-2">
                        {{code.pointer || "-"}}
                    </div>
                </b-td>
                <b-td class="text-right">
                    <b-dropdown class="avo-action-dropdown" no-caret style="min-width:none" :right="true">
                        <template #button-content>
                        <b-icon icon="three-dots"></b-icon>
                        </template>
                        <b-dropdown-item @click="viewEditModal(index)">Edit pointer</b-dropdown-item>
                        <b-dropdown-item @click="deleteCode(index)">Remove</b-dropdown-item>
                        <b-modal :id="'edit-icd-modal-'+index" hide-footer hide-header>
                            <div class="avo-header-text" style="text-align:center">
                                Edit ICD Code Pointer
                            </div>
                            <hr>
                            <b-alert
                                v-model="showAlertModal"
                                variant="danger"
                                dismissible>
                                <b>{{errorTextModal}}</b>
                            </b-alert> 
                            <b-row>
                                <b-col>
                                    <b-form-group
                                    id="pointer"
                                    label="Pointer:">
                                    <b-input-group>
                                        <b-form-input type="number" min="0" v-model="newPointer"></b-form-input>
                                    </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col style="text-align:right">
                                    <b-button @click="closeEditModal(index)" class="avo-basic-btn mr-2">
                                        Cancel
                                    </b-button>
                                    <b-button @click="submitEdit(index)" class="avo-primary-btn">
                                        Submit New Pointer
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-modal>
                    </b-dropdown>
                </b-td>
            </b-tr>
        </b-tbody>
        <b-tbody v-else>
            <div class="no-data-text">
                No ICD codes to display.
            </div>
        </b-tbody>
        <b-tfoot>
            <b-tr>
                <b-td colspan="4">
                    <div>
                        <b-button v-b-modal.new-icd-code class="avo-primary-btn mr-2" style="width:150px;height:30px">
                            <b-icon class="mr-2" icon="plus-circle"></b-icon>
                            Add ICD code
                        </b-button>
                        <icd-code-add :claim-id="claimId" :preauth-id="preauthId" :current-codes="codes" @submitted="getCodes()" />
                    </div>
                </b-td>
            </b-tr>
        </b-tfoot>
    </b-table-simple>
    </b-overlay>
</div>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from '../../common.js'
import IcdCodeAdd from '../codes/IcdCodeAdd'

export default {
    name: 'IcdCodesList',
    props: {
        claimId: {
            type: Number,
            default: null,
        }, 
        preauthId: {
            type: Number,
            default: null,
        },
        tableClass: {
            type: String,
            default: "shadow"
        },
        tableStyle: {
            type: String,
            default: "border:solid 1px #E2E4EB;font-size:14px;"
        },
        borderless: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: true,
        },
    },
    mixins: [alertsMixin],
    components: {
        IcdCodeAdd
    },
    data() {
        return {
            codes: [],
            header_checkbox: false,
            row_checkbox: [],
            showDeleteButton: false,
            newPointer: 0,
            requests: 0,
            
            codesLoading: true,
            showAlertModal: false,
            errorTextModal: "",
        }
    },
    methods: {
        getCodes: function(){
            let url;
            if (this.claimId !== null) {
                url = make_url("/reports/claims/" + this.claimId + "/icd_codes");
            } else if (this.preauthId !== null) {
                url = make_url("/preauths/" + this.preauthId + "/icd_codes");
            }
            const self = this;
            const promise = authenticated_request({
                method: "get",
                url: url
            }).then(function(response){
                self.codes = response.data.codes || response.data.preauth_icdcodes || [];
                self.setShowAlert(false);
            }).catch(function(error){
                self.setShowAlert(true, error);
            }).finally(()=>{
                self.codesLoading = false;
            });
            return promise;
        },
        deleteCode: function(index){
            let url;
            if (this.claimId !== null) {
                url = make_url("/claims/" + this.claimId + "/icd_codes/" + this.codes[index]["code"]);
            } else if (this.preauthId !== null) {
                url = make_url("/preauths/" + this.preauthId + "/icd_codes/" + this.codes[index]["code"]);
            }
            const self = this;
            const promise = authenticated_request({
                method: "delete",
                url: url
            }).then(function(response){
                if (response){
                    self.setShowAlert(false);
                    self.$store.commit("setUpdateCPTCodes", true);
                    self.getCodes();
                }
            }).catch(function(error){
                self.setShowAlert(true, error);
            });
            return promise;
        },
        viewEditModal(index){
            this.newPointer = this.codes[index].pointer;
            this.$bvModal.show('edit-icd-modal-'+index);
        },
        closeEditModal(index){
            this.newPointer = 0;
            this.setShowAlert(false);
            this.$bvModal.hide('edit-icd-modal-'+index);
        },
        submitEdit(index){
            let url;
            if (this.claimId !== null) {
                url = make_url("/claims/" + this.claimId + "/icd_codes/" + this.codes[index]["code"]);
            } else if (this.preauthId !== null) {
                url = make_url("/preauths/" + this.preauthId + "/icd_codes/" + this.codes[index]["code"]);
            }
            const self = this;
            const promise = authenticated_request({
                method: "put",
                url: url,
                data: {
                    'pointer': this.newPointer
                }
            }).then(function(response){
                if (response){
                    self.errorTextModal = "";
                    self.showAlertModal = false;
                    self.getCodes();
                    self.$store.commit("setUpdateCPTCodes", true);
                    self.closeEditModal(index);
                }
            }).catch(function(error){
                self.errorTextModal = error;
                self.showAlertModal = true;
            });
            return promise;
        }
    },
    async mounted() {
        this.getCodes();
    }
}
</script>

<style scoped>
.no-data-text {
    padding: 16px 0px;
    font-size: 15px;
    font-weight: 200;
    text-align: center;
}
</style>