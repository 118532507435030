<template>
<b-container class="p-0 pt-3">
    <b-row v-if="mode==='list'" align-v="center" align-h="between" class="px-5">
        <b-col sm="12" md="7" lg="6" class="d-flex align-items-center">
            <b-input-group class="search-group">
                <b-input-group-prepend>
                    <span class="input-group-text"><b-icon icon="search"></b-icon></span>
                </b-input-group-prepend>
                <b-form-input
                    @keyup="debounceNotes"
                    @search="debounceNotes"
                    class="note-search-input pl-1"
                    type="search" 
                    v-model="search.terms"
                    placeholder="Try typing a note's title"/>                
                <b-input-group-append >
                    <span class="divider"/>
                    <span class="divider"/>
                    <b-dropdown
                        class="dropdown filter-dropdown"
                        :boundary="'window'"
                        ref='dropdown'>
                        <template #button-content >
                        <b-icon-funnel class="mr-2" />{{filterInputLabel}}
                        </template>
                        <b-dropdown-form class="d-flex flex-column align-items-start">
                        <span style="font-size:20px;font-weight:300">Filters</span>
                        
                        <div class="selecter-label">Note Type</div>
                        <multiselect 
                            v-model="search.types"
                            :hide-selected="true"
                            :options="types"
                            track-by="type" 
                            label="label"
                            :multiple="true"
                            :show-labels="false"
                            placeholder="-- Select an Option --">
                        </multiselect>
                        
                        <div class="selecter-label">Worker</div>
                        <multiselect 
                            v-model="search.workers"
                            :hide-selected="true"
                            :options="workers"
                            track-by="worker_id" 
                            label="name"
                            :multiple="true"
                            :show-labels="false"
                            placeholder="-- Select an Option --">
                        </multiselect>

                        <div class="selecter-label">Created After</div>
                        <b-form-input type="date"
                            ref="date1"
                            value-as-date
                            locale="en"
                            class="date-input"
                            v-model="search.start_date">
                        </b-form-input>

                        <div class="selecter-label">Created Before</div>
                        <b-form-input type="date"
                            ref="date2"
                            value-as-date
                            locale="en"
                            class="date-input"
                            v-model="search.end_date">
                        </b-form-input>
                        
                        <div class="d-flex justify-content-end mt-4">
                            <b-button @click="onClearFilters" variant="outline-primary" class="avo-basic-btn mr-2">Clear Filters</b-button>
                            <b-button @click="getNotes" variant="outline-primary" class="avo-primary-btn">Apply</b-button>
                        </div>
                        </b-dropdown-form>
                    </b-dropdown>
                </b-input-group-append>
            </b-input-group>
        </b-col>
        <b-col sm="12" md="5" lg="6" class="text-right">
            <b-button v-b-modal.new-note variant="outline-primary" class="avo-primary-btn mt-4 mt-md-0">
                <b-icon icon="plus" class="mr-2"/>Add Note
            </b-button>
            <note-add :claimId="claimId" :preauthId="preauthId" @noteAdded="onNotesUpdate"/>
        </b-col>
    </b-row>
    <b-row v-else-if="mode==='inline' && showInlineHeader">
        <b-col>
            <div class="d-flex justify-content-between px-4">
                <div class="label-text" style="text-decoration:underline var(--blue) 3px;text-underline-offset:1.2rem">Notes</div>
                <div v-if="!hideViewAll" class="avo-text-light">View all notes</div>
            </div>
            <hr class="w-100">
        </b-col>
    </b-row>
    <b-overlay :show="notesLoading" variant="white" style="min-height:100px;">
    <template v-if="mode==='list'">
        <div class="p-5">
            <b-row>
            <b-col class="col-12 col-md-6 col-lg-4" v-for="note in notes" :key="note.note_id">
                <note-card 
                    :key="note.note_id"
                    :note="note"
                    :mode="'list'"/>
            </b-col>
            </b-row>
        </div>
    </template>
    <template v-else-if="mode==='inline' && showInlineHeader">
        <div class="pt-4 pr-4 pl-4">
            <b-row>
            <b-col>
            <note-card v-for="note in notes"
                :key="note.note_id"
                :note="note"
                :mode="'inline'"/>
            </b-col>
            </b-row>
        </div>
    </template>
    <template v-else-if="mode==='inline' && !showInlineHeader">
        <div class="">
            <b-row>
            <b-col>
            <note-card v-for="note in notes"
                :key="note.note_id"
                :note="note"
                :mode="'inline'"/>
            </b-col>
            </b-row>
        </div>
    </template>
    <div v-if="notesLoading" class="no-notes pt-4">
        Fetching notes
    </div>
    <div v-if="notes.length==0 && !notesLoading" class="no-notes pt-4">
        No notes found
    </div>
    <b-row v-if="showPagination && notes.length > 0">
      <b-col md="12" class="">
            <b-pagination :total-rows="totalRows" :per-page="perPageVar" v-model="currentPage" align="center" />
      </b-col>
    </b-row>
    </b-overlay>
</b-container>
</template>

<script>
import { make_url, authenticated_request, debounce } from '../../common.js'
import Multiselect from 'vue-multiselect'

import NoteCard from "./NoteCard.vue"
import NoteAdd from "./NoteAdd.vue"
export default {
	name: 'NotesList',
	components: {
        Multiselect,
        NoteCard,
        NoteAdd
    },
	props: [
        'claimId',
        'preauthId',
        'mode',
        'limitFirstN',
        'hidePagination',
        'hideHeader',
        'hideViewAll'
    ],
	data() {
		return {
			search: {
                terms: "",
                types: [],
                workers: [],
                start_date: "",
                end_date: "",
            },
            search_params: {
                terms: "",
                types: "",
                worker_ids: ""
            },
            types: [],
            workers: [],
            perPageVar: 6,
            currentPage: 1,
            showPagination: !this.hidePagination,
            showInlineHeader: !this.hideHeader,
		}
	},
	methods: {
        clearSearchFilters: function(){
            this.search.terms = "";
            this.search.types = [];
            this.search.workers = [];
            this.search.start_date = "";
            this.search.end_date = "";
        },
        clearSearchParams: function(){
            this.search_params.terms = "";
            this.search_params.types = "";
            this.search_params.worker_ids = "";
        },
        onClearFilters: function(){
            this.clearSearchFilters();
            this.clearSearchParams();
            this.getNotes();
        },
        getFilterOptions: function(){
            const url = make_url("/reports/notes/" + this.claimId + "/filters");
            const vm = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
            }).then(function(response){
                const items = response.data;
                vm.types = items.types;
                vm.workers = items.workers;
                return items || [];
            });
            return promise;
        },
        searchNotes: function() {
            if (this.search.terms.length > 2 || this.search.terms.length == 0) {
                this.getNotes();
            }
        },
		getNotes: function(){
			var params = {
                per_page: this.perPageVar,
                page: this.currentPage,
                search_terms: this.search_params.terms,
                types: this.search_params.types,
                worker_ids: this.search_params.worker_ids,
			}
            if (this.search.start_date){
                params.start = this.search.start_date + "T00:00:00+00:00"
            }
            if (this.search.end_date){
                params.end = this.search.end_date + "T00:00:00+00:00"
            }
            if (this.claimId) {
                params.claim_id = this.claimId
            } else if (this.preauthId) {
                params.preauth_id = this.preauthId
            }

            this.$store.dispatch('fetchNotesList', params);
		},
        onNotesUpdate: function(){
            this.getNotes();
            if (this.mode !== 'inline') {
                this.getFilterOptions();
            }
        },
	},
    computed: {
        searchIsEmpty(){
            return this.search.terms.length == 0 
                    && this.search.types.length == 0 
                    && this.search.workers.length == 0 
                    && this.search.start_date.length == 0 
                    && this.search.end_date.length == 0;
        },
        notes: function(){
            const notes = this.$store.state.claims.notesList?.notes || [];
            if (this.limitFirstN) {
                return notes.slice(0, this.limitFirstN);
            } else {
                return notes;
            }
        },
        totalRows: function(){
            return this.$store.state.claims.notesList?.pagination?.total || 0;
        },
        notesLoading: function(){
            if (this.claimId && this.$store.state.claims.notesListClaimId === this.claimId) {
                return false;
            }
            else if (this.preauthId && this.$store.state.claims.notesListPreauthId === this.preauthId) {
                return false;
            }
            else {
                return true;
            }
        },
        filterInputLabel: function(){
            const count = this.filterCount;
            if(count == 0) {
                return "Add Filters";
            } else {
                return count + " Filter(s)";
            }
        },
        filterCount: function() {
            var count = 0;
            for(const filter in this.search){
                if((this.search[filter].length || ((filter === 'start_date' || filter === 'end_date') && this.search[filter])) && filter !== "terms"){
                    count += 1;
                }
            }
            return count;
        },
    },
    watch: {
        mode: function(val, oldVal){
            if (val === 'list' && oldVal === 'inline'){
                this.getFilterOptions();
            }
        },
        currentPage: function(){
            this.onNotesUpdate();
        },
        claimId: function(){
            this.onNotesUpdate();
        },
        preauthId: function(){
            this.onNotesUpdate();
        },
        'search.terms': function(newValues) {
            this.search_params.terms = newValues;
        },
        'search.types': function(newValues) {
            this.search_params.types = newValues.map(obj => obj.type).join();
        },
        'search.workers': function(newValues) {
            this.search_params.worker_ids = newValues.map(obj => obj.worker_id).join();
        },
    },
    async mounted(){
        if (this.notesLoading) {
            this.getNotes();
        }
        // No search provided if inlined
        if (this.mode !== 'inline'){
            this.getFilterOptions();
        }
    },
    created() {
        this.debounceNotes = debounce(this.searchNotes, 800);
    },
}
</script>

<style scoped>
.no-notes {
  font-size: 16px;
  font-weight: 200;
  text-align: center;
}

/* Styling the search bar group */
::v-deep .search-group {
    position: relative;
    border: 1px solid #C9D2DF;
    border-radius: 5px;
    max-width:650px;
}
::v-deep .note-search-input, .input-group-text {
    height:50px;
    border: none;
    border-radius: 5px;
    background: white;
    color: #9CA3AD; 
}
::v-deep .note-search-input::placeholder {
    color: #9CA3AD;
    font-size:12px;
    opacity: 1;
}
::v-deep .b-dropdown .btn-secondary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 5px;
    color: #9CA3AD;
    background: white;
    font-size:12px;
    height:50px;
    padding-right: 35px;
    min-width:150px;
    text-align:left;
}
::v-deep .b-dropdown .btn-secondary:active,
::v-deep .btn-secondary:not(:disabled):not(.disabled):active, 
::v-deep .btn-secondary:not(:disabled):not(.disabled).active, 
::v-deep .show > .btn-secondary.dropdown-toggle {
    color:#519EFF !important;
    background-color: white !important;
}
::v-deep .b-dropdown .btn-secondary:focus {
    box-shadow:none !important;
}
.divider {
    display: inline-block;
    width: 0;
    height: 20px;
    margin: auto;
    border-right: 1.8px solid #D2D8E2;
}

/* dropdown menu-related styling */
::v-deep .filter-dropdown .b-dropdown-form {
    padding: 20px 30px 20px 30px;
}
::v-deep .filter-dropdown .dropdown-menu {
    width:100.4%;
    right: -1px !important;
    left: auto !important;
    top:45px !important;
    transform: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 16px 48px #1617182B !important;
}
.selecter-label {
    color: #64728C;
    font-size: 14px;
    font-weight: 500;
    margin-top: 0.8rem;
    margin-bottom:5px;
}
</style>