<template>
<div>
    <b-row>
        <b-col>
            <b-alert
                v-model="showAlert"
                variant="danger"
                dismissible>
                <b>{{alertMessage}}</b>
            </b-alert>
        </b-col>
    </b-row>
    <b-row class="mt-4">
        <b-col cols="4">
            <div class="d-flex align-items-center justify-content-between mb-2">
                <div class="avo-header-text-med" >Billing Summary </div>
                <b-button @click="showEditRepsonsibilitiesModal()" class="avo-basic-btn" variant="outline-primary" style="min-width:0px !important;">
                    Set Responsibility <b-icon icon="pencil" class="ml-2"></b-icon>
                </b-button>
                <payment-responsibility-edit :responsiblePartyData="responsibleParty" @submitted="getResponsibleParty"/>
            </div>
            <b-overlay :show="codesLoading" variant="white">
            <div class="billing-summary">
                <div class="bold d-flex justify-content-between" style="font-size:18px;">
                    <div>
                        Total charged
                    </div>
                    <div>
                        {{formatDollars(claim.invoiced)}}
                    </div>
                </div>
                <hr style="margin-top:10px;margin-bottom:5px">
                <div class="d-flex justify-content-between mb-2">
                    <div>
                        Insurance payments
                    </div>
                    <div>
                        {{formatDollars(groupedCodesAndTotals.payerPaidTotal)}}
                    </div>
                </div>
                <div v-for="code in groupedCodesAndTotals.payerPaidCodes" :key="'payer_resp'+code.claim_code_id" class="d-flex justify-content-between mb-1 avo-text-light">
                    <div>
                        {{code.code}}: <span class="avo-text-light-small">{{code.short}}</span>
                    </div>
                    <div class="ml-1">
                        {{formatDollars(code.total_payer_payments)}}
                    </div>
                </div>
                <hr style="margin-top:10px;margin-bottom:5px">
                <div class="d-flex justify-content-between mb-2">
                    <div>
                        Patient payments
                    </div>
                    <div>
                        {{formatDollars(groupedCodesAndTotals.patientPaidTotal)}}
                    </div>
                </div>
                <div v-for="code in groupedCodesAndTotals.patientPaidCodes" :key="'patient_resp'+code.claim_code_id" class="d-flex justify-content-between mb-1 avo-text-light">
                    <div>
                        {{code.code}}: <span class="avo-text-light-small">{{code.short}}</span>
                    </div>
                    <div class="ml-1">
                        {{formatDollars(code.total_patient_payments)}}
                    </div>
                </div>
                <hr style="margin-top:10px;margin-bottom:5px">
                <div class="claim-bottom-bar responsibility-bar mb-1">
                <!-- TODO: AV-664 Add responsible party name below -->
                    <span class="mr-3">Claim out to:  </span> <strong>{{ responsiblePartyName }}</strong>
                </div>
                <div class="claim-bottom-bar total-paid-billing">
                    <span class="mr-3">Total paid:  </span> <strong>{{formatDollars(groupedCodesAndTotals.totalPaid)}}</strong>
                </div>
            </div>
            </b-overlay>
        </b-col>
        <b-col cols="8" class="pl-0">
            <div class="avo-header-text-med mb-3">Itemized CPT Codes</div>
            <b-overlay :show="codesLoading" variant="white" style="height:75%">
                <b-table-simple sticky-header borderless class="avo-simple-table mb-0">
                    <b-thead style="border-bottom:solid 1px #E2E4EB;font-size:14px">
                        <b-tr>
                            <b-th>
                                CODE
                            </b-th>
                            <b-th>
                                DESC
                            </b-th>
                            <b-th>
                                LAST PAYMENT ID
                            </b-th>
                            <b-th class="text-right">
                                CHARGED
                            </b-th>
                            <b-th class="text-right">
                                PAID
                            </b-th>
                            <b-th class="text-right">
                                ADJ.
                            </b-th>
                            <b-th class="text-right">
                                BALANCE
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="code in cptCodeReportData.codes" :key="'latest_payment'+code.claim_code_id" style="border-bottom:solid 1px #E2E4EB">
                            <b-td>
                                {{code.code}}
                            </b-td>
                            <b-td>
                                <span class="avo-text-light-small">{{code.short}}</span>
                            </b-td>
                            <b-td>
                                <span v-if="code.latest_payment_id">#{{code.latest_payment_id}}</span>
                                <span v-else>N/A</span>
                            </b-td>
                            <b-td class="text-right">
                                {{formatDollars(calculateChargedAmount(code))}}
                            </b-td>
                            <b-td class="text-right">
                                {{formatDollars(Number(code.total_patient_payments || 0) + Number(code.total_payer_payments || 0))}}
                            </b-td>
                            <b-td class="text-right">
                                {{formatDollars(code.total_adjustments)}}
                            </b-td>
                            <b-td class="text-right">
                                {{formatDollars(calculateCodeBalance(code))}}
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
                <b-table-simple>
                <b-tfoot style="border:solid 1px #E2E4EB;border-top:0px;font-size:14px;">
                        <b-tr>
                            <b-th colspan="11">
                                Current Totals
                            </b-th>
                            <b-th /> <b-th /> <b-th /> <b-th /> <b-th /> <b-th />
                            <b-th /> <b-th /> <b-th /> <b-th /> <b-th /> <b-th /> 
                            <b-th class="text-right">
                                {{formatDollars(groupedCodesAndTotals.totalCharged)}}
                            </b-th>
                            <b-th class="text-right">
                                {{formatDollars(groupedCodesAndTotals.totalPaid)}}
                            </b-th>
                            <b-th class="text-right">
                                {{formatDollars(groupedCodesAndTotals.totalAdjustments)}}
                            </b-th>
                            <b-th class="text-right">
                                {{formatDollars(groupedCodesAndTotals.totalBalance)}}
                            </b-th>
                        </b-tr>
                    </b-tfoot>
                </b-table-simple>
            </b-overlay>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <div class="d-flex justify-content-between align-items-center my-4">
                <div class="d-flex flex-column">
                    <div class="avo-header-text-med">Itemized Payments</div>
                </div>
                <b-button @click="showAddPaymentModal()" 
                        variant="outline-primary" 
                        class="avo-primary-btn" 
                        style="width:160px;height:30px;"
                        v-if="hasPermission('post_payments')"
                        >
                    <b-icon class="mr-2" icon="plus-circle"></b-icon>
                    Add Payment
                </b-button>
            <claim-payment-add 
                :claim-id="claim.claim_id" 
                :payer-id="claim.payer_id" 
                :patient-id="claim.patient_id" 
                :codesData="addPaymentData" 
                :era-list=eras 
                v-if="hasPermission('post_payments')"
                @submitted="getCodes()" />  
            </div>
        </b-col>
    </b-row>
    <itemized-payments></itemized-payments>
</div>
</template>

<script>
// import Multiselect from 'vue-multiselect'
import { make_url, authenticated_request, permissionsMixin, alertsMixin, formatDollars } from '../../common.js'

import PaymentResponsibilityEdit from '../codes/PaymentResponsibilityEdit.vue'
import ClaimPaymentAdd from "../claims/ClaimPaymentAdd.vue"
import ItemizedPayments from '../payments/ItemizedPayments.vue'

export default {
    name: 'PaymentsBilling',
    props: [],
    mixins: [permissionsMixin, alertsMixin],
    components: {
          PaymentResponsibilityEdit,
          ClaimPaymentAdd,
          ItemizedPayments,
    },
    data: function(){
            return {
                cptCodeReportData: {},
                addPaymentData: null,
                eras: [],
                
                codesLoading: true,
                responsibleParty: null,
            }
    },
    methods: {
        formatDollars,
        getResponsibleParty: function(){
            const url = make_url('/reports/claims/'+this.claim.claim_id+'/responsible_party')
            const self = this;
            authenticated_request({
                url, 
                method: 'GET',
            }).then(
                function(response){
                    self.responsibleParty = response.data;
                }
            ).catch(
                console.log('error')
            )
        },
        getCodes: function(){
            const url = make_url("/reports/claims/" + this.claim.claim_id + "/cpt_codes");
            const self = this;
            const promise = authenticated_request({
                method: "get",
                url: url
            }).then(function(response){
                self.cptCodeReportData = response.data;
            }).catch(function(error){
                console.log(error.response)
            }).finally(()=>{
                self.codesLoading = false;
            });
            return promise;
        },
        updateBillingCodes: function(newCodeData){
            this.cptCodeReportData = newCodeData;
        },
        showEditRepsonsibilitiesModal(){
            this.$bvModal.show('edit-payment-responsibilities');
        },
        showAddPaymentModal(){
            this.addPaymentData = {
                'payerResponsibility': JSON.parse(JSON.stringify(this.groupedCodesAndTotals.payerPaidCodes)),
                'patientResponsibility': JSON.parse(JSON.stringify(this.groupedCodesAndTotals.patientPaidCodes)),
            }
            this.$bvModal.show('new-claim-payment');
        },
        calculateChargedAmount(code){
            return Number(((code.unit_charge || 0) * (code.units || 0))).toFixed(2)
        },
        calculateCodeBalance(code){
            return Number(((code.unit_charge || 0) * (code.units || 0)) - (Number(code.total_adjustments || 0) + Number(code.total_patient_payments || 0) + Number(code.total_payer_payments || 0))).toFixed(2)
        },

        //methods to handle payment ERA's
        getDownloadUrl(document_id) {
            const vm = this;
            const url = make_url("/documents/" + document_id + "/download");
            const promise = authenticated_request({
                method: "get",
                url: url,
            }).then(function(response){
                const fullUrl = new URL(response.data, process.env.VUE_APP_API_URL);
                vm.downloadFile(fullUrl, document_id)
            }, function(error){
                vm.showError(error);
            });
            return promise;
        },
        downloadFile(url, document_id) {
            const vm = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
                responseType: 'blob'
            }).then(function(response){
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                var title = vm.getFileName(document_id);
                link.setAttribute('download', title);
                document.body.appendChild(link);
                link.click();
            }).catch(function(error){
                vm.showError(error);
            });
            return promise;
        },
        getFileName(document_id){
            for (var i = 0; i < this.eras.length; i++){
                if (document_id == this.eras[i].document_id)
                {
                    return this.eras[i].title;
                }
            }
            //no title found- default filename is ERA
            return "ERA";
        },
        //error methods
        showError(alertMessage){
            this.setShowAlert(true, alertMessage);
        },
        hideError(){
            this.setShowAlert(false);
        },
    },
    computed: {
        claim() {
            return this.$store.state.claims.workedClaim;
        },
        groupedCodesAndTotals(){
            var returnObj = {
                payerPaidCodes: [],
                payerPaidTotal: 0,
                patientPaidCodes: [],
                patientPaidTotal: 0,

                totalCharged: 0,
                totalPaid: 0,
                totalAdjustments: 0,
                totalBalance: 0,
            }
            if(!this.cptCodeReportData.codes){
                return returnObj 
            }

            this.cptCodeReportData.codes.forEach(function(code){
                returnObj.totalAdjustments += (Number(code.total_adjustments || 0))
                returnObj.totalPaid += (Number(code.total_patient_payments || 0) + Number(code.total_payer_payments || 0))
                // Add patient payment totals
                returnObj.patientPaidCodes.push(code);
                returnObj.patientPaidTotal += Number(code.total_patient_payments || 0)
                // Add payer payment totals
                returnObj.payerPaidCodes.push(code);
                returnObj.payerPaidTotal += Number(code.total_payer_payments || 0)
                
                returnObj.totalCharged += Number(code.unit_charge || 0) * Number(code.units || 0)
            });
            returnObj.totalCharged = (returnObj.totalCharged).toFixed(2);
            returnObj.totalBalance = (returnObj.totalCharged - returnObj.patientPaidTotal - returnObj.payerPaidTotal - returnObj.totalAdjustments).toFixed(2)
            returnObj.totalAdjustments = (returnObj.totalAdjustments).toFixed(2)
            return returnObj
        },
        updateBilling: function(){
            return this.$store.state.claims.updateBilling;
        },
        responsiblePartyName: function(){
            if(this.responsibleParty){
                if (this.responsibleParty.responsible_party_type == 'PATIENT')
                    return 'Patient'
                else if (this.responsibleParty.responsible_party_type == 'INSURANCE')
                    return this.responsibleParty.responsible_payer_name || 'Insurance'

            }
            return 'Unknown';
        },
    },
    watch:{
        updateBilling: function() {
            if (this.updateBilling){
                this.getCodes();
                this.$store.commit("setUpdateBilling", false);
            }
        },
    },
    created() {
    },
    mounted: function() {
        this.getCodes();
        this.getResponsibleParty();
        const self = this;
        authenticated_request({
            method: "get",
            url: make_url("/claims/" + this.claim.claim_id + "/payments/eras")
        }).then(function(response){
            self.eras = (response.data.eras || [])
        }).catch(function(errors){
            self.showError(errors);
        });
}
}
</script>

<style scoped>
.alert {
    background: #FDEEED 0% 0% no-repeat padding-box;
    border: 1px solid var(--red);
    border-radius: 5px;
    text-align: center;
    letter-spacing: 0px;
    color: var(--red);
}
.claim-sidebar {
    border-right: 1px solid #c9d2df;
}
.nav {
    display: unset;
}
.nav-claim .nav-link {
    color: var(--gray);
}
.nav-claim .nav-link:hover{
    border:none;
    color: var(--dark);
}
.avo-primary-btn {
    margin: 5px;
    min-width: 0px;
    width: 50px;
}
/* nav bar styling (overrides) */
.nav-section {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #E6EEF9;
    margin-left: -35px;
    margin-right: -35px;
    padding-right: 35px;
    padding-left: 35px;
}
.avo-simple-table {
    border:solid 1px #E2E4EB;
    font-size:14px;
    max-height:100%
}
.billing-summary {
    border: 1px solid #c9d2df;
    border-radius: 5px;
    background: #F8FAFD;
    padding:16px;
    padding-bottom:8px;
}
.claim-bottom-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: -8px;
    margin-right: -8px;
    padding-right: 8px;
    height: 32px;
    border-radius: 10px;
    font-size: 14px;
    color:white;
}
.responsibility-bar {
    background: transparent linear-gradient(102deg, var(--red) 0%, var(--dark-red) 100%) 0% 0% no-repeat padding-box;
}
.total-paid-billing {
    background: transparent linear-gradient(102deg, var(--blue-700) 0%, var(--dark-blue) 100%) 0% 0% no-repeat padding-box;
}
.effective-box{
    padding:5px;
    padding-left: 10px;
    padding-right: 10px;
    background:#eef1fa;
    border-radius:5px;
}
</style>
