<template>
<div class="header-section">
<b-container>
    <div class="mb-3">
      <b-row>
          <b-col>
            <div class="alert-div">
              <b-alert
                  :show="showAlert"
                  dismissible
                  variant="danger">
                  <b>{{alertMessage}}</b>
              </b-alert>
            </div>
          </b-col>
        </b-row>
        <b-row>
            <b-col cols="4" class="pr-0 pl-0">
                <b-card v-if="claim.patient" class="avo-info-card mr-auto">
                  <div class= "d-flex justify-content-between">
                    <div class="avo-header-text">{{claim.patient_name || "-"}}</div>
                    <div>
                    <b-icon v-if="!editPatientLoading" @click="$bvModal.show('edit-patient')" icon="pencil" style="cursor:pointer"
                    v-b-popover.hover.bottom="'Edit patient'"></b-icon>
                    <patient-edit-modal :patientId="claim.patient_id" :patient="claim.patient" :claimId="claim.claim_id" :issues="issues" 
                    @loaded="editPatientLoading = false"/>
                    </div>
                  </div>
                    <b-row class="mt-3 mb-3">
                        <b-col cols="12" lg="6" md="5">
                            <div class="d-flex flex-column">
                                <div class="label-text">Account No.</div>
                                <div class="data-text">{{claim.patient ? (claim.patient.account_no || "-") : "-"}}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="6" md="5">
                            <div class="d-flex flex-column">
                                <div class="label-text">DOB</div>
                                <div class="data-text">{{claim.patient ? formatDOB(claim.patient.date_of_birth) : "-"}}</div>
                            </div>
                        </b-col>
                    </b-row>
                    <div class="d-flex justify-content-between flex-wrap">
                        <span class="data-text"><b-icon icon="telephone" /> {{claim.patient ? (claim.patient.telephone || "-") : "-"}}</span>
                        <a v-if="claim.patient" :href="'mailto:'+claim.patient.email" class="data-text"><b-icon icon="envelope" /> {{claim.patient.email || "-"}}</a>
                    </div>
                </b-card>
                <div v-else style="padding:20px">
                  <b-button v-b-modal.new-patient class="avo-primary-btn">
                    Add Patient
                  </b-button>
                  <patient-add-modal :claim-id="claim.claim_id" :issues="issues" />
                </div>
            </b-col>
            <b-col cols="6">
                <div class="d-flex flex-column justify-content-around h-100 px-2" style="max-width:800px">
                    <div class="d-flex justify-content-between align-items-center flex-wrap">
                        <div class="avo-header-text"><span style="font-weight:300">Claim ID:</span> {{claim.claim_id}}</div>
                        <div v-if="!hasPermission('assign_claims')" class="d-flex justify-content-start align-items-center">
                            <b-avatar v-if="issues ? claim.issues_worker : claim.worker" :text="issues ? claim.issues_worker_initials : claim.worker_initials" class="mr-2" style="width:32px;height:32px;"></b-avatar>
                            <div v-if="issues ? claim.issues_worker : claim.worker" class="d-flex flex-column align-items-start">
                                <span style="color:black;font-size:12px;">{{issues ? claim.issues_worker_name : claim.worker_name}}</span>
                                <span class="avo-text-light-small" style="margin-top:0px;padding-top:0px">Assigned</span>
                            </div>
                            <div v-else style="color:black;font-size:14px;">Unassigned</div>
                        </div>
                        <div class="d-flex align-items-center ml-2">
                          <!-- Links to modals for quick actions. This is quite hacky: if the components are loaded in then
                              we can grab the modals from the components, otherwise we have to add them here. There should be a
                              better way to check if a component is loaded, but for now we are keeping track of this in the store:
                              this could go wrong easily if a component is destroyed for some reason that we don't register. The 
                              symptom of these kinds of problems would be the modals refusing to open, or opening two of the same
                              modal. -->
                            <b-icon v-b-modal.new-worklog role="button" icon="list-ul" font-size="1.2rem" class="mr-3"
                            v-b-popover.hover.bottom="'Add new worklog'"></b-icon>
                            <work-log-add v-if="!(loadedTabs.includes('WorkClaimWorkLog'))" :claim="claim"/>
                            <b-icon v-b-modal.new-task role="button" icon="calendar-4" font-size="1.2rem" class="mr-3"
                            v-b-popover.hover.bottom="'Add new task'"></b-icon>
                            <task-add v-if="!(loadedTabs.includes('WorkClaimTasks'))" :claim-id="claim.claim_id"/>
                            <b-icon v-b-modal.new-document role="button" icon="paperclip" font-size="1.2rem" class="mr-3"
                            v-b-popover.hover.bottom="'Add new document'"></b-icon>
                            <document-add v-if="!(loadedTabs.includes('WorkClaimDocuments'))" idType="claim" :id="claim.claim_id"  @docUploadError="docUploadError"/>
                            <b-icon v-b-modal.new-note role="button" icon="file-earmark" font-size="1.2rem" class="mr-3"
                            v-b-popover.hover.bottom="'Add new note'"></b-icon>
                            <note-add v-if="!(loadedTabs.includes('WorkClaimNotes'))" :claimId="claim.claim_id" />
                            <b-button v-b-modal.claim-edit :disabled="claimEditLoading" class="avo-primary-compact-btn">Edit Claim</b-button>
                            <claim-edit @loaded="claimEditLoading = false" :issues="issues" />
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-start align-items-center">
                        <div class="d-flex flex-column mr-3">
                        <div class="avo-text-light">Timely Filing</div>
                        <div :class="['data-card', timelyFilingClass(claim.timely_days_left)]">
                            <b-icon class="mr-2" icon="clock" font-scale="1.5"></b-icon>
                            <div class="mr-1">{{claim.timely_days_left}} </div>
                            <span v-if="claim.timely_days_left">days left</span>
                            <span v-else>-</span>
                        </div>
                        </div>
                        <div class="d-flex flex-column mr-3">
                        <div class="avo-text-light">Follow Up</div>
                            <div class="data-card"
                              v-b-tooltip="{ variant: 'dark', placement: 'bottom', title: 'To set a new Follow Up date, create a Task' }"
                            >
                                <b-iconstack class="mr-1 ml-1" style="color:#519eff">
                                <b-icon icon="circle" scale="1.8"></b-icon>
                                <b-icon icon="calendar-4" scale="1"></b-icon>
                                </b-iconstack>
                                <span style="margin-left:5px">
                                {{claim.follow_up ? formatDate(claim.follow_up.due_date) : "-"}}
                                </span>
                            </div>
                        </div>
                        <div v-if="hasPermission('assign_claims')" class="d-flex flex-column mt-1">
                          <span class="avo-text-light"> Assigned</span>
                          <claim-worker-select :issues="issues" />
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col cols="2" class="pl-0 pr-0 ml-auto d-flex flex-column justify-content-around align-items-end">
                <div class="avo-header-text text-right mt-2"> 
                  <span style="font-weight:300">Balance:</span> {{formatDollars(claim.balance)}}
                </div>
                <div v-if="!hasPermission('transition_claims')" class="d-flex flex-column">
                  <div class="avo-text-light">Status</div>
                  <div class="status-box">
                      <div :class="['status-icon',  claim.state.short]"></div>
                      <div>{{claim.state.label}} </div>
                  </div>
                </div>
                <div v-if="hasPermission('transition_claims')">
                  <div class="avo-text-light">Status/Claim Actions</div>
                  <b-overlay :show="transitionLoading">
                    <b-dropdown class="avo-dropdown status-dropdown"
                    text="-- Select an Action --"
                    :no-flip="true">
                        <template #button-content>
                          <div class="d-flex align-items-center">
                            <div :class="['status-icon',  claim.state.short]"></div>
                            <div>{{claim.state.label}} </div>
                        </div>
                        </template>
                        <!-- <b-dropdown-item @click="transitionClaim('open')">Open Claim</b-dropdown-item> -->
                        <b-dropdown-item v-if="!issues" @click="transitionClaim('submit')">Submitted to Payer</b-dropdown-item>
                        <b-dropdown-item v-if="!issues" @click="transitionClaim('pending')">Pending</b-dropdown-item>
                        <b-dropdown-item v-if="!issues" @click="transitionClaim('reject_or_deny')">Rejected/Denied</b-dropdown-item>
                        <b-dropdown-item v-if="!issues" @click="transitionClaim('ar_review')">AR Review</b-dropdown-item>
                        <b-dropdown-item v-if="!issues" @click="transitionClaim('client_mgmt_assistance')">Client/Management Assistance</b-dropdown-item>
                        <b-dropdown-item v-if="!issues" @click="transitionClaim('posting')">Posting</b-dropdown-item>
                        <b-dropdown-item v-if="!issues" @click="transitionClaim('patient_balance')">Patient Balance</b-dropdown-item>
                        <b-dropdown-item v-if="!issues" @click="transitionClaim('close')">Close Claim</b-dropdown-item>
                        <b-dropdown-item v-if="issues" @click="transitionClaim('move_to_claims')">Move to Claims Board</b-dropdown-item>
                        <b-dropdown-item v-if="!issues" @click="transitionClaim('move_to_issues')">Move to Issues Board</b-dropdown-item>
                    </b-dropdown>
                  </b-overlay>
                </div>
                <div class="d-flex flex-column">
                  <div class="avo-text-light">Substatus</div>
                  <div class="status-box">
                      <!-- <div :class="['status-icon',  claim.state.short]"></div> -->
                      <div>{{claim.substatus_1 && worklogOptionsMap ? worklogOptionsMap.claim_substatuses[claim.substatus_1] : 'None'}} </div>
                  </div>
                </div>
                
            </b-col>
        </b-row>
    </div>
    </b-container>
    <div>
        <b-nav tabs class="nav-section" >
            <b-nav-item :active="activeTab == 'WorkClaimSummary'" @click="setActiveTab('WorkClaimSummary')">Summary</b-nav-item>
            <b-nav-item :active="activeTab == 'WorkClaimForm'" @click="setActiveTab('WorkClaimForm')">Details</b-nav-item>
            <b-nav-item :active="activeTab == 'WorkClaimWorkLog'" @click="setActiveTab('WorkClaimWorkLog')">Work Log</b-nav-item>
            <b-nav-item :active="activeTab == 'WorkClaimTasks'" @click="setActiveTab('WorkClaimTasks')">Tasks</b-nav-item>
            <b-nav-item :active="activeTab == 'WorkClaimNotes'" @click="setActiveTab('WorkClaimNotes')">Notes</b-nav-item>
            <b-nav-item :active="activeTab == 'WorkClaimDocuments'" @click="setActiveTab('WorkClaimDocuments')">Documents</b-nav-item>
            <b-nav-item :active="activeTab == 'WorkClaimActivity'" @click="setActiveTab('WorkClaimActivity')">Activity</b-nav-item>
        </b-nav>
        <keep-alive>
          <component :is="activeTab" :issues="issues"></component>
        </keep-alive>
    </div>
</div>
</template>

<script>
import { make_url, 
         authenticated_request, 
         debounce, 
         formatDate, 
         formatDollars,
         permissionsMixin, 
         alertsMixin,
         } from '../../common.js'
import { mapState } from 'vuex'

import WorkClaimSummary from './WorkClaimSummary.vue'
import WorkClaimForm from './WorkClaimForm.vue'
import WorkClaimTasks from './WorkClaimTasks.vue'
import WorkClaimNotes from './WorkClaimNotes.vue'
import WorkClaimDocuments from './WorkClaimDocuments.vue'
import WorkClaimActivity from './WorkClaimActivity.vue'
import Multiselect from 'vue-multiselect'
import ClaimWorkerSelect from '../claims/ClaimWorkerSelect.vue'
import WorkClaimWorkLog from './WorkClaimWorkLog.vue'
import NoteAdd from "../notes/NoteAdd.vue"
import DocumentAdd from "../documents/DocumentAdd.vue"
import WorkLogAdd from "../worklog/WorkLogAdd.vue"
import TaskAdd from "../tasks/TaskAdd.vue"
import ClaimEdit from "./ClaimEdit.vue"
import PatientAddModal from "../patients/PatientAddModal.vue"
import PatientEditModal from "../patients/PatientEditModal.vue"

export default {
  name: 'WorkClaim',
  mixins: [permissionsMixin, alertsMixin],
  props: ['issues'],
  components: { 
      WorkClaimSummary,
      WorkClaimForm,
      WorkClaimTasks,
      WorkClaimNotes,
      WorkClaimDocuments,
      WorkClaimActivity,
      Multiselect,
      ClaimWorkerSelect,
      WorkClaimWorkLog,
      NoteAdd,
      DocumentAdd,
      WorkLogAdd,
      TaskAdd,
      ClaimEdit,
      PatientAddModal,
      PatientEditModal
  },
  data() {
      return {
        claimEditLoading: true,
        editPatientLoading: true,
        
        transitionLoading: false,

        //Worker assignment/search data
        unassigned: {worker_id: null, full_name: "Unassigned", initials: ""},
        selectableWorkers: [{worker_id: null, full_name: "Unassigned", initials: ""}],
        workersLimit: 5,
      }
  },
  created(){
    this.debounceWorkers = debounce(this.workersSearchChange, 1000);
  },
  mounted() {
    if (this.worklogOptionsMap == null) {
      this.refreshWorkLogOptionsMap()
    }
  },
  methods: {
    formatDate,
    formatDollars,
    formatDOB(dob) {
        if(dob){
            const dateObj = new Date(dob + 'T00:00:00');
            return new Intl.DateTimeFormat('en-US').format(dateObj);
        }
        return "-"
    },
    transitionClaim(transition) {
      this.transitionLoading = true;
      this.setShowAlert(false, null);
      if(transition == 'move_to_issues' || transition == 'move_to_claims'){
        const url = make_url("/claims/" + this.claim.claim_id);
        const vm = this;
        const promise = authenticated_request({
            method: "put",
            url: url,
            data: {
              'issue_dash': transition == 'move_to_issues' ? 1 : 0
            }
        }).then(function(response){
            if (response) {
              // refresh claims table
              vm.clearWorkedClaim()
              vm.$router.push({ path: '/'})
            }
        }).catch(function(error){
            console.log(error.response)
            if (error.response.data) {
              vm.setShowAlert(true, error.response.data.msg);
            }
            
        }).finally(()=>{
            vm.transitionLoading = false;
        });
        return promise;

      } 
      else {
        const url = make_url("/claims/" + this.claim.claim_id + "/transitions/" + transition);
        const vm = this;
        const promise = authenticated_request({
            method: "put",
            url: url,
        }).then(function(response){
            if (response) {
                vm.claim.state.label = response.data.label;
                vm.claim.state.short = response.data.short;
                vm.claim.state.value = response.data.state;
                vm.$store.commit("refreshActivityLog");
            }
        }).catch(function(error){
            console.log(error.response)
            if (error.response.data) {
              vm.setShowAlert(true, error.response.data.msg);
            }
            
        }).finally(()=>{
            vm.transitionLoading = false;
        });
        return promise;
      }
    },
    docUploadError() {
      this.setShowAlert(true, "An error occurred uploading the document. Please contact support if problem persists.");
    },
    timelyFilingClass: function(daysLeft) {
        var filingClass = '';
        filingClass = daysLeft < 30 ? 'filing-red': daysLeft < 60 ? 'filing-yellow' : 'filing-blue';
        return filingClass;
    },
    timelyFileDate: function(daysLeft) {
      if (daysLeft) {
        var date = new Date();
        date.setDate(date.getDate() + daysLeft);
        var month = (date.getMonth() + 1).toString();
        if (month.length == 1){
          month = "0" + month;
        }
        var day = (date.getDate()).toString();
        if (day.length == 1){
          day = "0" + day;
        }
        var new_date = month + '/'+ day + '/'+ date.getFullYear();
        return new_date
      }
      return "-"
    },
    // For tab navigation
    setActiveTab: function(component){
        this.$store.commit("setWorkedClaimActiveTab", component);
    },
    clearWorkedClaim: function() {
        this.$store.commit("setRefresh", true);
        this.$store.commit("clearWorkedClaim")
    },
    refreshWorkLogOptionsMap() {
      this.$store.dispatch("fetchWorklogOptionsMap")
    },
  },
  computed: {
    ...mapState({
      claim: state => state.claims.workedClaim,
      newClaimState: state => state.claims.newClaimState,
      workedClaim: state => state.claims.workedClaim,
      activeTab: state => state.claims.workedClaimActiveTab,
      loadedTabs: state => state.claims.workedClaimLoadedTabs,
      claimTransitionedTo: state => state.claims.claimTransitionedTo,
      worklogOptionsMap: state => state.claims.worklogOptionsMap,
    }),
  },
  watch: {
    claimTransitionedTo: function(transition){
      if (transition){
        this.transitionClaim(transition);
        this.$store.commit("clearClaimTransition");
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
@media only screen and (min-width : 800px) {
   .container { max-width: 1400px; } 
} 
.header-section {
    padding: 5px 20px 30px 20px;
}
.data-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  max-width:175px;
  margin-right:0;
  border-radius: 5px;
  padding-left:10px;
  padding-right:15px;
  font-weight:500;
  font-size: 14px;
  background:#F7F8FA;
}
.filing-red {
  background: #FFF3EB 0% 0% no-repeat padding-box;
  color: #FD4739;
}
.filing-yellow {
  background: #FDF9E7 0% 0% no-repeat padding-box;
  color: #E7A33A;
}
.filing-blue {
  background: #E0EBF3 0% 0% no-repeat padding-box;
  color: #2473AB;
}

/* assigned muliselect styling */
.assigned-multiselect ::v-deep .multiselect__single{
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 6px;
}

/* Styles for the claim status box and different claim status icons */
.status-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 40px;
  max-width:200px;
  margin-right:0;
  border-radius: 5px;
  padding-left:10px;
  padding-right:15px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight:500;
  font-size: 14px;
  background:#F7F8FA;
}
.status-icon {
    min-width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 10px;
}

/* nav bar styling (overrides) */
.nav-section {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #E6EEF9;
    margin-left: -35px;
    margin-right: -35px;
    padding-right: 35px;
    padding-left: 35px;
}

.avo-info-card {
  max-width: 600px !important;
}
.balance-text {
    font-size: 20px;
    font-weight: 300;
}

/* dropdown styles - these are the same as in ClaimPreview - centralize? */
.dropdown {
  border-radius: 5px;
}
.dropdown ::v-deep .btn-secondary {
  background-color: #F7F8FA;
  border: none;
  min-height:40px;
  max-width: 200px !important;
  color:var(--dark);
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  padding-right: 30px;
  white-space:normal; 
  overflow:hidden;
}
::v-deep .b-dropdown .btn-secondary:active,
::v-deep .btn-secondary:not(:disabled):not(.disabled):active, 
::v-deep .btn-secondary:not(:disabled):not(.disabled).active, 
::v-deep .show > .btn-secondary.dropdown-toggle {
    color: black;
    background-color: #F7F8FA;
}
::v-deep .b-dropdown .btn-secondary:focus {
    box-shadow:none !important;
}
::v-deep .b-dropdown .btn-secondary:hover {
    background-color: #dde4f5;
}
::v-deep .avo-dropdown .dropdown-menu {
    left: auto !important;
    right: 0 !important;
    font-size: 14px;
    font-weight: 500;
    color: #64728C;
}
::v-deep .multiselect__select {
    top:0;  
}
::v-deep .dropdown-toggle::after {
    color: var(--gray);
    position:absolute;
    right: 10px;
    border: none!important;
    font-family: 'Font Awesome 5 Free' !important;
    content: "\f078" !important;
    font-weight: 900 !important;
}
::v-deep .status-dropdown .dropdown-toggle::after {
    top:10px;
}
</style>