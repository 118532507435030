<template>
<div>
    <b-row align-v="center" align-h="between" class="mb-4">
        <b-col cols="12" class="d-flex align-items-center">
            <b-form-group
            id="code"
            label="Filter by CPT code"
            style="min-width:350px">
            <b-input-group>
                <multiselect
                    openDirection="bottom"
                    placeholder="Search by code or description" 
                    label="code"
                    :custom-label="codeWithDescription"
                    v-model="selectedCodes"
                    track-by="code"
                    :options="selectableCPTCodes"
                    :multiple="true"
                    :close-on-select="false"
                    :loading="cptLoading"
                    :internal-search="false"
                    :show-labels="false"
                    @search-change="debounceCPTCodes"
                    :showNoOptions="true">
                    <template slot="noResult">
                        No codes matched your search
                    </template>
                    <template slot="noOptions">
                        Start typing a code or description...
                    </template>
                </multiselect>
            </b-input-group>
            </b-form-group>
        </b-col>
    </b-row>
    <b-overlay :show="paymentsLoading" variant="white" style="min-height:200px;">
    <div v-if="payments.length==0 && !paymentsLoading" class="no-payments pt-4">
        No payments to display
    </div>
    <b-row v-for="(payment) in payments" :key="payment.code_payment_id">
        <b-col>
            <b-card class="payment-card mb-3 shadow">
                <b-row>
                    <b-col cols="4">
                        <b-row class="mb-3">
                            <b-col cols="4">
                                <div class="avo-text-light-small">Date</div>
                                <div class="data-text">{{formatDateShort(payment.payment_date)}}</div>
                            </b-col>
                            <b-col cols="4">
                                <div class="avo-text-light-small">Received from</div>
                                <div class="data-text">{{payment.payment_source == 2 ? payment.payer_name : 'Patient'}}</div>
                            </b-col>
                            <b-col cols="4">
                                <div class="avo-text-light-small">Payment type</div>
                                <div class="data-text">{{payment.payment_source == 2 ? optionMaps.payer_payment_types[payment.payer_payment_type] : optionMaps.patient_payment_types[payment.patient_payment_type] }}</div>
                            </b-col>
                        </b-row>
                        <b-row >
                            <b-col cols="4">
                                <div class="avo-text-light-small">Ref Payment ID</div>
                                <div class="data-text">#{{payment.payment_id}}</div>
                            </b-col>
                            <b-col cols="4">
                                <div class="avo-text-light-small">Ref Invoice No</div>
                                <div class="data-text">{{payment.invoice_no}}</div>
                            </b-col>
                            <b-col cols="4">
                                <div class="avo-text-light-small">Payment method</div>
                                <div class="data-text">{{payment.payment_source == 2 ? optionMaps.payer_payment_methods[payment.payer_payment_method] : optionMaps.patient_payment_methods[payment.patient_payment_method] }}</div>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="8">
                        <div class="code-payment-box p-3">
                            <b-row class="mb-2">
                                <b-col cols="2">
                                    <b-row>
                                        <b-col>
                                            <div class="avo-text-light-small">CODE</div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="6">
                                    <b-row>
                                        <b-col cols="3" class="text-center">
                                            <div class="avo-text-light-small">UNITS</div>
                                        </b-col>
                                        <b-col cols="3" class="text-right">
                                            <div class="avo-text-light-small">BILLED</div>
                                        </b-col>
                                        <b-col cols="3" class="text-right">
                                            <div class="avo-text-light-small">PAID</div>
                                        </b-col>
                                        <b-col cols="3" class="text-right">
                                            <div class="avo-text-light-small">ALLOWED</div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="4">
                                    <b-row>
                                        <b-col>
                                            <div class="avo-text-light-small">ADJUSTMENTS</div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <b-row class="data-text-small mb-1">
                                <b-col cols="2">
                                    <b-row>
                                        <b-col class="d-flex align-items-center">
                                            <div class="mr-1">{{payment.code}}</div>
                                            <div v-for="(modifier, index) in payment.modifiers" :key="index" class="modifier-badge">
                                                {{modifier}}
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="6">
                                    <b-row>
                                        <b-col cols="3" class="text-center">
                                            {{payment.units}}
                                        </b-col>
                                        <b-col cols="3" class="text-right">
                                            ${{Number(((payment.unit_charge || 0) * (payment.units || 0))).toFixed(2)}}
                                        </b-col>
                                        <b-col cols="3" class="text-right">
                                            ${{payment.amount}}
                                        </b-col>
                                        <b-col cols="3" class="text-right">
                                            ${{Number(payment.allowed_amount || 0).toFixed(2)}}
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="4">
                                    <b-row>
                                        <b-col>
                                            <div class="d-flex flex-wrap">
                                                <div v-for="(adjustment, index) in payment.adjustments" :key="index" class="mr-2">
                                                    <span v-if="adjustment.adjustment_id">
                                                        {{optionMaps.adjustment_group_codes_short[adjustment.group_code]}}-{{adjustment.reason_code}} ${{adjustment.amount}}
                                                        <span v-if="adjustment.remark_code">({{adjustment.remark_code}})</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
    </b-overlay>
</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { make_url, authenticated_request, formatDateShort, debounce } from '../../common.js'

export default {
  name: 'ItemizedPayments',
  props: ['source'],
  components: {
      Multiselect
  },
  data: function(){
      return {
        payments: [],
        optionMaps: null,
        paymentsLoading: true,

        selectableCPTCodes: [],
        selectedCodes: [],
        selectedCodesString: "",
        searchText: "",
        cptLoading: false,
      }
  },
  methods: {
    getCodePaymentHistory(){
        var params = {
            codes: this.selectedCodesString
        };
        var vm = this;
        vm.paymentsLoading = true;
        authenticated_request({
            method: "get",
            url: make_url("/reports/claims/"+ this.claim.claim_id + "/payments/history").toString(),
            params: params
        }).then(function(response){
            vm.payments = response.data.payments || [];
            vm.optionMaps = response.data.paymentMaps;
        }).catch(function(error){
            console.log(error.response)
        }).finally(()=>{
            vm.paymentsLoading = false;
        });
    },
    codeWithDescription(code){
        if (code.code && code.short){
            return (code.code + ": " + code.short)
        }
        return ""
    },
    searchChange: function(searchQuery) {
        this.cptLoading = true;
        this.searchText = searchQuery
        if (searchQuery.length > 0 ) {
            this.CPTAutocomplete();
        } else {
            this.cptLoading = false;
        }
    },
    CPTAutocomplete() {
        var vm = this;
        const url = make_url("/reports/codes/autocomplete");
        authenticated_request({
            method: "get",
            url: url,
            params: {
                search: this.searchText,
                limit: 10
            }
        }).then(function(response){
            vm.cptLoading = false;
            const codes = response.data.codes;
            vm.selectableCPTCodes = codes || [];
            return codes || [];
        }).catch(function(errors){
            console.log(errors.response)
            vm.showError(errors);
        });
    },
    formatDateShort(datetime) {
      return formatDateShort(datetime)
    },
  },
  computed: {
    claim() {
        return this.$store.state.claims.workedClaim;
    },
    paymentSource(){
        if(this.source && this.source == 'patient'){
            return 1
        } else if (this.source && this.source == 'payer'){
            return 2
        } else {
            return null
        }
    },
  },
  watch: {
      selectedCodes(newValue){
          var justCodes = [];
          if(newValue.length > 0){
              newValue.forEach(function(code){
                  justCodes.push(code.code)
              })
              this.selectedCodesString = justCodes.join(",");
          } else {
              this.selectedCodesString = "";
          }
          this.getCodePaymentHistory();
      }
  },
  created() {
      this.debounceCPTCodes = debounce(this.searchChange, 300)
      this.getCodePaymentHistory();
  },
  mounted: function() {
    
  }
}
</script>

<style scoped>
::v-deep .multiselect__spinner {
    background: #DDE4F5;
}
.no-payments {
  font-size: 16px;
  font-weight: 200;
  text-align: center;
}
.payment-card {
    min-height:100px;
    border-radius: 10px;
    background: white;
    border: 1px solid #E2E4EB;
}
.code-payment-box {
    background: transparent linear-gradient(101deg, #EEF1FA 0%, #F8FAFD 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width:100%;
    height:100%;
}
.modifier-badge {
    display:flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 9px;
    border-radius: 4px;
    background-color: #223053;
    margin-right:2px;
    /* padding: 1px 2px 0px 2px; */
    padding: 2px 1px 0 0;
    min-height: 15px;
    min-width: 15px;
}

/* Styling the search bar group */
::v-deep .search-group {
    position: relative;
    border: 1px solid #C9D2DF;
    border-radius: 5px;
    max-width:650px;
}
::v-deep .payment-search-input, .input-group-text {
    height:50px;
    border: none;
    border-radius: 5px;
    background: white;
    color: #9CA3AD; 
}
::v-deep .payment-search-input::placeholder {
    color: #9CA3AD;
    font-size:12px;
    opacity: 1;
}
::v-deep .b-dropdown .btn-secondary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 5px;
    color: #9CA3AD;
    background: white;
    font-size:12px;
    height:50px;
    padding-right: 35px;
    min-width:150px;
    text-align:left;
}
::v-deep .b-dropdown .btn-secondary:active,
::v-deep .btn-secondary:not(:disabled):not(.disabled):active, 
::v-deep .btn-secondary:not(:disabled):not(.disabled).active, 
::v-deep .show > .btn-secondary.dropdown-toggle {
    color:#519EFF !important;
    background-color: white !important;
}
::v-deep .b-dropdown .btn-secondary:focus {
    box-shadow:none !important;
}
.divider {
    display: inline-block;
    width: 0;
    height: 20px;
    margin: auto;
    border-right: 1.8px solid #D2D8E2;
}

/* dropdown menu-related styling */
::v-deep .filter-dropdown .b-dropdown-form {
    padding: 20px 30px 20px 30px;
}
::v-deep .filter-dropdown .dropdown-menu {
    width:100.4%;
    right: -1px !important;
    left: auto !important;
    top:45px !important;
    transform: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 16px 48px var(--dark)2B !important;
}
</style>