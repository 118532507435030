<template>
<div>
    <b-container class="claim-container p-0">
        <br>
        <div class="1" ref="1"></div>
        <div class="avo-header-text-med mb-2">
            Visit
        </div>
        <!-- TODO: handle when to make visit editable -->
        <claim-visit :claim-id="claim.claim_id" editable />
        <div class="avo-header-text-med mt-4 mb-3"> 
            Services rendered
        </div>
        <cpt-codes-list @codesUpdated="updateWorkedClaimCodes" :claim-id="claim.claim_id" />
        <div class="avo-header-text-med mt-5 mb-3"> 
            Diagnoses
        </div>
        <icd-codes-list :claim-id="claim.claim_id" />
        <div class="avo-header-text-med mt-5 mb-3"> 
            Insurances
        </div>
        <insurance-list :patient-id="claim.patient_id" :patient-name="claim.patient_name" @insurancesUpdated="setInsurances($event)"/>

        <div class="mt-5">
            <b-nav tabs class="nav-section mb-4" >
                <b-nav-item :active="activeTab == 'PaymentsBilling'" @click="setActiveTab('PaymentsBilling')">Billing</b-nav-item>
                <b-nav-item :active="activeTab == 'PaymentsPatient'" @click="setActiveTab('PaymentsPatient')">Patient</b-nav-item>
                <b-nav-item :active="activeTab == 'PaymentsPayer'" @click="setActiveTab('PaymentsPayer')">Payer</b-nav-item>
                <b-nav-item :active="activeTab == 'PaymentsCodeHistory'" @click="setActiveTab('PaymentsCodeHistory')">CPT History</b-nav-item>
                <b-nav-item :active="activeTab == 'PracticeFees'" @click="setActiveTab('PracticeFees')">Fee Schedule</b-nav-item>
            </b-nav>
            <component :is="activeTab" :practiceId="claim.practice_id" ></component>
        </div>
    </b-container>
</div>

</template>

<script>
import InsuranceList from '../payers/InsuranceList.vue'
import CptCodesList from '../codes/CptCodesList.vue'
import IcdCodesList from '../codes/IcdCodesList.vue'
import ClaimVisit from '../visits/Visit.vue'
import PaymentsBilling from '../payments/PaymentsBilling.vue'
import PaymentsPatient from '../payments/PaymentsPatient.vue'
import PaymentsPayer from '../payments/PaymentsPayer.vue'
import PaymentsCodeHistory from '../payments/PaymentsCodeHistory.vue'
import PracticeFees from '../practices/PracticeFees.vue'

export default {
    name: 'WorkClaimForm',
    components: {
        InsuranceList,
        CptCodesList,
        IcdCodesList,
        ClaimVisit,
        PaymentsBilling,
        PaymentsPatient,
        PaymentsPayer,
        PaymentsCodeHistory,
        PracticeFees,
    },
    data(){
        return {
            activeTab: "PaymentsBilling",
        }
    },
    methods: {
        // For tab navigation
        setActiveTab: function(component){
            this.activeTab = component;
        },
        updateWorkedClaimCodes: function(codes){
            this.$store.commit('setWorkedClaimCodes', codes);
        },
        setInsurances: function(insurances){
            this.$store.commit('setInsurances', insurances);
        },
    },
    computed: {
        claim() {
            return this.$store.state.claims.workedClaim;
        },
    },
    mounted: function(){
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (min-width : 800px) {
   .container { max-width: 1400px; } 
} 
.alert {
    background: #FDEEED 0% 0% no-repeat padding-box;
    border: 1px solid var(--red);
    border-radius: 5px;
    text-align: center;
    letter-spacing: 0px;
    color: var(--red);
}
.claim-sidebar {
    border-right: 1px solid #c9d2df;
}
.nav {
    display: unset;
}
.nav-claim .nav-link {
    color: var(--gray);
}
.nav-claim .nav-link:hover{
    border:none;
    color: var(--dark);
}
.avo-primary-btn {
    margin: 5px;
    min-width: 0px;
    width: 50px;
}
/* nav bar styling (overrides) */
.nav-section {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #E6EEF9;
    margin-left: -35px;
    margin-right: -35px;
    padding-right: 35px;
    padding-left: 35px;
}
.avo-simple-table {
    border:solid 1px #E2E4EB;
    font-size:14px;
    max-height:100%
}
.payment-card {
    border: 1px solid #c9d2df;
    padding:20px;
}
.billing-summary {
    border: 1px solid #c9d2df;
    border-radius: 5px;
    background: #F8FAFD;
    padding:16px;
    padding-bottom:8px;
}
.total-paid-billing {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: -8px;
    margin-right: -8px;
    padding-right: 8px;
    height: 32px;
    border-radius: 10px;
    font-size: 14px;
    color:white;
    background: transparent linear-gradient(102deg, #33487D 0%, #223053 100%) 0% 0% no-repeat padding-box;
}
.effective-box{
    padding:5px;
    padding-left: 10px;
    padding-right: 10px;
    background:#eef1fa;
    border-radius:5px;
}
</style>