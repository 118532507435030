<template>
<b-modal id="add-new-code" @hide="resetModal" title="Add a new code" hide-footer size="xl">
<b-nav tabs class="mb-4 nav-section">
    <b-nav-item :active="!do_show_payer_info_tab" @click="setPayerInfo(false)">Step 1: Code information</b-nav-item>
    <b-nav-item :active="do_show_payer_info_tab" @click="setPayerInfo(true)">Step 2: Payer information</b-nav-item>
</b-nav>
<b-form @submit="onSubmit">
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <!-- Step two here -->
    <div v-if="do_show_payer_info_tab">
        <b-row align-h="center">
        <b-col cols=9>
        <b-card bg-variant="light" text-variant="dark">
            <b-row>
            <b-col>
                <b-card-text><b>Code:</b> {{ form.cpt_code ? form.cpt_code.code : null || 'N/A'}}, <b>Modifiers:</b> {{ formatModifiers(form.modifiers) || 'N/A'}}</b-card-text>
                <b-card-text><b>POS/TOS:</b> {{ form.pos || 'N/A'}}, {{ form.tos || 'N/A'}}</b-card-text>
                <b-card-text><b>Description:</b> {{ form.cpt_code ? form.cpt_code.short : null || 'N/A' }}</b-card-text>
            </b-col>
            <b-col>
                <b-card-text><b>Charge fee:</b> {{ form.charge_fee ? '$'+form.charge_fee : 'N/A' }}</b-card-text>
                <b-card-text><b>Medicare:</b> {{ form.medicare_fee ? '$'+form.medicare_fee : 'N/A' }}</b-card-text>
                <b-card-text><b>Cost:</b> {{ form.cost ? '$'+form.cost : 'N/A' }}</b-card-text>
                <b-card-text><b>Self-pay:</b> {{ form.self_pay_fee ? '$'+form.self_pay_fee : 'N/A' }}</b-card-text>
            </b-col>
            </b-row>
        </b-card>
        </b-col>
        </b-row>

        <div v-if="payers.length > 0">
        <b-table-simple striped class="mt-4">
            <b-thead>
                <b-tr>
                    <b-th>Payer</b-th>
                    <b-th>Charge Fee</b-th>
                    <b-th>Contracted Fee</b-th>
                    <b-th>Allowed Amount</b-th>
                    <b-th />
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="(payer,payer_index) in payers" :key="payer_index">
                    <b-td>
                        {{ payer.payer_object ? payer.payer_object.name : null || "N/A"}} 
                        <span class="avo-text-light">{{ payer.payer_object ? " - " + payer.payer_object.full_address : null || ""}}</span>
                    </b-td>
                    <b-td>
                        {{payer.charge_fee ? '$'+payer.charge_fee : "N/A"}}
                    </b-td>
                    <b-td>
                        {{payer.contracted_fee ? '$'+payer.contracted_fee : "N/A"}}
                    </b-td>
                    <b-td>
                        {{payer.allowed_amount ? '$'+payer.allowed_amount : "N/A"}}
                    </b-td>
                    <b-td>
                        <b-icon @click="removePayerRow(payer_index)" icon="x-circle" font-scale="1.25" class="mb-2" style="color: var(--gray-800); cursor:pointer" />
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        </div>
        <div v-else style="text-align:center" class="mt-4">
        No payers added
        </div>

    <b-button v-b-modal.new-payers-modal class="avo-primary-btn">Add payers</b-button>

    <b-modal id="new-payers-modal" title="Add payers" hide-footer size="lg">

    <div v-for="(payer, index) in new_payers" :key="`payer-${index}`">
        <b-card class="mt-2 mb-2">
            <b-row>
                <b-col style="text-align:left">
                    <h5>Payer {{ index + 1 }}</h5>
                </b-col>
                <b-col style="text-align:right">
                <b-icon @click="removeNewPayerRow(index)" icon="x-circle" font-scale="1.25" class="mb-2" style="color: var(--gray-800); cursor:pointer" />
                </b-col>
            </b-row>
            <b-form-row>
            <b-col>
            <b-form-group
            id="payer"
            label="Payer:"
            label-size="lg"
            label-cols-lg="3">
            <b-input-group>
                <payer-multiselect
                    v-model="payer.payer_object"
                />
            </b-input-group>
            </b-form-group>
            </b-col>
            </b-form-row>
            <b-form-group
            id="rates-master"
            label="Rates:"
            label-size="lg"
            label-cols-lg="3">
            <b-form-row>
            <b-col>
                <b-form-group
                id="charge-fee"
                label="Charge fee:">
                <b-input-group prepend="$">
                    <b-form-input v-model="payer.charge_fee" type="number" required></b-form-input>
                </b-input-group>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                id="contracted-fee"
                label="Contracted fee:">
                <b-input-group prepend="$">
                    <b-form-input v-model="payer.contracted_fee" type="number" required></b-form-input>
                </b-input-group>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                id="allowed-amount"
                label="Allowed amount:">
                <b-input-group prepend="$">
                    <b-form-input v-model="payer.allowed_amount" type="number" required></b-form-input>
                </b-input-group>
                </b-form-group>
            </b-col>
            </b-form-row>
            </b-form-group>
            <b-form-group
            id="rates-surgery-center"
            label="Rates for surgery centers:"
            label-size="lg"
            label-cols-lg="3"
            v-show="show_surgery_center_fields">
                <b-form-row>
                <b-col>
                    <b-form-group
                    id="charge-fee-surgery-center"
                    label="Charge fee:">
                        <template #label>
                        Charge fee: <span class="badge badge-primary">Surgery Center</span>
                        </template>
                    <b-input-group prepend="$">
                        <b-form-input v-model="payer.charge_fee_surgery_center" type="number" :required="show_surgery_center_fields"></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                    id="contracted-fee-surgery-center"
                    label="Contracted fee:">
                        <template #label>
                        Contracted fee: <span class="badge badge-primary">Surgery Center</span>
                        </template>
                    <b-input-group prepend="$">
                        <b-form-input v-model="payer.contracted_fee_surgery_center" type="number" :required="show_surgery_center_fields"></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                    id="allowed-amount-surgery-center"
                    label="Allowed amount:">
                        <template #label>
                        Allowed amount: <span class="badge badge-primary">Surgery Center</span>
                        </template>
                    <b-input-group prepend="$">
                        <b-form-input v-model="payer.allowed_amount_surgery_center" type="number" :required="show_surgery_center_fields"></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </b-col>
                </b-form-row>
            </b-form-group>
        </b-card>
    </div>
    
    <b-row>
        <b-col style="text-align:left">
            <b-button @click="addNewPayer" class="avo-primary-btn mt-2">
                <b-icon icon="plus-circle"></b-icon> Add another payer
            </b-button>
        </b-col>
        <b-col style="text-align:right">
            <b-button @click="commitNewPayers" class="avo-primary-btn mt-2">
                <b-icon icon="clipboard-plus" /> Stage payer details
            </b-button>
        </b-col>
    </b-row>

    </b-modal>

    <b-row>
        <b-col style="text-align:right">
            <b-button @click="onSubmit" class="avo-primary-btn mt-2">
                Finalize and save <b-icon icon="arrow-down-right-square"/> 
            </b-button>
        </b-col>
    </b-row>
    </div>


    <!-- Step one here -->
    <div v-else class="mb-4">
    <b-card class="mt-2 mb-2">
    <b-form-group
        id="code-info"
        label="Code details"
        label-size="lg"
        label-cols-lg="3">
        <b-form-row>
        <b-col>
        <b-form-group
        id="cpt_code"
        label="CPT Code:">
        <b-input-group>
            <multiselect
                openDirection="bottom"
                placeholder="Search by code or description" 
                label="code"
                :custom-label="codeWithDescription"
                v-model="form.cpt_code"
                track-by="code"
                :options="selectableCPTCodes"
                :loading="cptLoading"
                :internal-search="false"
                :show-labels="false"
                @search-change="debounceCPTCodes"
                :showNoOptions="true"
                :allow-empty="false">
                <template slot="noResult">
                    No codes matched your search
                </template>
                <template slot="noOptions">
                    Start typing a code or description...
                </template>
            </multiselect>
        </b-input-group>
        </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="modifiers"
            label="Modifiers:">
            <b-input-group>
                <b-form-input class="mr-2" id="modifier-1" type="text" v-model="form.modifiers[0]"></b-form-input>
                <b-form-input class = "mr-2" id="modifier-2" type="text" v-model="form.modifiers[1]"></b-form-input>
                <b-form-input class = "mr-2" id="modifier-3" type="text" v-model="form.modifiers[2]"></b-form-input>
                <b-form-input id="modifier-4" type="text" v-model="form.modifiers[3]"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        </b-form-row>
        <b-row>
            <b-col>
                <b-form-group
                id="pos"
                label="POS:">
                <b-input-group>
                    <b-form-input v-model="form.pos" type="number" required></b-form-input>
                </b-input-group>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                id="tos"
                label="TOS:">
                <b-input-group>
                    <b-form-input v-model="form.tos" type="number" required></b-form-input>
                </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
    </b-form-group>
    </b-card>

    <b-card class="mt-2 mb-2">
    <b-form-group
        id="rates-master"
        label="Practice fee rates"
        label-size="lg"
        label-cols-lg="3">
        
        <b-form-row>
        <b-col>
            <b-form-group
            id="charge-fee"
            label="Charge fee:">
            <b-input-group prepend="$">
                <b-form-input v-model="form.charge_fee" type="number" required></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="medicare-fee"
            label="Medicare fee:">
            <b-input-group prepend="$">
                <b-form-input v-model="form.medicare_fee" type="number" required></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="cost"
            label="Cost:">
            <b-input-group prepend="$">
                <b-form-input v-model="form.cost" type="number" required></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="self-pay-fee"
            label="Self pay fee:">
            <b-input-group prepend="$">
                <b-form-input v-model="form.self_pay_fee" type="number" required></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        </b-form-row>
    </b-form-group>
    <b-form-group
        id="rates-surgery-center"
        label="Rates for Surgery Centers"
        label-size="lg"
        label-cols-lg="3"
        v-show="show_surgery_center_fields">
        <b-form-row >
        <b-col >
            <b-form-group
            id="medicare-fee-surgery"
            label="Medicare fee (surgery center):">
                <template #label>
                Medicare fee: <span class="badge badge-primary">Surgery Center</span>
                </template>
            <b-input-group prepend="$">
                <b-form-input v-model="form.medicare_fee_surgery_center" type="number" :required="show_surgery_center_fields"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="cost-surgery"
            label="Cost (surgery center):">
                <template #label>
                Cost: <span class="badge badge-primary">Surgery Center</span>
                </template>
            <b-input-group prepend="$">
                <b-form-input v-model="form.cost_surgery_center" type="number" :required="show_surgery_center_fields"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="self-pay-fee-surgery"
            label="Self pay fee (surgery center):">
                <template #label>
                Self pay fee: <span class="badge badge-primary">Surgery Center</span>
                </template>
            <b-input-group prepend="$">
                <b-form-input v-model="form.self_pay_fee_surgery_center" type="number" :required="show_surgery_center_fields"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        </b-form-row>
    </b-form-group>
    <b-row>
        <b-col style="text-align:right mb-2">
            <b-form-checkbox type="checkbox" size="md" id="show_surgery_checkbox" v-model="show_surgery_center_fields" switch> Add <span :class="show_surgery_center_fields ? 'badge badge-primary' : 'badge badge-secondary'">Surgery Center</span> fields </b-form-checkbox>
        </b-col>
    </b-row>
    </b-card>

    <b-card class="mt-2 mb-2">
    <b-form-group
        id="underpayment-group"
        label="Claim auto-flagging"
        label-size="lg"
        label-cols-lg="3">
    <b-form-row>
    <b-col cols="4">
        <b-form-group
            id="underpayment-pct"
            label="Underpayment percentage:"
            style="text-align:left">
        <b-input-group append="%">
            <b-form-input v-model="form.underpayment" type="number"></b-form-input>
        </b-input-group>
        </b-form-group>
    </b-col>
    </b-form-row>
    </b-form-group>
    </b-card>

    <b-row>
        <b-col style="text-align:right">
            <b-button @click="setPayerInfo(true)" class="avo-primary-btn">
                Continue to add payer rates
            </b-button>
        </b-col>
    </b-row>

    </div>
    <!-- {{ form }} -->
</b-form>
</b-modal>
</template>

<script>
import { make_url, authenticated_request, debounce, alertsMixin } from '../../common.js'
import Multiselect from 'vue-multiselect'
import PayerMultiselect from '../multiselects/PayerMultiselect.vue'

export default {
  name: 'PracticeFeeAdd',
  components:{
    Multiselect,
    PayerMultiselect,
  },
  mixins: [ alertsMixin ],
  props: ['year', 'practiceId', 'payerInfoTab'],
  data: function(){
      return {
            form: {
                practice_id: this.practiceId,
                year: this.year,
                cpt_code: null,
                modifiers: [null,null,null,null],
                underpayment: null,
                pos: 11,
                tos: 2,
                underpayment_percent: null,
                charge_fee: null,
                medicare_fee: null,
                cost: null,
                self_pay_fee: null,
                medicare_fee_surgery_center: null,
                cost_surgery_center: null,
                self_pay_fee_surgery_center: null,
            },

            payers: [],

            new_payers: [{  payer_object: null,
                        charge_fee: null, 
                        contracted_fee: null, 
                        allowed_amount: null, 
                        charge_fee_surgery_center: null, 
                        contracted_fee_surgery_center: null, 
                        allowed_amount_surgery_center: null, }],

            do_show_payer_info_tab: this.payerInfoTab,
            show_surgery_center_fields: false,

            payersOptions: [],

            selectableCPTCodes: [],
            cptSearchText: "",
            cptLoading: false,
      }
  },
  methods: {
    clearData(){
        this.form = { practice_id: this.practiceId,
                year: this.year,
                cpt_code: null,
                modifiers: [null,null,null,null],
                pos: 11,
                tos: 2,
                underpayment_percent: null,
                medicare_fee: null,
                cost: null,
                self_pay_fee: null,
                medicare_fee_surgery_center: null,
                cost_surgery_center: null,
                self_pay_fee_surgery_center: null, };

        this.payers = [];
        this.do_show_payer_info_tab = false;
        this.new_payers = [{ payer_object: null,
                    charge_fee: null, 
                    contracted_fee: null, 
                    allowed_amount: null, 
                    charge_fee_surgery_center: null, 
                    contracted_fee_surgery_center: null, 
                    allowed_amount_surgery_center: null, }];
        
        this.hideError();
    },
    validateInputs(){
        this.hideError();

        if ((this.form.cpt_code == null) || (this.form.cpt_code == "")){
            this.showError("Please select a cpt code")
            return false
        }

        if ((this.form.pos == null) || (this.form.pos == "")){
            this.showError("Please enter a pos")
            return false
        }

        if ((this.form.tos == null) || (this.form.tos == "")){
            this.showError("Please enter a tos")
            return false
        }

        for (var i = 0; i < this.form.modifiers.length; i++){
            if ((this.form.modifiers[i] != null)&&(this.form.modifiers[i] != "")){
                if (this.form.modifiers[i].length != 2){
                    this.showError("Invalid modifier value")
                    return false
                }
            }
        }
        
        return true
    },
    addNewPayer(){
        const newPayer = {  payer_object: null,
                            charge_fee: null, 
                            contracted_fee: null, 
                            allowed_amount: null, 
                            charge_fee_surgery_center: null, 
                            contracted_fee_surgery_center: null, 
                            allowed_amount_surgery_center: null, }
        this.new_payers.push(newPayer);
    },
    commitNewPayers(){
        this.payers = this.payers.concat(this.new_payers);
        this.new_payers = [{ payer_object: null,
                    charge_fee: null, 
                    contracted_fee: null, 
                    allowed_amount: null, 
                    charge_fee_surgery_center: null, 
                    contracted_fee_surgery_center: null, 
                    allowed_amount_surgery_center: null, }];
        this.$bvModal.hide('new-payers-modal');
    },
    removeNewPayerRow(index){
        this.new_payers.splice(index, 1);
    },
    removePayerRow(index){
        this.payers.splice(index, 1);
    },
    formatModifiers(modifiers_array){
        const modifier_filter = (code_string) => { return (code_string && code_string.length == 2) };
        const distinct_filter = (value, index, self) => { return self.indexOf(value) === index; }
        const modifier_formatter = (code_string) => { return code_string.toUpperCase() };
        return modifiers_array.filter(modifier_filter)
                                .map(modifier_formatter)
                                .filter(distinct_filter)
                                .sort().join(",")
    },
    onSubmit(){
        if (this.validateInputs()){
            const vm = this;
            const data = {  code_info: {
                                        cpt_code: this.form.cpt_code.code,
                                        modifiers: this.formatModifiers(this.form.modifiers),
                                        pos: this.form.pos,
                                        tos: this.form.tos,
                                        charge_fee: this.form.charge_fee || null,
                                        medicare_fee: this.form.medicare_fee || null,
                                        self_pay_fee: this.form.self_pay_fee || null, 
                                        cost: this.form.cost || null,
                                        medicare_fee_surgery_center: this.form.self_pay_fee_surgery_center || null,
                                        self_pay_fee_surgery_center: this.form.self_pay_fee_surgery_center || null,
                                        cost_surgery_center: this.form.cost_surgery_center || null,
                                        year: this.form.year,
                                        }, 
                            payer_fees: this.payers.map((value) => { return {
                                            payer_id: value.payer_object.payer_id,
                                            cpt_code: this.form.cpt_code.code,
                                            modifiers: this.formatModifiers(this.form.modifiers),
                                            pos: this.form.pos,
                                            tos: this.form.tos,
                                            charge_fee: value.charge_fee || null,
                                            contracted_fee: value.contracted_fee || null,
                                            allowed_amount: value.allowed_amount || null,
                                            charge_fee_surgery_center: value.charge_fee_surgery_center || null,
                                            contracted_fee_surgery_center: value.contracted_fee_surgery_center || null,
                                            allowed_amount_surgery_center: value.allowed_amount_surgery_center || null,
                                            year: this.form.year,
                                            };}),
                         }   
            
                authenticated_request({
                    method: 'POST',
                    url: make_url("/practices/" + this.practice_id + "/fees/multiadd"),
                    data: data,
                }).then(()=>{
                    vm.clearData();
                    vm.hideModal();
                }).finally(()=>{
                    vm.$emit('submitted');
                });
        }
    },
    cptSearchChange: function(searchQuery) {
        this.cptLoading = true;
        this.cptSearchText = searchQuery
        if (searchQuery.length > 0 ) {
            this.CPTAutocomplete();
        } else {
            this.cptLoading = false;
        }
    },
    CPTAutocomplete() {
        var vm = this;
        const url = make_url("/reports/codes/autocomplete");
        authenticated_request({
            method: "get",
            url: url,
            params: {
                search: this.cptSearchText,
                limit: 10
            }
        }).then(function(response){
            vm.cptLoading = false;
            const codes = response.data.codes;
            vm.selectableCPTCodes = codes || [];
            return codes || [];
        }).catch(function(errors){
            console.log(errors.response)
            vm.showError(errors);
        });
    },
    setPayerInfo(bool){
        // if (this.do_show_payer_info_tab != bool){
        //     this.clearData();
        // }
        this.do_show_payer_info_tab = bool;
    },
    resetModal(){
        this.hideError();
        this.clearData();
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
    hideModal(){
        this.$bvModal.hide('add-new-code');
    },
    codeWithDescription(code){
        if (code.code && code.short){
            return (code.code + ": " + code.short);
        }
        return "Code/description missing"
    },
  },
    computed: {
        practice_id: function(){
            if(this.$store.state.selected_practice){
                return this.$store.state.practices.selected_practice.practice_id;
            } else {
                return this.practiceId;
            }
        },
    },
    created(){
        const vm = this;

        this.debounceCPTCodes = debounce(this.cptSearchChange, 300)
    }
}
</script>

<style scoped>
::v-deep .multiselect__spinner {
    background: #DDE4F5;
}
</style>