
































import Vue from 'vue'
import { mapState } from 'vuex'
import { permissionsMixin } from "../common.js"
import BackButton from "../components/BackButton.vue"

import ClaimSearch from '../components/claims/ClaimSearch.vue'
import ClaimList from "../components/claims/ClaimList.vue"
import ClaimPreview from "../components/claims/ClaimPreview.vue"
import ClaimBalanceSummary from "../components/claims/ClaimBalanceSummary.vue"

import WorkClaim from "../components/claims/WorkClaim.vue"


export default Vue.extend({
  name: 'Claims',
  mixins: [permissionsMixin,],
  components: {
      BackButton,
      ClaimSearch,
      ClaimList,
      ClaimPreview,
      ClaimBalanceSummary,
      WorkClaim,
  },
  props: ['initialClaimId', 'issues',],
  data(){
      return {
          current_claim: null,
          initial_claim_viewed: false,
          claims: [],
      }
  },
  methods: {
      clearWorkedClaim(): void {
          let route_name;
          if (this.issues) {
              route_name = 'issues-claims';
          } else {
              route_name = 'claims';
          }
          this.$router.push({ name: route_name });
          this.$store.commit("clearWorkedClaim");
          // start on claim summary by default
          this.$store.commit("setWorkedClaimActiveTab", "WorkClaimSummary");
          this.$store.commit("setRefresh", true);
      }
  },
  computed: {
      header: function() {
        return this.$route?.meta?.header || "Claims";
      },
      backButtonText: function() {
        return "Back to " + (this.issues ? "Issues Claims" : "Claims");
      },
      ...mapState({
          selectedClaim: (state: any) => state.claims.selectedClaim,
          workedClaim: (state: any) => state.claims.workedClaim,
          search: (state: any) => state.claims.search,
      }),
  },
  watch: {
      initialClaimId(newValue, oldValue) {
          if (oldValue != null && newValue != null) {
              // TODO: Needing to do something this hacky indicates that we need to 
              // refactor how we handle initialClaimId
              window.location.reload();
          }
      }
  }
})
