<template>
<div v-if="events">
<ul class="nav mt-3 mb-2">
  <li class="nav-item">
    <a class="nav-link active disabled label-text pl-4 pt-0" href="#" style="text-decoration:underline var(--blue) 3px;text-underline-offset:1.2rem">Activity</a>
  </li>
</ul>
<hr class="w-100 mb-0 mt-1">
  <b-overlay :show="eventsLoading" variant="white" style="min-height:200px;">
    <b-table id="my-table"
      ref="table"
      :sort-desc.sync="sortDesc"
      :per-page="perPage" 
      :current-page="currentPage" 
      :items="events"
      :fields="eventsFields"
      :borderless="borderless"
      thead-class="d-none"
      class="events"
      primary-key="event_id">
      <template #cell(initials)="data">
        <b-avatar :text="data.value"></b-avatar>
      </template>
      <template #cell(label)="data">
        <div class="user-text"> {{ data.item.name }}</div>
        <div class="event-text">{{ data.value }} on {{ new Date(data.item.occurred_at).toLocaleString("en-US", {month: 'numeric', day: 'numeric', year: 'numeric'}) }}.</div>
      </template>
      <template #cell(occurred_at)="data">
          <div class="event-text"> {{ new Date(data.value).toLocaleString("en-US", {month: 'numeric', day: 'numeric', year: 'numeric', hour: '2-digit', minute:'2-digit'}).replace(',',' at') }} </div>
      </template>
    </b-table>
    <div v-if="events.length == 0 && !eventsLoading" class="no-events pt-5">
        No events for this claim.
    </div>
  </b-overlay>
</div>
</template>


<script>
export default {
  name: 'ActivityListEmbedded',
  components: {},
  props: ['claimId', 'userId', 'perPage','hideViewAll'],
  data(){
      return {
          eventsFields: [
              {key: 'initials', tdClass: 'avatar'},
              {key: 'label', tdClass: 'data-col'},
              {key: 'occurred_at', tdClass: 'time-col'}
          ],
          perPageVar: this.perPage || 5,
          currentPage: 1,
          sortBy: "occurred_at",
          sortDesc: true,
          borderless: true,
      }
  },
  methods: {
    getEvents: function(){
      var params = {
          per_page: this.perPageVar,
          page: this.currentPage,
      }
      if (this.userId){
        params.user_id = this.userId
      }
      if(this.sortBy){
          params.sort = this.sortBy + ":" + (this.sortDesc?"desc":"asc")
      }
      this.$store.dispatch('fetchClaimActivityLogs', {claimId: this.claimId, params: params});
    }
  },
  computed: {
    rows() {
      return this.$store.state.claims.activityLogs?.total || 0;
    },
    events() {
      return this.$store.state.claims.activityLogs?.events || [];
    },
    eventsLoading() {
      return this.$store.state.claims.activityLogsClaimId != this.claimId;
    },
  },
  beforeMount() {
    if (this.$store.state.claims.activityLogsClaimId != this.claimId){
      this.getEvents();
    }
  },
  watch: {
    claimId: function() {
      this.getEvents();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.no-events {
  font-size: 16px;
  font-weight: 200;
  text-align: center;
}
.events {
  background: #f8fafd;
  border-radius: 0px 0px 15px 15px;
}
::v-deep .events td {
  height: 48px;
}
::v-deep .events tr:hover {
  background-color: #f2f6fc;
}
.event-text {
    padding-top: 4px;
    font-size: 14px;
    font-weight: 400;
    color:var(--gray);
}
.user-text {
    font-size: 14px;
    color:var(--dark);
    font-weight: 500;
}
a {
    font-size: 14px;
    font-weight: 400;
    color:var(--gray);
}
a.active {
    font-size: 14px;
    font-weight: 700;
    color:var(--dark);
}
::v-deep .avatar {
  width: 1%;
  padding-right: 0;
  text-align: right;
}
::v-deep .data-col {
  padding: 16px;
  text-align: left;
}
::v-deep .time-col {
  padding: 16px;
  text-align: right;
}
</style>