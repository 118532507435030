<template>
   <div class="tasks-container">
        <task-list :claimId="claim.claim_id" :screen="true" :issues="issues" />
   </div>
</template>

<script>
// import { make_url, authenticated_request } from '../../common.js'
import TaskList from '../tasks/TaskList.vue'

export default {
    name: 'WorkClaimTasks',
    components: {
        TaskList
    },
    props: ['issues'],
    computed: {
        claim() {
            return this.$store.state.claims.workedClaim;
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tasks-container {
    padding-top: 25px !important;
}
</style>