<template>
<div id="add-patient">
<b-modal id="new-patient" @hide="resetModal" title="New Patient" hide-footer size="md">
<b-form>
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <div>
        <div class="d-flex">
            <div class="flex-fill mr-2">
                <b-form-group
                id="given_name"
                label="First Name">
                <b-input-group>
                    <b-form-input v-model="patient.given_name"></b-form-input>
                </b-input-group>
                </b-form-group>
            </div>
            <div class="flex-fill ml-2">
                <b-form-group
                id="family_name"
                label="Last Name">
                <b-input-group>
                    <b-form-input v-model="patient.family_name"></b-form-input>
                </b-input-group>
                </b-form-group>
            </div>
        </div>
        <div class="d-flex">
            <div class="flex-fill mr-2">
                <b-form-group
                id="account_no"
                label="Account Number">
                <b-input-group>
                    <b-form-input v-model="patient.account_no"></b-form-input>
                </b-input-group>
                </b-form-group>
            </div>
            <div class="flex-fill ml-2">
                <b-form-group
                id="date_of_birth"
                label="Date of Birth">
                <b-input-group>
                    <b-form-input type="date"
                    today-button
                    reset-button
                    close-button
                    v-model="patient.date_of_birth"></b-form-input>
                </b-input-group>
                </b-form-group>
            </div>
        </div>
        <b-card class="mt-4 mb-4">
            <div class="avo-header-text">
                Contant Info
            </div>
            <hr style="margin-right:-20px;margin-left:-20px">
            <div class="d-flex">
                <div class="mr-2">
                    <b-form-group
                    id="telephone"
                    label="Primary Phone">
                    <b-input-group>
                        <b-form-input v-model="address.telephone"></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </div>
                <div class="ml-2">
                    <b-form-group
                    id="email"
                    label="Email">
                    <b-input-group>
                        <b-form-input v-model="address.email"></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </div>
            </div>
        </b-card>
        <div class="mt-4" style="text-align:right">
            <b-button @click="validatePatientInputs" variant="outline-primary" class="avo-primary-btn" :disabled="submitting">
                <b-overlay :show="submitting" variant="white">Create Patient</b-overlay>
            </b-button>
        </div>
    </div>
</b-form>
</b-modal>
</div>
</template>

<script>
import { alertsMixin, make_url, authenticated_request } from '../../common.js'

export default {
  name: 'PatientAdd',
  props: ['claimId', 'issues'],
  mixins: [alertsMixin],
  data: function(){
      return {
            patient: {
                given_name: null,
                family_name: null,
                account_no: null,
                date_of_birth: null,
            },
            address: {
                telephone: null,
                email: null,
            },
            
            submitting: false,
      }
  },
  methods: {
    clearData(){
            this.patient = {
                given_name: null,
                family_name: null,
                account_no: null,
                date_of_birth: null,
            };
            this.address = {
                telephone: null,
                email: null,
            };
        this.hideError();
    },
    validatePatientInputs(){
        this.submitting = true;
        this.hideError();
        
        if ((this.patient.given_name == "") || (this.patient.given_name == null)){
            this.showError("Please enter a patient first name");
            this.submitting = false;
            return false;
        }
        if ((this.patient.family_name == "") || (this.patient.family_name == null)){
            this.showError("Please enter a patient last name");
            this.submitting = false;
            return false;
        }
        
        this.createPatient();
    },
    createPatient(){
        const params = {
            'patient': this.patient,
            'address': this.address
        };
        const url = make_url("/forms/patient_with_address");
        const vm = this;
        const promise = authenticated_request({
            method: "post",
            url: url,
            data: params
        }).then(function(response){
            if (response) {
                if (vm.claimId){
                    vm.associatePatientWithClaim(response.data);
                }
                else{
                    vm.submitting = false;
                    vm.$bvModal.hide('new-patient');
                }
            }
        }).catch(function(error){
            vm.submitting = false;
            vm.showError(error);
        });
        return promise;
    },
    // Claim functions will only run if claimId is passed in
    associatePatientWithClaim(patient_id){
        const vm = this;
        authenticated_request({
            method: "put",
            url: make_url("/claims/" + this.claimId).toString(),
            data: {
                'patient_id': patient_id
            }
        }).then(function(response){
            if (response){
                vm.refreshWorkedClaimData();
            }
        }).catch(function(errors){
            vm.submitting = false;
            vm.showError(errors);
        });
    },
    refreshWorkedClaimData(){
        var vm = this;
        const url = make_url("/reports/claims");
        authenticated_request({
            method: "get",
            url: url,
            params: {
                'claim_id': vm.claimId,
                'issues_claims': vm.issues,
            }
        }).then(function(response){
            var claim = response.data.claims[0];
            vm.$store.commit("setWorkedClaim", claim);
            vm.$bvModal.hide('new-patient');
        }).catch(function(errors){
            vm.showError(errors);
        }).finally(()=>{
            vm.submitting = false;
        });
    },
    resetModal(){
        this.clearData();
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
  },
}
</script>

<style scoped>

</style>