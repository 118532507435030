<template>
  <multiselect 
    placeholder=""
    :value="worker"
    track-by="worker_id"
    :showNoOptions="false"
    :options="selectableWorkers"
    :show-labels="false"
    label="name"
    class="assigned-multiselect"
    :loading="loading"
    @open="onOpen"
    @select="changeAssignedWorker">
    <template slot="option" slot-scope="props">
    <div v-if="props.option.worker_id">
        <b-avatar size=30px class="mr-1" :text="props.option.initials"></b-avatar>
        {{props.option.name}}
    </div>
    <div v-else>
        Unassigned
    </div>
    </template>
    <template slot="singleLabel" slot-scope="props">
    <div v-if="props.option.worker_id">
        <b-avatar size=27px class="mr-1" :text="props.option.initials"></b-avatar>
        {{props.option.name}}
    </div>
    <div v-else class="m-1" style="padding-top:2px">
        Unassigned
    </div>
    </template>
    <template slot="noResult">
    No Workers found
    </template>
    <template slot="afterList">
      <div v-if="loading">
        Loading Workers ...
      </div>
    </template>
  </multiselect>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Multiselect from 'vue-multiselect'
import { make_url, authenticated_request, alertsMixin } from '../../common.js'

export default {
  name: 'ClaimWorkerSelect',
  props: ['issues'],
  mixins: [alertsMixin],
  components: {
    Multiselect,
  },
  data(){
      return {
        worker: null,
        loading: null,
        
        //Worker assignment/search data
        unassigned: {worker_id: null, name: "Unassigned", initials: ""},
        selectableWorkers: [{worker_id: null, name: "Unassigned", initials: ""}],
      }
  },
  methods: {
    setCurrentWorker(){
      if (!this.issues && this.claim && this.claim.worker){
        this.worker = {
          worker_id: this.claim.worker.worker_id,
          name: this.claim.worker_name,
          initials: this.claim.worker_initials
        }
      }
      else if (this.issues && this.claim && this.claim.issues_worker){
        this.worker = {
          worker_id: this.claim.issues_worker.worker_id,
          name: this.claim.issues_worker_name,
          initials: this.claim.issues_worker_initials
        }
      }
      else{
        this.worker = {
          worker_id: null,
          name: "Unassigned",
          initials: "",
        };
      }
    },
    getWorkers: function(){
      this.loading = true;
      var role;
      if (!this.issues){
        role = "Worker"
      }
      else {
        role = "Issues Worker"
      }
      
      const url = make_url("/reports/workers/autocomplete");
      const vm = this;
      const promise = authenticated_request({
          method: "get",
          url: url,
          params: {
            role: role
          }
      }).then(function(response){
          var workers = response.data.workers;
          workers.unshift(vm.unassigned);
          vm.selectableWorkers = workers || [vm.unassigned];
          return workers || [vm.unassigned];
      }).catch(function(errors) {
          vm.setShowAlert(true, errors);
      }).finally(()=>{
        vm.loading = false;
      });
      
      return promise;
    },
    changeAssignedWorker: function(worker){
      if ((!this.issues && this.claim.worker && this.claim.worker.worker_id) || (this.issues && this.claim.issues_worker && this.claim.issues_worker.worker_id)){
        var url;
        if (!this.issues){
          url = make_url("/workers/" + this.claim.worker.worker_id + "/claims/" + this.claim.claim_id).toString();
        }
        else{
          url = make_url("/workers/" + this.claim.issues_worker.worker_id + "/claims/" + this.claim.claim_id).toString();
        }
        const vm = this;
        const promise = authenticated_request({
            method: "delete",
            url: url,
        }).then(function(response){
          if (response){
            if (worker && worker.worker_id){
              vm.assignNewWorker(worker);
            }
            else {
              if (vm.issues){
                vm.claim.issues_worker = {
                  "worker_id": null,
                }
                vm.claim.issues_worker_name = "Unassigned"
                vm.claim.issues_worker_initials = "";
                vm.$store.commit("setNewClaimWorker", vm.claim.issues_worker);
              }
              else{
                vm.claim.worker = {
                  "worker_id": null,
                }
                vm.claim.worker_name = "Unassigned"
                vm.claim.worker_initials = "";
                vm.$store.commit("setNewClaimWorker", vm.claim.worker);
              }
            }
          }
        }).catch(function(errors) {
            vm.setShowAlert(true, errors);
        });
        
        return promise;
      }
      else{
        this.assignNewWorker(worker);
      }
    },
    assignNewWorker: function(worker){
      var data = {
          claims: [this.claim.claim_id],
      }
      const vm = this;
      var url;
      if (!this.issues){
        url = make_url("/workers/" + worker.worker_id + "/claims");
      }
      else{
        url = make_url("/workers/" + worker.worker_id + "/issues/claims");
      }
      const promise = authenticated_request({
        method: "post",
        url: url,
        data: data
      }).then(function(response){
        if (response){
          if (vm.issues){
            vm.claim.issues_worker = {
              "worker_id": worker.worker_id,
            }
            vm.claim.issues_worker_name = worker.name;
            vm.claim.issues_worker_initials = worker.initials;
          }
          else{
            vm.claim.worker = {
              "worker_id": worker.worker_id,
            }
            vm.claim.worker_name = worker.name;
            vm.claim.worker_initials = worker.initials;
          }
          var new_worker = {
            "worker_id": worker.worker_id,
            "worker_name": worker.name,
            "worker_initials": worker.initials
          }
          vm.$store.commit("setNewClaimWorker", new_worker);
        }
      }).catch(function(errors){
          vm.setShowAlert(true, errors);
      });
      return promise;
    },
    onOpen: function () {
      if (this.loading !== null) {
        return;
      }
      this.getWorkers()
    },
  },
  computed: {
    claim(){
      return this.$store.state.claims.selectedClaim || this.$store.state.claims.workedClaim;
    },
  },
  watch: {
    claim:{
      handler(){
        this.setCurrentWorker()
      },
      deep: true
    }
  },
  async mounted() {
    this.setCurrentWorker()
  }
}
</script>