<template>
  <div>
    <b-row>
      <b-col>
      <avo-alert :avoAlerts.sync="avoAlerts" />
      </b-col>
    </b-row>
    <b-overlay :show="visitLoading" variant="white">
      <b-row>
        <b-col cols="3">
          <label class="selecter-label">Facility</label>
          <b-form-group id="visit-facility">
            <b-form-select
              text-field="name"
              value-field="location_id"
              v-model="newVisit.practice_location_id"
              :options="practice_locations"
              :disabled="disabled"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <label class="selecter-label">Visit Type</label> <!-- Changed from: Procedure type -->
          <b-form-group id="visit-procedure_type">
            <b-form-select
              v-model="newVisit.procedure_type_id"
              value-field="procedure_type_id"
              text-field="name"
              :options="procedure_types"
              :disabled="disabled"
            >
            Select a visit type
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <label class="selecter-label">Tax ID</label>
          <b-form-group id="visit-taxid">
            <b-form-input
              type="text"
              v-model="newVisit.tax_id"
              disabled
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <label class="selecter-label">NPI</label>
          <b-form-group id="visit-npi">
            <b-form-input
              type="text"
              v-model="newVisit.group_npi"
              disabled
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <label class="selecter-label">Billing provider</label>
          <b-form-group id="visit-billing_provider">
            <b-form-select
              text-field="name"
              value-field="provider_id"
              v-model="newVisit.billing_provider_id"
              :options="providers"
              :disabled="disabled"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <label class="selecter-label">DOS</label>
          <b-form-group id="visit-date_of_service">
            <b-form-input
              type="date"
              v-model="newVisit.date_of_service"
              :disabled="disabled"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <label class="selecter-label">Start time</label>
          <b-form-group id="visit-start_time">
            <b-form-input
              type="time"
              v-model="newVisit.start_time"
              locale="en"
              hide-header
              :disabled="disabled"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <label class="selecter-label">End time</label>
          <b-form-group id="visit-end_time">
            <b-form-input
              type="time"
              v-model="newVisit.end_time"
              locale="en"
              hide-header
              :disabled="disabled"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <label class="selecter-label">Servicing provider</label>
          <b-form-group id="visit-servicing_provider">
            <b-form-select
              text-field="name"
              value-field="provider_id"
              v-model="newVisit.servicing_provider_id"
              :options="providers"
              :disabled="disabled"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <label class="selecter-label">Resource</label>
          <b-form-group id="visit-resource_provider">
            <b-form-select
              text-field="name"
              value-field="provider_id"
              v-model="newVisit.resource_provider_id"
              :options="providers"
              :disabled="disabled"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <label class="selecter-label">Rendering provider</label>
          <b-form-group id="visit-rendering_provider">
            <b-form-select
              text-field="name"
              value-field="provider_id"
              v-model="newVisit.rendering_provider_id"
              :options="providers"
              :disabled="disabled"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <label class="selecter-label">Supervising provider</label>
          <b-form-group id="visit-supervising_provider">
            <b-form-select
              text-field="name"
              value-field="provider_id"
              v-model="newVisit.supervising_provider_id"
              :options="providers"
              :disabled="disabled"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <label class="selecter-label">Referring/PCP</label>
          <b-form-group id="visit-referring_provider">
            <b-form-select
              text-field="name"
              value-field="provider_id"
              v-model="newVisit.referring_provider_id"
              :options="providers"
              :disabled="disabled"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col style="text-align: center">
          <b-button
            v-if="visit_id && visitChanged"
            class="avo-primary-btn"
            @click="submitData"
            >Commit changes to visit</b-button
          >
          <b-button
            v-else-if="visitChanged"
            class="avo-primary-btn"
            @click="submitData"
            >Create visit</b-button
          >
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import Vue from 'vue';
import { 
  alertsMixin,
  make_url, 
  authenticated_request, 
  formatLocalDateForSelect as formatDate,
  formatLocalTimeForSelect as formatTime,
} from "../../common.js";
import AvoAlert from '../../components/AvoAlert.vue';

export default Vue.extend({
  name: "ClaimVisit",
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    claimId: {
      type: Number,
      default: null,
    },
    visitId: {
      type: Number,
      default: null,
    },
  },
  components: {
    AvoAlert,
  },
  mixins: [alertsMixin],
  data() {
    return {
      visit_id: null,
      visit: {},
      newVisit: {
        practice_location_name: "",
        tax_id: "",
        npi: "",
        visit_type: "",
        procedure_type_id: "",
        date_of_service: "",
        start_time: "",
        end_time: "",
        servicing_provider_id: "",
        resource_provider_id: "",
        billing_provider_id: "",
        rendering_provider_id: "",
        supervising_provider_id: "",
        referring_provider_id: "",
      },
      practice_locations: [],
      providers: [],
      visit_types: [],
      procedure_types: [],
      visitChanged: false,
      visitLoading: true,
      dataLoaded: false,
    };
  },
  methods: {
    formatDate,
    formatTime,
    loadData() {
      this.getVisitReport().then(() => this.setDataLoaded());
    },
    getVisitReport() {
      var self = this;
      var endpoint_url;
      if (this.claimId) {
        endpoint_url = make_url(
          "/reports/claims/" + this.claimId + "/visits/summary"
        );
      } else if (this.visitId) {
        endpoint_url = make_url("/reports/visits/" + this.visitId);
      }
      const promise = authenticated_request({
        method: "get",
        url: endpoint_url,
      })
        .then(function (response) {
          self.handleReportResponse(response.data);
          return response.data;
        })
        .catch(function (error) {
          self.showError(error);
        })
        .finally(() => {
          self.visitLoading = false;
        });
      return promise;
    },
    handleReportResponse(data) {
      this.practice_locations = data.facilities_options || [];
      this.providers = data.providers_options || [];
      // this.visit_types = data.visit_types || [];
      this.procedure_types = data.procedure_types || [];
      this.visit = {
        servicing_provider_id: data.servicing_provider_id,
        resource_provider_id: data.resource_provider_id,
        billing_provider_id: data.billing_provider_id,
        rendering_provider_id: data.rendering_provider_id,
        supervising_provider_id: data.supervising_provider_id,
        referring_provider_id: data.referring_provider_id,
        practice_location_id:
          data.practice_location_id || data.claim_facility_id,
        tax_id: data.visit_tax_id || data.claim_tax_id,
        group_npi: data.visit_group_npi || data.claim_group_npi,
        visit_type: data.visit_type,
        procedure_type_id: data.procedure_type_id,
        date_of_service: this.formatDate(data.date_of_service),
        start_time: this.formatTime(data.start_time),
        end_time: this.formatTime(data.end_time),
      };
      this.newVisit = Object.assign({}, this.visit);
      this.visit_id = data.visit_id;
    },
    setDataLoaded() {
      this.dataLoaded = true;
    },
    submitData() {
      if (this.dataLoaded) {
        var visit_data = this.getChanges();
        if (Object.keys(visit_data).length > 0) {
          this.submitVisit(visit_data);
        }
      }
    },
    getChanges() {
      var visit_data = {};
      for (let key in this.newVisit) {
        if (this.newVisit[key] != this.visit[key]) {
          if (key != "tax_id" && key != "group_npi") {
            if (
              key != "start_time" &&
              key != "end_time" &&
              key != "date_of_service"
            ) {
              visit_data[key] = this.newVisit[key];
            }
          }
        }
      }
      visit_data['start_time'] = new Date(this.newVisit.date_of_service + ' ' + this.newVisit.start_time);
      visit_data['end_time'] = new Date(this.newVisit.date_of_service +  ' ' + this.newVisit.end_time);
      visit_data['date_of_service'] = new Date(this.newVisit.date_of_service + ' ' + this.newVisit.start_time);

      return visit_data;
    },
    submitVisit(visit_data) {
      var url;
      var method;
      if (this.visit_id != null) {
        url = make_url("/visits/" + this.visit_id);
        method = "put";
      } else if (this.claimId != null) {
        url = make_url("/claims/" + this.claimId + "/visits");
        method = "post";
      }
      const self = this;
      const promise = authenticated_request({
        method: method,
        url: url,
        data: visit_data,
      })
        .then(function (response) {
          if (response) {
            self.showAvoSuccess("Successfuly submitted visit");
            if (!self.visit_id) {
              self.visit_id = response.data;
            }
            self.visit = Object.assign({}, self.newVisit);
            self.visitChanged = false;
          }
        })
        .catch(function (error) {
          self.showAvoAlert(error);
        })
        .finally(function () {
          self.$emit("visitUpdated", self.getChanges());
        });
      return promise;
    },
  },
  computed: {
    disabled() {
      return !(this.editable && this.dataLoaded);
    },
  },
  watch: {
    newVisit: {
      handler() {
        if (!this.visitChanged && this.dataLoaded) {
          this.visitChanged = true;
        }
      },
      deep: true,
    },
  },
  beforeMount: function () {
    this.loadData();
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.selecter-label {
  margin-top: 0.5rem;
}
</style>