<template>
<div>
    <b-card class="header-card">
      <div class="d-flex flex-row justify-content-between">
        <div class="summary-card-header-txt pl-2">
          {{title}}
        </div>
        <div @click="setActiveTab('WorkClaimActivity')" class="avo-text-light pr-2 mt-1 cursor-pointer" >
          View all Activity <b-icon icon="chevron-right"></b-icon>
        </div>
      </div>
    </b-card>
    <b-overlay :show="eventsLoading"  variant="white" style="min-height:200px;" :class="eventsLoading ? 'events' : ''">
    <b-table id="my-table" 
      ref="table"
      :sort-desc.sync="sortDesc"
      :per-page="perPage" 
      :current-page="currentPage" 
      :items="events"
      :fields="eventsFields"
      :borderless="borderless"
      thead-class="d-none"
      class="events"
      primary-key="event_id">
      <template #cell(main-col)="data">
        <div class="p-2 pr-0">
          <div class="d-flex flex-row align-items-center">
            <div class="mr-4">
                <b-iconstack>
                <b-icon icon="circle-fill" style="color:#F3F8FD" scale="2"></b-icon>
                <b-icon v-if="getEventIconType(data.item) == 'Document'" icon="paperclip"></b-icon>
                <b-icon v-else-if="getEventIconType(data.item) == 'Note'" icon="file-earmark"></b-icon>
                <b-icon v-else-if="getEventIconType(data.item) == 'Task'" icon="calendar-4"></b-icon>
                <b-icon v-else-if="getEventIconType(data.item) == 'Worklog'" icon="list-ul"></b-icon>
                <b-icon v-else-if="getEventIconType(data.item) == 'Worker'" icon="person"></b-icon>
                <b-icon v-else-if="getEventIconType(data.item) == 'Claim'" icon="file-earmark-medical"></b-icon>
                <b-icon v-else-if="getEventIconType(data.item) == 'Code'" icon="card-list"></b-icon>
                <b-icon v-else-if="getEventIconType(data.item) == 'Payment'" icon="credit-card"></b-icon>
                <b-icon v-else-if="getEventIconType(data.item) == 'Flagged'" icon="exclamation-triangle"></b-icon>
                <b-icon v-else-if="getEventIconType(data.item) == 'Transition'" icon="arrow-left-right"></b-icon>
                <b-icon v-else icon="list-ul"></b-icon>
                </b-iconstack>
            </div>
            <div>
                <div class="label-text" style="font-size:16px;font-weight:400">
                    {{data.item.label}}<span v-if="data.item.payload.title">:
                    <span class="label-text" style="font-size:16px">{{data.item.payload.title}}</span>
                    </span>
                </div>
                <div class="avo-text-light">
                    {{formatDate(data.item.occurred_at)}} at {{formatTime(data.item.occurred_at)}}
                </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column align-items-end flex-wrap">
            <!-- <b-avatar class="mr-1" :text="event.initials"></b-avatar> -->
            <div class="text-nowrap">by {{data.item.name}}</div>
            <div class="avo-text-light">{{data.item.user_role ? data.item.user_role : 'Worker'}}</div>
        </div>
        
      </template>
    </b-table>
    <div v-if="events.length == 0 && eventsLoading" class="no-events">
        Fetching events
    </div>
    <div v-if="events.length == 0 && !eventsLoading" class="no-events">
        No events found
    </div>
    <div v-if="!hideFooter" class="footer-text"><b-link href="#todo">View All Activity</b-link></div>
    </b-overlay>
</div>
</template>


<script>
import { formatFullDate as formatDate, formatTime } from '../../common.js'

export default {
  name: 'ActivityList',
  components: {},
  props: ['claimId', 'userId', 'perPage', 'tableTitle', 'hideFooter', 'hideViewAll'],
  data(){
      return {
          eventsFields: [
              {key: 'main-col'}
          ],
          perPageVar: this.perPage || 5,
          title: this.tableTitle || "Activity",
          currentPage: 1,
          sortBy: "occurred_at",
          sortDesc: true,
          borderless: true,
      }
  },
  methods: {
    getEvents: function(){
      var params = {
          per_page: this.perPageVar,
          page: this.currentPage,
      }
      if (this.userId){
        params.user_id = this.userId
      }
      if(this.sortBy){
        params.sort = this.sortBy + ":" + (this.sortDesc?"desc":"asc")
      }
      this.$store.dispatch('fetchClaimActivityLogs', {claimId: this.claimId, params: params});
    },
    setActiveTab: function(component){
        this.$store.commit("setWorkedClaimActiveTab", component);
    },
    getEventIconType(event){
        var eventArr = event.event.split('.');
        if(eventArr[1] == "notes"){
            return "Note"
        } else if(eventArr[1] == "documents"){
            return "Document"
        } else if(eventArr[1] == "tasks"){
            return "Task"
        } else if(eventArr[1] == "worklog"){
            return "Worklog"
        } else if(eventArr[1] == "workers"){
            return "Worker"
        } else if(eventArr[1] == "claims"){
            if(eventArr[2] == "codes"){
                return "Code"
            } else if(eventArr[2] == "payment"){
                return "Payment"
            } else if(eventArr[2] == "flagged"){
                return "Flagged"
            } else if(eventArr[2] == "transitioned"){
                return "Transition"
            } return "Claim"
        }
    },
    formatDate,
    formatTime,
  },
  computed: {
    refreshActivitySummary() {
      return this.$store.state.claims.reloadActivitySummary;
    },
    rows() {
      return this.$store.state.claims.activityLogs?.total || 0;
    },
    events() {
      return this.$store.state.claims.activityLogs?.events || [];
    },
    eventsLoading() {
      return this.$store.state.claims.activityLogsClaimId != this.claimId;
    },
  },
  watch: {
    refreshActivitySummary(newState){
        // console.log("activity summary refresh (from watch): " + newState)
        if(newState){
            this.getEvents();
            this.$store.commit("activitySummaryRefreshed");
        } 
    },
  },
  // activated() is only fired when a kept-alive component is navigated to;
  // complemented by the above watch: which updates activity if sibling on same page creates a log.
  activated() {
    // console.log("activity summary refresh (from activated): " + this.refreshActivitySummary)
    if(this.refreshActivitySummary){
      this.getEvents();
      this.$store.commit("activitySummaryRefreshed");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-card{
  border-radius: 5px 5px 0px 0px;
  border: 1px solid #E2E4EB;
}
.footer-text {
  border-radius: 0px 0px 5px 5px;
  border: 1px solid #E2E4EB;
  border-top: 0px;
  height: 48px;
  margin-top: 0rem;
  padding: .75rem;
  text-align: center;
}
.footer-text > a {
  font: 12px/14px;
  font-weight: 400;
  color:var(--blue);
}
.events {
  border-collapse: separate;
  border-spacing: 0px;
  border: 1px solid #E2E4EB;
  border-radius: 0px 0px 5px 5px;
  border-top: 0px;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.no-events {
  font-size: 16px;
  font-weight: 200;
  text-align: center;
  border: 1px solid #E2E4EB;
  border-radius: 0px 0px 5px 5px;
  border-top: 0px;
  padding: 20px;
  min-height: 75px;
}
::v-deep .events td {
  min-height: 75px;
  border-bottom: 1px solid #e6eef9;
  display:flex;
  justify-content: space-between;
  align-items:center;
  padding-left:3%;
  padding-right:3%;
}
::v-deep .events tr:hover {
  background-color: #f2f6fc;
}
</style>