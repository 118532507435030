<template>
<b-modal @hide="resetModal" id="new-note" title="New Note" no-close-on-backdrop hide-footer size="md">
<div>
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>You must provide at least a title or description to create a new note.</b>
    </b-alert>

</div>
<div class="p-3">
    <b-form @submit="onSubmit">
        <b-form-group
            id="note-type"
            label="Type:">
            <b-form-select v-model="note.note_type" :options="types">
                <template #first>
                <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                </template>
            </b-form-select>
        </b-form-group>
        <b-form-group
            id="note-title"
            label="Title:">
            <b-form-input v-model="note.note_title"></b-form-input>
        </b-form-group>
        <b-form-group
            id="note-body"
            label="Body:">
            <b-form-textarea v-model="note.note_body"></b-form-textarea>
        </b-form-group>
        <div class="text-right">
            <b-button @click="onSubmit" class="note-submit-btn">Create Note</b-button>
        </div>
    </b-form>
</div>
</b-modal>
</template>

<script>
import { make_url, authenticated_request } from '../../common.js'

export default {
    name: 'NoteAdd',
    components: {},
    props: ['claimId', 'preauthId'],
    data() {
        return {
            note: {
                note_type: null,
                note_title: null,
                note_body: null,
                worker_id: null
            },
            showAlert: false,
        }
    },
    
    computed: {
        types() {
            return this.$store.state.claims.notesTypes;
        }
    },
    
    methods: {
        onSubmit() {
            if(this.isEmpty()) {
                this.showAlert = true;
                return
            }
            const url = (this.claimId) ? make_url("/claims/" + this.claimId + "/notes") : make_url("/preauths/" + this.preauthId + "/notes");
            const vm = this;
            const promise = authenticated_request({
                method: "post",
                url: url,
                data: {
                    title: vm.note.note_title,
                    body: vm.note.note_body,
                    note_type: vm.note.note_type
                }
            }).then(function(){
                vm.$emit('noteAdded');
                vm.showAlert = false;
                vm.$bvModal.hide('new-note');
                vm.$store.commit("invalidateNotesList");
                vm.$store.commit("refreshActivityLog");
            }, function(error){
                console.log(error)
            });
            return promise;
        },
        
        resetModal(){
            this.note = {
                note_type: null,
                note_title: null,
                note_body: null,
                worker_id: null
            }
        },

        isEmpty() {
            return Object.values(this.note).every(x => (x === null || x === ''));
        }
    
    },
    mounted() {
        if (this.types == null) {
            console.log("Notes types not loaded yet");
            this.$store.dispatch("fetchNotesTypesMap");
        }
    }
}
</script>

<style scoped>
.alert {
    background: #FDEEED 0% 0% no-repeat padding-box;
    border: 1px solid var(--red);
    border-radius: 5px;
    text-align: center;
    letter-spacing: 0px;
    color: var(--red);
}

.note-submit-btn {
    height:40px;
    width:113px;
    border: 2px solid var(--blue);
    border-radius: 5px;
    background: white;
    color: var(--blue);
    font-size: 14px;
}
.note-submit-btn:hover {
    background: #EEF1FA 0% 0% no-repeat padding-box;
}
.note-submit-btn:active {
    border: none !important;
    box-shadow: none !important;
    background: var(--blue) 0% 0% no-repeat padding-box !important;
    color: white !important;
}
</style>