<template>
<div>
    <b-row>
        <b-col>
            <b-alert
                v-model="showAlert"
                variant="danger"
                dismissible>
                <b>{{alertMessage}}</b>
            </b-alert>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <div class="avo-header-text-med mb-3">Payments From Payer</div>
            <div class="billing-card">
                <b-row class="px-3">
                    <b-col cols="6" class="p-3">
                        <b-row class="mx-0 mb-2">
                            <b-col cols="6">
                                <span class="avo-text-light">SUMMARY</span>
                            </b-col>
                            <b-col cols="2" class="text-right">
                                <span class="avo-text-light">BILLED</span>
                            </b-col>
                            <b-col cols="2" class="text-right">
                                <span class="avo-text-light">PAID</span>
                            </b-col>
                            <b-col cols="2" class="text-right">
                                <span class="avo-text-light">BALANCE</span>
                            </b-col>
                        </b-row>
                        <b-row  class="mx-0 mb-2 pb-1" style="border-bottom:1px solid #E2E4EB;">
                            <b-col cols="6">
                                Responsible For:
                            </b-col>
                            <b-col cols="2" class="text-right">
                                ${{groupedCodesAndTotals.totalCharged}}
                            </b-col>
                            <b-col cols="2" class="text-right">
                                ${{groupedCodesAndTotals.totalPaid.toFixed(2)}}
                            </b-col>
                            <b-col cols="2" class="text-right">
                                ${{groupedCodesAndTotals.totalBalance}}
                            </b-col>
                        </b-row>
                        <b-row v-for="(code, index) in groupedCodesAndTotals.payerResponsibilityCodes" :key="index" class="mx-0 mb-1">
                            <b-col cols="6">
                                <span class="data-text">{{code.code}}: </span><span class="avo-text-light-small">{{code.short}}</span>
                            </b-col>
                            <b-col cols="2" class="text-right">
                                <span class="data-text">${{calculateChargedAmount(code)}}</span>
                            </b-col>
                            <b-col cols="2" class="text-right">
                                <span class="data-text">${{Number(code.total_payer_payments || 0).toFixed(2)}}</span>
                            </b-col>
                            <b-col cols="2" class="text-right">
                                <span class="data-text">${{calculateCodeBalance(code)}}</span>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="6" class="p-4" style="background-color:#F3F8FD;max-height:100%">
                        <b-row class="mb-4">
                            <b-col cols="4">
                                <div class="avo-text-light">Primary Insurance</div>
                                <div class="data-text">{{(primaryInsurance.name || claim.payer_name)}}</div>
                            </b-col>
                            <b-col cols="4">
                                <div class="avo-text-light">MEM#</div>
                                <div class="data-text">{{(primaryInsurance.member_id || '-')}}</div>
                            </b-col>
                            <b-col cols="4">
                                <div class="avo-text-light">GRP#</div>
                                 <div class="data-text">{{(primaryInsurance.group_no || '-')}}</div>
                            </b-col>
                        </b-row>
                        <b-row class="mb-4">
                            <b-col cols="4">
                                <div class="avo-text-light">Subscriber</div>
                                <div class="data-text">{{claim.patient_name}}</div>
                            </b-col>
                            <b-col cols="4">
                                <div class="avo-text-light">Status</div>
                                <div>
                                    <span class="effective-box">{{insuranceStatus(primaryInsurance)}}</span>
                                </div>
                                <div class="avo-text-light">
                                    {{ dateFormat(primaryInsurance.effective_start_date) }}
                                    - 
                                    {{ dateFormat(primaryInsurance.effective_end_date) }}
                                </div>
                            </b-col>
                            <b-col cols="4">
                                <div class="avo-text-light">Payer Contact</div>
                                <div class="data-text"><b-icon icon="telephone" /> {{(claim.payer_phone || "-")}}</div>
                                <div class="data-text"><b-icon icon="printer" /> {{(claim.payer_fax || "-")}}</div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <div class="d-flex justify-content-between align-items-center my-4">
                <div class="d-flex flex-column">
                    <div class="avo-header-text-med">Payments From Payer</div>
                </div>
            </div>
        </b-col>
    </b-row>
    <itemized-payments :source="'payer'"></itemized-payments>
</div>
</template>

<script>
import { make_url, authenticated_request, formatDateShort, alertsMixin } from '../../common.js'

import ItemizedPayments from '../payments/ItemizedPayments.vue'

export default {
    name: 'PaymentsPayer',
    mixins: [alertsMixin],
    components: {
        ItemizedPayments,
    },
    data(){
        return {
            cptCodeReportData: {},
            addPaymentData: null,
            primaryInsurance: {},
            eras: [],
            
            codesLoading: true,
            insurancesLoading: false,
        }
    },
    methods: {
        getCodes: function(){
            console.log("getting codes from payer tab!!!")
            const url = make_url("/reports/claims/" + this.claim.claim_id + "/cpt_codes");
            const self = this;
            const promise = authenticated_request({
                method: "get",
                url: url
            }).then(function(response){
                self.cptCodeReportData = response.data;
            }).catch(function(error){
                console.log(error.response)
            }).finally(()=>{
                self.codesLoading = false;
            });
            return promise;
        },
        getPatientInsurances: function() {
            const url = make_url("/patients/reports/" + this.claim.patient_id + "/insurance");
            const self = this;
            const promise = authenticated_request({
                method: "get",
                url: url
            }).then(function(response){
                const insurances = response.data.insurance;
                insurances.forEach(function(insurance){
                    if(insurance.payer_id == self.claim.payer_id){
                        self.primaryInsurance = insurance;
                    }
                })
                self.hideError()
                self.insurances = insurances;
                return insurances;
            }).catch(function(errors){
                self.showError(errors);
            }).finally(()=>{
                self.insurancesLoading = false;
            });
            return promise;
        },
        insuranceStatus: function(insurance) {
            var today = new Date().toISOString().slice(0, 10);
            if ((insurance.effective_start_date && insurance.effective_end_date) && 
                (today > insurance.effective_start_date && today < insurance.effective_end_date) &&
                insurance.active_benefits)
            {
                return "Effective";
            }
            else {
                return "Not effective";
            }
        },
        dateFormat(date){
            if (date){
                return new Date(date).toLocaleString("en-US", {month: 'numeric', day: 'numeric', year: 'numeric'});
            }
            return "N/A";
        },
        showAddPaymentModal(){
            this.addPaymentData = {
                'payerResponsibility': JSON.parse(JSON.stringify(this.groupedCodesAndTotals.payerResponsibilityCodes)),
                'patientResponsibility': JSON.parse(JSON.stringify(this.groupedCodesAndTotals.patientResponsibilityCodes)),
            }
            this.$bvModal.show('new-claim-payment');
        },

        calculateChargedAmount(code){
            return Number(code.payer_responsibility || 0).toFixed(2)
        },
        calculateCodeBalance(code){
            return Number((code.payer_responsibility || 0) - (parseFloat(code.total_payer_payments) || 0)).toFixed(2)
        },

        formatDateShort(datetime) {
            return formatDateShort(datetime)
        },

        //error methods
        showError(alertMessage){
            this.setShowAlert(true, alertMessage);
        },
        hideError(){
            this.setShowAlert(false);
        },

    },
    computed: {
        claim() {
            return this.$store.state.claims.workedClaim;
        },
        groupedCodesAndTotals(){
            var returnObj = {
                payerResponsibilityCodes: [],
                totalCharged: 0,
                totalPaid: 0,
                totalBalance: 0,
            }
            if(!this.cptCodeReportData.codes){
                return returnObj 
            }
            this.cptCodeReportData.codes.forEach(function(code){
                if(code.responsibility_type == 2){
                    returnObj.payerResponsibilityCodes.push(code);
                    returnObj.totalCharged += Number(code.payer_responsibility || 0)
                    returnObj.totalPaid += Number(code.total_payer_payments || 0)
                } else if (code.responsibility_type == 3){
                    returnObj.payerResponsibilityCodes.push(code);
                    returnObj.totalCharged += Number(code.payer_responsibility || 0)
                    returnObj.totalPaid += Number(code.total_payer_payments || 0)
                }
            });
            returnObj.totalBalance = (returnObj.totalCharged - returnObj.totalPaid).toFixed(2)
            return returnObj
        },
    },
    mounted: function(){
        this.getCodes();
        this.getPatientInsurances();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.billing-card {
    /* padding: 20px; */
    border-radius: 5px;
    background: white;
    border: 1px solid #E2E4EB;
}
.effective-box{
    padding:5px;
    padding-left: 10px;
    padding-right: 10px;
    background:#eef1fa;
    border-radius:5px;
}

</style>