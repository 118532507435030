<template>
   <div class="worklog-container">
       <work-log :issues="issues" :embedded="false" />
   </div>
</template>

<script>
import WorkLog from '../worklog/WorkLog.vue'

export default {
    name: 'WorkClaimWorkLog',
    components: {
        WorkLog
    },
    props: ['claim', 'issues'],
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.worklog-container {
    padding-top: 25px;
}
</style>