<template>
<div>
    <b-row class="mb-4 justify-content-md-center">
        <b-col v-if="!hasPermission('assign_claims')" tabs class="nav-section">
            <b-nav>
                <b-nav-item :active="bucket_option == 'unassigned'" @click="bucket_option = 'unassigned'">Unassigned claims</b-nav-item>
                <b-nav-item :active="bucket_option == 'my_claims'" @click="bucket_option = 'my_claims'">My claims</b-nav-item>
                <b-nav-item :active="bucket_option == 'all_claims'" @click="bucket_option = 'all_claims'">View all</b-nav-item>
            </b-nav>
        </b-col>
        <b-col sm="12" :md=" hasPermission('assign_claims') ? 9 : 7">
            <div class="d-flex justify-content-between align-items-center">
            <b-input-group class="search-group">
                <b-input-group-prepend>
                    <span class="input-group-text"><b-icon icon="search"></b-icon></span>
                </b-input-group-prepend>
                <b-form-input
                    @update="claimAutoSearch"
                    class="claim-search-input pl-1"
                    type="search" v-model="search_inputs.terms"
                    v-b-tooltip="{ variant: 'dark', placement: 'bottom', title: 'You can also narrow your results using the filters on the right' }"
                    placeholder="Try typing something to search, or use the filters on the right"/>                
                <b-input-group-append >
                    <span class="divider"></span>
                    <b-dropdown class="dropdown flag-dropdown">
                        <template #button-content>
                            {{flagInputLabel}}
                        </template>
                        <b-dropdown-form>
                            <div style="font-size:20px;font-weight:300;margin-bottom:15px;">Substatuses</div>
                            <b-form-checkbox 
                              class="mb-2"
                              v-model="doSelectAllSubstatuses" 
                              @change="selectAllSubstatuses">
                                <span class="avo-text-bold">Select/Deselect All</span>
                            </b-form-checkbox>
                            <b-form-checkbox-group
                                v-model="search_inputs.substatuses"
                                :options="substatuses"
                                checkboxes
                                stacked
                                @change="claimAutoSearch">
                            </b-form-checkbox-group>
                        </b-dropdown-form>
                    </b-dropdown>
                    <span class="divider"></span>
                    <b-dropdown class="dropdown filter-dropdown" 
                        boundary="window"
                        v-b-tooltip="{ variant: 'dark', placement: 'bottom', title: 'Visibility of some claims may be limited based on your practice assignments' }"
                        >
                        <template #button-content>
                            {{practiceInputLabel}}
                        </template>
                        <b-dropdown-form class="d-flex flex-column align-items-start">
                            <div class="selecter-label">Select practices to filter by</div>
                            <practice-multiselect
                              v-model="search_inputs.practices"
                              :multiple="true"
                              @input="commitSearch"
                            />
                        </b-dropdown-form>
                    </b-dropdown>
                    <span class="divider"></span>
                    <b-dropdown 
                        class="dropdown filter-dropdown" 
                        boundary="window" 
                        v-b-tooltip="{ variant: 'dark', placement: 'bottomright', title: 'Press Apply once you\'ve set your filters' }"
                        ref="filterDropdown">
                        <template #button-content>
                          <b-icon-funnel class="mr-2" />{{filterInputLabel}}
                        </template>
                        <b-dropdown-form 
                            id="filters-dropdown"
                            class="d-flex flex-column align-items-start"
                        >
                        <b-tooltip target="filters-dropdown" placement="right" show variant="dark" title="Press Apply below to commit your selected filters" />
                        <span style="font-size:20px;font-weight:300">Other Filters</span>
                        <div v-if="!issues" class="selecter-label">Claim Group</div>
                        <b-tabs 
                            v-b-tooltip="{ variant: 'dark', placement: 'right', title: 'Searching all claims will take a long time' }"
                            pills fill>
                            <b-tab title="Open" :active="search_inputs.is_closed == false" @click="search_inputs.is_closed = false"></b-tab>
                            <b-tab title="Closed" :active="search_inputs.is_closed" @click="search_inputs.is_closed = true"></b-tab>
                            <b-tab 
                                title="Both" id="both-select" :active="search_inputs.is_closed == null" @click="search_inputs.is_closed = null"></b-tab>
                        </b-tabs>

                        <div class="selecter-label">Age of Claim</div>
                        <b-form-checkbox-group
                          v-model="search_inputs.ages"
                          :options="age_ranges"
                          :disabled="!canModifyAgeRanges"
                          buttons
                          button-variant="outline-primary"
                          class="checkbox-btn-group"
                          v-b-tooltip="{ variant: 'dark', placement: 'bottom', title: ageRangesMessage }"
                          id="search-ages"
                        />
                        
                        <div class="selecter-label">Include archived claims</div>
                        <b-form-checkbox
                            v-model="search_inputs.include_old_claims"
                            :value="true"
                            label="Include archived claims"
                            checkbox
                            id="old-claims"
                            class="avo-text-light"
                            v-b-tooltip="{ variant: 'dark', placement: 'left', title: 'Claims older than 2 years are excluded from search results unless this is checked' }"
                        >Include claims 2+ years old</b-form-checkbox>
                        
                        <div class="selecter-label">Claim Balance</div>
                        <b-form-row class="claim-balance-group">
                          <b-col>
                          <b-form-group
                            label="Min"
                            label-for="search-min-balance"
                            >
                            <b-form-input
                              v-model="search_inputs.min_balance"
                              type="number"
                              id="search-min-balance"
                              />
                          </b-form-group>
                          </b-col>
                          <b-col>
                          <b-form-group
                            label="Max"
                            label-for="search-max-balance"
                            >
                            <b-form-input
                              v-model="search_inputs.max_balance"
                              type="number"
                              id="search-max-balance"
                              />
                          </b-form-group>
                          </b-col>
                          <b-col cols />
                        </b-form-row>

                        <div v-if="!search_inputs.is_closed" class="selecter-label">Status of Claim</div>
                        <multiselect 
                          v-if="!search_inputs.is_closed"
                          v-model="search_inputs.states"
                          :hide-selected="true"
                          :options="states"
                          track-by="state" 
                          label="label"
                          :multiple="true"
                          :show-labels="false"
                          placeholder="-- Select an Option --"
                        />
                        
                        <div v-if="hasPermission('assign_claims')" class="selecter-label">Assigned</div>
                        <worker-multiselect v-if="hasPermission('assign_claims')"
                          v-model="search_inputs.workers"
                          :multiple="true"
                        />
                        
                        <div class="selecter-label">Insurance</div>
                        <payer-multiselect 
                          v-model="search_inputs.payers"
                          :multiple="true"
                        />

                        <div class="selecter-label">Practice</div>
                        <practice-multiselect
                          v-model="search_inputs.practices"
                          :multiple="true"
                        />
                        
                        <div class="selecter-label">Location</div>
                        <multiselect 
                          v-model="search_inputs.locations"
                          :hide-selected="true"
                          :options="selectableLocations"
                          track-by="location_id" 
                          :custom-label="locationLabel"
                          :multiple="true"
                          :show-labels="false"
                          placeholder="-- Select an Option --">
                            <span slot="noOptions">
                              Select a practice to see available locations
                            </span>
                        </multiselect>
                        
                        <div class="selecter-label">Provider</div>
                        <provider-multiselect
                          v-model="search_inputs.providers"
                          :multiple="true"
                        />

                        <div class="selecter-label">CPT Code</div>
                        <code-multiselect
                          v-model="search_inputs.cpt_codes"
                          :multiple="true"
                        />

                        <div class="selecter-label">Visit Type</div> <!-- Changed from: Procedure type -->
                        <multiselect 
                          v-model="search_inputs.procedure_types"
                          :hide-selected="true"
                          :close-on-select="false"
                          :options="procedure_types"
                          track-by="procedure_type_id" 
                          label="name"
                          :multiple="true"
                          :show-labels="false"
                          placeholder="-- Select an Option --"
                        />
                        
                        <div class="d-flex justify-content-end mt-4">
                            <b-button @click="clearFilters" variant="outline-primary" class="avo-basic-btn">Clear Filters</b-button>
                            <b-button 
                                @click="onApplyFilters" 
                                variant="outline-primary" 
                                class="avo-primary-btn ml-3"
                                id="apply-button"
                                >Apply</b-button>
                        </div>
                        </b-dropdown-form>
                    </b-dropdown>
                </b-input-group-append>
            </b-input-group>
            </div>
        </b-col>
    </b-row>
</div>
</template>

<script>
import { make_url, authenticated_request, debounce, permissionsMixin, parseAgeRanges, } from '../../common.js'
import Multiselect from 'vue-multiselect'
import PayerMultiselect from '../multiselects/PayerMultiselect.vue'
import WorkerMultiselect from '../multiselects/WorkerMultiselect.vue'
import PracticeMultiselect from '../multiselects/PracticeMultiselect.vue'
import ProviderMultiselect from '../multiselects/ProviderMultiselect.vue'
import CodeMultiselect from '../multiselects/CodeMultiselect.vue'

export default {
  name: 'ClaimSearch',
  mixins: [permissionsMixin,],
  props: ['issues', 'initialClaimId'],
  components: { 
    Multiselect,
    PayerMultiselect,
    WorkerMultiselect,
    PracticeMultiselect,
    ProviderMultiselect,
    CodeMultiselect,
  },
  data() {
      return {
          search_inputs: {
              terms: "",
              substatuses: [],
              ages: [],
              states: [],
              payers: [],
              workers: [],
              practices: [],
              locations: [],
              providers: [],
              cpt_codes: [],
              visit_types: [],
              procedure_types: [],
              include_old_claims: false,
              is_closed: null,
              min_balance: null,
              max_balance: null,
          },
          doSelectAllSubstatuses: false,
          search_params: {
              search_terms: "",
              age_ranges: "",
              states: "",
              substatuses: "",
              payer_ids: "",
              worker_ids: "",
              practice_ids: "",
              location_ids: "",
              provider_ids: "",
              cpt_codes: "",
              visit_types: "",
              procedure_types: "",
              is_closed: null,
              min_balance: null,
              max_balance: null,
          },
          assigned_age_ranges: null,
          age_ranges: [
              {text: "0-30 days", value:"0,30"},
              {text: "31-60 days", value:"31,60"},
              {text: "61-90 days", value:"61,90"},
              {text: "91-120 days", value:"91,120"},
              {text: "121+ days", value:"121,730"},
          ],
          states: [],
          substatuses: [],
          practices: [],
          locations: [],
          visit_types: [],
          procedure_types: [],
          bucket_option: 'all_claims',
      }
  },
  methods: {
      clearFilters: function(){
        this.clearSearchInputs();
        this.clearSearchParams();
      },
      selectAllSubstatuses() {
        if (this.doSelectAllSubstatuses) {
          this.search_inputs.substatuses = Object.keys(this.substatuses).map(function(key) {
              return key;
          });
        } else {
          this.search_inputs.substatuses = [];
        }
        this.claimAutoSearch();
      },
      clearSearchInputs: function(){
        this.search_inputs.terms = "";
        if (this.canModifyAgeRanges) {
          this.search_inputs.ages = [];
        }
        this.search_inputs.states = [];
        this.search_inputs.substatuses = [];
        this.search_inputs.providers = [];
        this.search_inputs.practices = [];
        this.search_inputs.locations = [];
        this.search_inputs.cpt_codes = [];
        this.search_inputs.visit_types = [];
        this.search_inputs.procedure_types = [];
        this.search_inputs.payers = [];
        if (this.bucket_option == 'my_claims'){
            this.search_inputs.workers = [this.worker_id];
        }
        else{
            this.search_inputs.workers =  [];
        }
        if (this.initialClaimId){
            this.search_inputs.is_closed = null;
        }
        else{
            this.search_inputs.is_closed = false;
        }
        this.search_inputs.include_old_claims = false;
        this.search_inputs.min_balance = null;
        this.search_inputs.max_balance = null;
      },
      clearSearchParams: function(){
        this.search_params.search_terms =  "";
        if (this.canModifyAgeRanges){
            this.search_params.age_ranges =  "";
        }
        this.search_params.states =  "";
        this.search_params.substatuses =  "";
        this.search_params.provider_ids = "";
        this.search_params.practice_ids = "";
        this.search_params.location_ids =  "";
        this.search_params.payer_ids =  "";
        this.search_params.cpt_codes = "";
        this.search_params.visit_types = "";
        this.search_params.procedure_types = "";
        if (this.bucket_option == 'my_claims'){
          this.search_params.worker_ids =  this.worker_id;
        }
        else{
          this.search_params.worker_ids =  "";
        }
        if (this.initialClaimId){
            this.search_params.is_closed = null;
        }
        else {
            this.search_params.is_closed = false;
        }
        this.search_params.min_balance = null;
        this.search_params.max_balance = null;
        this.$store.commit("setSearchObject", this.search_inputs);
        this.$store.commit("setSearchParams", this.search_params);
      },
      getMyAgeRanges: function(){
        const url = make_url("/workers/age_ranges");
        const vm = this;
        const promise = authenticated_request({
            url: url,
            method: "GET"}
            ).then(function(response) {
                if (!response.data.age_ranges) {
                    vm.assigned_age_ranges = [];
                    console.log("No assigned age ranges");
                    return
                }
                vm.assigned_age_ranges = parseAgeRanges(response.data.age_ranges);
                console.log("My assigned age ranges: ", vm.assigned_age_ranges);
                vm.search_inputs.ages = vm.assigned_age_ranges;
                vm.commitSearch();
            }).catch(function(error) {
                console.log("Error getting age ranges: ", error);
                console.log(error);
            });
        return promise;
      },
      getFilterOptions: function(){
        const url = make_url("/reports/claims/filters");
        const vm = this;
        const promise = authenticated_request({
            method: "get",
            url: url,
        }).then(function(response){
            const items = response.data;
            vm.visit_types = items.visit_types;
            vm.procedure_types = items.procedure_types;
            //get all states besides closed for the claim filter
            for (var i = 0; i < items.states.length; i++){
                if (items.states[i].state != 8){
                    vm.states.push(items.states[i]);
                }
            }
            vm.practices = items.practices;
            vm.substatuses = items.substatuses;
            vm.locations = items.practice_locations;
            return items || [];
        });
        return promise;
      },
      onApplyFilters: function(){
        this.$refs.filterDropdown.hide();
        this.commitSearch();
      },
      commitSearch: function(){
          this.$store.commit("setSearchObject", this.search_inputs);
          this.$store.commit("setSearchParams", this.search_params);
          
          this.setSearchReady();
      },
      setSearchReady: function(){
            this.$store.commit("setSearchReady", true);
      },
      searchClaims: function() {
            if (this.search_inputs.terms.length > 2 || this.search_inputs.terms.length == 0) {
                this.commitSearch();
            }
      },
      locationLabel: function(location) {
          // find the item with the matching practice_id in practices
          const practice_item = this.practices.find(function(item) {
              return item.practice_id == location.practice_id;
          });
          if (practice_item) {
              return location.name + ' (' + practice_item.name + ')';
          } else {
              return location.name;
          }
      },
      setAgeRangeParams: function() {
          // If no age range is selected then it defaults to 0,730
          // By default, claims older than 730 days are excluded unless do_include_old is checked
          const age_ranges = [...this.search_inputs.ages].sort().join() || "0,730";
          const do_include_old = this.search_inputs.include_old_claims;
          
          if (do_include_old) {
            this.search_params.age_ranges = age_ranges + ",731,9999";
          } else {
            this.search_params.age_ranges = age_ranges;
          }
      }
  },
  computed: {
      worker_id: function() {
          return localStorage.getItem('worker_id');
      },
      selectableLocations: function(){
        // filter out only locations whose practice_id matches a selected practice
        const practice_ids = this.search_inputs.practices.map(function(item) {
            return item.practice_id;
        });
        return this.locations.filter(function(item) {
            return practice_ids.includes(item.practice_id);
        });
      },
      flagInputLabel: function(){
          if(this.search_inputs.substatuses.length == 0) {
               return "Filter Sub-statuses";
          } else {
              return this.search_inputs.substatuses.length + " Substatus(es)"
          }
      },
      practiceInputLabel: function(){
          if(this.search_inputs.practices.length == 0) {
               return "Filter Practices";
          } else {
              return this.search_inputs.practices.length + " Practice(s)"
          }
      },
      filterInputLabel: function(){
          const count = this.filterCount;
          if(count == 0) {
               return "More Filters";
          } else {
               return count + " Filter(s)";
          }
      },
      filterCount: function() {
          var count = 0;
          for(const filter in this.search_params){
              if ((filter == "min_balance" || filter == "max_balance") && this.search_params[filter] != null){
                  count += 1;
              }
              else if (filter == "age_ranges") {
                if (this.search_params[filter] !== "0,730") {
                    count += 1;
                }
              }
              else if (filter != "is_closed" && filter != "min_balance" && filter != "max_balance"){
                if(this.search_params[filter] && this.search_params[filter].length && filter !== "terms" && filter !== "substatuses"){
                    count += 1;
                }
              }
          }
          if (this.bucket_option == 'my_claims'){
              count -= 1;
          }
          return count;
      },
      canModifyAgeRanges: function(){
          if (!this.assigned_age_ranges) {
            return true
          } else if (this.assigned_age_ranges.length == 0) {
            return true
          } else {
            return false
          }
      },
      ageRangesMessage: function(){
          if (this.canModifyAgeRanges){
                return "Selecting a short time window returns faster results";
            }
          else{
                return "Your age ranges have been assigned and cannot be modified. To find all your assigned claims, go to the My Work tab.";
          }
      },
      refresh: function(){
        return this.$store.state.claims.refresh;
      },
  },
  created() {
      const storedSearchObject = this.$store.state.claims.searchObject;
      console.log("Restoring storedSearchObject", storedSearchObject);
      this.search_inputs = Object.assign({}, this.search_inputs, storedSearchObject);

      this.getFilterOptions();
      console.log("Calling getMyAgeRanges");
      this.getMyAgeRanges();

      if (this.initialClaimId){
          this.search_inputs.is_closed = null;
      } else {
          this.search_inputs.is_closed = false;
      }
      if (!this.hasPermission('assign_claims')){
          this.bucket_option = 'unassigned';
      }
      this.claimAutoSearch = debounce(this.searchClaims, 900);
  },
  mounted() {
      this.setSearchReady();
  },
  watch: {
      // TODO: These 'search_inputs.[parameter]' terms would be better handled as computed values
      'search_inputs.terms': function(newValues) {
          this.search_params.search_terms = newValues;
      },
      'search_inputs.substatuses': function(newValues) {
          this.search_params.substatuses = newValues.join();
      },
      'search_inputs.ages': function() {
        this.setAgeRangeParams();
      },
      'search_inputs.include_old_claims': function() {
          this.setAgeRangeParams();
      },
      'search_inputs.states': function(newValues) {
          this.search_params.states = newValues.map(obj => obj.state).join();
      },
      'search_inputs.workers': {
          immediate: true,
          handler(newValues) {
              this.search_params.worker_ids = newValues.map(obj => obj.worker_id).join();
          }
      },
      'search_inputs.payers': {
          immediate: true,
          handler(newValues) {
            this.search_params.payer_ids = newValues.map(obj => obj.payer_id).join();
          }
      },
      'search_inputs.practices': {
          immediate: true,
          handler(newValues) {
            this.search_params.practice_ids = newValues.map(obj => obj.practice_id).join();
          }
      },
      'search_inputs.locations': function(newValues) {
          this.search_params.location_ids = newValues.map(obj => obj.location_id).join();
      },
      'search_inputs.providers': {
          immediate: true,
          handler(newValues) {
              this.search_params.provider_ids = newValues.map(obj => obj.provider_id).join();
          }
      },
      'search_inputs.cpt_codes': {
          immediate: true,
          handler(newValues) {
              this.search_params.cpt_codes = newValues.map(obj => obj.code).join();
          }
      },
      'search_inputs.visit_types': function(newValues) {
          this.search_params.visit_types = newValues.map(obj => obj.value).join();
      },
      'search_inputs.procedure_types': function(newValues) {
          this.search_params.procedure_types = newValues.map(obj => obj.procedure_type_id).join();
      },
      'search_inputs.is_closed': function(newValues){
          this.search_params.is_closed = newValues;
      },
      'search_inputs.min_balance': function(newValues){
          this.search_params.min_balance = newValues || null;
      },
      'search_inputs.max_balance': function(newValues){
          this.search_params.max_balance = newValues || null;
      },
      bucket_option: function(newValue) {
        if (this.initialClaimId) {
            delete this.search_params.has_worker_assigned;
            delete this.search_params.has_issues_worker_assigned;
            this.commitSearch();
            return;
        }
        switch(newValue){
            case 'unassigned':
                this.search_params.worker_ids = "";
                if (!this.issues){
                  this.search_params["has_worker_assigned"] = false;
                }
                else{
                  this.search_params["has_issues_worker_assigned"] = false;
                }
                this.commitSearch();
                break;
            case 'my_claims':
                delete this.search_params.has_worker_assigned;
                delete this.search_params.has_issues_worker_assigned;
                this.search_params.worker_ids = this.worker_id;
                this.commitSearch();
                break;
            default:
                delete this.search_params.has_worker_assigned;
                delete this.search_params.has_issues_worker_assigned;
                this.search_params.worker_ids = "";
                this.commitSearch();
        }
      },
      refresh: function(){
        if (this.refresh){
          this.commitSearch();
          this.$store.commit("setRefresh", false);
        }
      }
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
/* Styling the search bar group */
::v-deep .search-group {
    position: relative;
    margin-right: 20px;
    border: 1px solid #C9D2DF;
    border-radius: 5px;
    max-width:900px;
}
::v-deep .claim-search-input, .input-group-text {
    height:50px;
    border: none;
    border-radius: 5px;
    background: white;
    color: #9CA3AD; 
}
::v-deep .claim-search-input::placeholder {
    color: #9CA3AD;
    font-size:12px;
    opacity: 1;
}
::v-deep .b-dropdown .btn-secondary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 5px;
    color: #9CA3AD;
    background: white;
    font-size:12px;
    height:50px;
    min-width:150px;
    text-align:left;
}
::v-deep .b-dropdown .btn-secondary:active,
::v-deep .btn-secondary:not(:disabled):not(.disabled):active, 
::v-deep .btn-secondary:not(:disabled):not(.disabled).active, 
::v-deep .show > .btn-secondary.dropdown-toggle {
    color:#519EFF !important;
    background-color: white;
}
::v-deep .b-dropdown .btn-secondary:focus {
    box-shadow:none !important;
}
.divider {
    display: inline-block;
    width: 0;
    height: 20px;
    margin: auto;
    border-right: 1.8px solid #D2D8E2;
}

/* dropdown menu-related styling */
::v-deep .filter-dropdown .b-dropdown-form {
    padding: 20px 30px 30px 30px;
}
::v-deep .filter-dropdown .dropdown-menu {
    width:100.3%;
    right: -1px !important;
    left: auto !important;
    top:45px !important;
    transform: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 16px 48px #1617182B !important;
}
::v-deep .flag-dropdown .dropdown-menu {
    width: 270px;
    padding: 10px;
    left: -1px !important;
    top:48px !important;
    transform: none !important;
    font-size: 14px;
    font-weight: 500;
    color: #64728C;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 16px 48px #1617182B !important;
}
::v-deep .flag-dropdown .b-dropdown-form {
    padding: 0.2rem 0.8rem;
}

/* Styling for claim balance group */
.claim-balance-group {
    font-size: 14px;
    color: var(--gray);
}

/* Styling for claim age button group */
.checkbox-btn-group {
    width: 100%;
    min-height:40px;
}
::v-deep .checkbox-btn-group .btn-outline-primary {
    border: 1px solid #C9D2DF;
    padding-top: 9px;
    font-size: 14px;
    color: var(--gray);
}
::v-deep .checkbox-btn-group .btn-outline-primary:hover {
    background-color: #F2F6FC;
    color: var(--gray-800);
}
::v-deep .checkbox-btn-group .btn-outline-primary:active,
::v-deep .checkbox-btn-group .btn-outline-primary.active {
    border: 1px solid var(--blue) !important;
    background-color: #F2F6FC !important;
    color: var(--dark);
}
::v-deep .checkbox-btn-group .btn-outline-primary:focus,
::v-deep .checkbox-btn-group .btn-outline-primary.focus {
    box-shadow: none !important;
}

.balance-text {
    font-size: 20px;
    font-weight: 300;
}

.tabs {
    background: #E7EAEE;
    border-radius:5px;
    color: #C9D2DF;
    font-size: 14px;
    border: 1px solid;
}
::v-deep .nav-pills .nav-link, .nav-pills .show > .nav-link{
    background: #E7EAEE;
    color: var(--dark);
}

::v-deep .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
    background: white;
    color: var(--dark);
}
</style>