<template>
<b-container class="p-0 pt-3">
    <b-alert
        v-model="showAlert"
        variant="danger"
        class="mt-2"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <b-row>
        <b-col cols="12">
            <div class="px-5">
                <b-input-group class="search-group">
                    <b-input-group-prepend>
                        <span class="input-group-text"><b-icon icon="search"></b-icon></span>
                    </b-input-group-prepend>
                    <b-form-input
                        @keyup="debounceEvents"
                        @search="debounceEvents"
                        class="task-search-input pl-1"
                        type="search" v-model="activitySearch"
                        placeholder="Search an event"/>
                <b-input-group-append>
                    <span class="divider"></span>
                    <span class="divider"></span>
                    <b-dropdown ref="dropdown" class="dropdown filter-dropdown" :boundary="'window'">
                        <template #button-content >
                        <b-icon-funnel class="mr-2" />{{filterInputLabel}}
                        </template>
                        <b-dropdown-form class="d-flex flex-column align-items-start">
                        <span style="font-size:20px;font-weight:300">Filters</span>
                        <b-alert
                            v-model="showAlertFilter"
                            variant="danger"
                            dismissible>
                            <b>{{errorTextFilter}}</b>
                        </b-alert>
                        <div class ="selecter-label">Date Range</div>
                        <b-form-input type="date" 
                        v-model="filters.date_start" 
                        class="mb-4"
                        today-button
                        reset-button
                        close-button></b-form-input>

                        <b-form-input type="date" 
                        v-model="filters.date_end" 
                        class="mb-4"
                        today-button
                        reset-button
                        close-button></b-form-input>

                        <div class="selecter-label">Event type</div>
                        <multiselect
                            :show-labels="false"
                            v-model="filters.types"
                            :hide-selected="true"
                            track-by="name"
                            label="name"
                            :options="event_types"
                            :multiple="true"
                            :internalSearch="true"
                            placeholder="-- Select an Option --">
                        </multiselect>

                        <div class="selecter-label">Workers</div>
                        <multiselect 
                            :show-labels="false"
                            v-model="filters.workers"
                            :hide-selected="true"
                            :options="selectableWorkers"
                            track-by="worker_id" 
                            label="name"
                            :multiple="true"
                            :internalSearch="false"
                            placeholder="-- Search for workers --">
                        </multiselect>

                        <div class="d-flex justify-content-end mt-4">
                            <b-button @click="clearFilters()" variant="outline-primary" class="avo-basic-btn mr-3">Clear Filters</b-button>
                            <b-button @click="applyFilters" variant="outline-primary" class="avo-primary-btn">Apply</b-button>
                        </div>
                        </b-dropdown-form>
                    </b-dropdown> 
                </b-input-group-append>
                </b-input-group>
            </div>
            <b-overlay :show="eventsLoading" variant="white" style="min-height:200px;">
            <div class="event-container">
                <div v-if="events">
                    <div v-for="(event, index) in events" :key="index">
                        <b-card class="event-card">
                        <div class="d-flex flex-row justify-content-between align-items-center">
                            <div class="p-2">
                                <div class="d-flex flex-row align-items-center">
                                    <!--TODO: ICON-->
                                    <div class="mr-4">
                                        <b-iconstack>
                                        <b-icon icon="circle-fill" style="color:#F3F8FD" scale="2"></b-icon>
                                        <b-icon v-if="getEventIconType(event) == 'Document'" icon="paperclip"></b-icon>
                                        <b-icon v-else-if="getEventIconType(event) == 'Note'" icon="file-earmark"></b-icon>
                                        <b-icon v-else-if="getEventIconType(event) == 'Task'" icon="calendar-4"></b-icon>
                                        <b-icon v-else-if="getEventIconType(event) == 'Worklog'" icon="list-ul"></b-icon>
                                        <b-icon v-else-if="getEventIconType(event) == 'Worker'" icon="person"></b-icon>
                                        <b-icon v-else-if="getEventIconType(event) == 'Claim'" icon="file-earmark-medical"></b-icon>
                                        <b-icon v-else-if="getEventIconType(event) == 'Code'" icon="card-list"></b-icon>
                                        <b-icon v-else-if="getEventIconType(event) == 'Payment'" icon="credit-card"></b-icon>
                                        <b-icon v-else-if="getEventIconType(event) == 'Flagged'" icon="exclamation-triangle"></b-icon>
                                        <b-icon v-else-if="getEventIconType(event) == 'Transition'" icon="arrow-left-right"></b-icon>
                                        <b-icon v-else icon="list-ul"></b-icon>
                                        </b-iconstack>
                                    </div>
                                    <div>
                                        <div class="label-text" style="font-size:16px;font-weight:400">
                                            {{event.label}}<span v-if="event.payload.title">:
                                            <span class="label-text" style="font-size:16px">{{event.payload.title}}</span>
                                            </span>
                                        </div>
                                        <div class="avo-text-light">
                                            {{formatDate(event.occurred_at)}} at {{formatTime(event.occurred_at)}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex">
                                <b-avatar class="mr-3" :text="event.initials"></b-avatar>
                                <div class="d-flex flex-column align-items-end flex-wrap">
                                    <div class="text-nowrap">by {{event.name}}</div>
                                    <div class="avo-text-light">{{event.user_role ? event.user_role : 'Worker'}}</div>
                                </div>
                                
                            </div>
                        </div>
                        </b-card>
                    </div>
                    <div md="12" v-if="events.length > 0">
                        <b-pagination :total-rows="rows" :per-page="perPageVar" v-model="currentPage" align="center" />
                    </div>
                </div>
                <div v-if="events.length == 0 && eventsLoading" class="no-events pt-4">
                    Fetching events
                </div>
                <div v-if="events.length == 0 && !eventsLoading" class="no-events pt-4">
                    No events found
                </div>
            </div>
            </b-overlay>
        </b-col>
    </b-row>
</b-container>
</template>


<script>
import { alertsMixin, make_url, authenticated_request, debounce, 
         formatFullDate as formatDate, formatTime } from '../../common.js'
import Multiselect from 'vue-multiselect'

export default {
  name: 'ActivityManager',
  mixins: [alertsMixin],
  components: {
      Multiselect
  },
  props: ['claimId', 'preauthId', 'perPage'],
  data(){
      return {
          events: [],
          perPageVar: this.perPage,
          currentPage: 1,
          rows: "",
          sortBy: "occurred_at",
          activitySearch: "",
          sortDesc: true,
          selectableWorkers: [],
          event_types: [],
          filters: {
            date_start: "",
            date_end: "",
            workers: [],
            types: [],
          },
         eventsLoading: true,

         showAlertFilter: false,
         errorTextFilter: ""
      }
  },
  methods: {
    getEvents: function(){
        var params = {
            per_page: this.perPageVar,
            page: this.currentPage,
        }
        if (this.sortBy){
            params.sort = this.sortBy + ":" + (this.sortDesc?"desc":"asc")
        }
        if (this.activitySearch){
            params.search = this.activitySearch;
        }
        if (this.filters.date_start){
            params.start_date = this.filters.date_start;
        }
        if (this.filters.date_end){
            params.end_date = this.filters.date_end;
        }
        if (this.filters.workers){
            params.workers = [];
            for (var i = 0; i < this.filters.workers.length; i++){
                params.workers.push(this.filters.workers[i]['worker_id']);
            }
        }
        if (this.filters.types){
            params.event_types = [];
            for (var j = 0; j < this.filters.types.length; j++){
                params.event_types.push(this.filters.types[j]['event']);
            }
        }
        const url = (this.claimId) ? make_url("/claims/" + this.claimId + "/activity").toString() : make_url("/preauths/" + this.preauthId + "/activity");
        const self = this;
        const promise = authenticated_request({
            method: "get",
            url: url,
            params: params
        }).then(function(response){
            const events = response.data.events;
            self.events = events;
            self.rows = response.data.pagination.total;
            
            self.setShowAlert(false);
            
            return events || [];
        }).catch(function(errors){
            self.setShowAlert(true, errors);
        }).finally(()=>{
            self.eventsLoading =  false;
        });
        
        return promise;
    },
    getFilterOptions() {
        const url_prefix = (this.claimId) ? `/claims/${this.claimId}` : `/preauths/${this.preauthId}`;
        var url = make_url(`${url_prefix}/activity/filters`).toString();
        var self = this;
        authenticated_request({
            method: "get",
            url: url
        }).then(function(response){
            const event_types = response.data.types;
            for (const [key, value] of Object.entries(event_types)) {
                if (key != "root.log.added"){
                    self.event_types.push({
                        'event': key,
                        'name': value
                    })
                }
            }
            self.selectableWorkers = response.data.workers;
            self.setShowAlert(false);
        }).catch(function(errors){
            self.setShowAlert(true, errors);
        });

    },
    clearFilters: function(){
        this.showAlertFilter = false;
        this.filters = {
            date_start: "",
            date_end: "",
            workers: [],
            types: [],
        };
        this.getEvents();
    },
    applyFilters(){
        this.showAlertFilter = false;
        this.getEvents();
        this.$refs.dropdown.hide();
    },
    getEventIconType(event){
        var eventArr = event.event.split('.');
        if(eventArr[1] == "notes"){
            return "Note"
        } else if(eventArr[1] == "documents"){
            return "Document"
        } else if(eventArr[1] == "tasks"){
            return "Task"
        } else if(eventArr[1] == "worklog"){
            return "Worklog"
        } else if(eventArr[1] == "workers"){
            return "Worker"
        } else if(eventArr[1] == "claims"){
            if(eventArr[2] == "codes"){
                return "Code"
            } else if(eventArr[2] == "payment"){
                return "Payment"
            } else if(eventArr[2] == "flagged"){
                return "Flagged"
            } else if(eventArr[2] == "transitioned"){
                return "Transition"
            } return "Claim"
        }
    },
    formatDate,
    formatTime,
  },
  computed: {
    refreshActivityLog() {
        // TODO: ActivityManager is used in both claims and preauths and in the future elsewhere, 
        // it should not have a direct dependency on the claims store, claims is only one place
        // it is used
        return this.$store.state.claims.reloadActivityLogs;
    },
    filterInputLabel: function(){
        const count = this.filterCount;
        if(count == 0) {
            return "Add Filters";
        } else {
            return count + " Filter(s)";
        }
    },
    filterCount: function() {
        var count = 0;
        for(const filter in this.filters){
            if(this.filters[filter].length || ((filter === 'start_date' || filter === 'end_date') && this.filters[filter])){
                count += 1;
            }
        }
        return count;
    }
  },
  watch: {
      currentPage(){
          return this.getEvents();
      },
      refreshActivityLog(newState){
        // console.log("activity manager refresh (from watch): " + newState)
        if(newState){
            this.getEvents()
            this.$store.commit("activityLogRefreshed");
        } 
      },
  },
  // activated() is only fired when a kept-alive component is navigated to;
  // complemented by the above watch: which updates activity if sibling on same page creates a log.
  activated(){
    // console.log("activity manager refresh (from activated): "+this.refreshActivityLog);
    if(this.refreshActivityLog){
      this.getEvents();
      this.$store.commit("activityLogRefreshed");
    }
  },
  async mounted() {
      this.getFilterOptions();
      this.getEvents();
  },
  created(){
    this.debounceEvents = debounce(this.getEvents, 900);
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.event-container {
    padding: 25px 20% 25px 5%;
}
.no-events {
  font-size: 16px;
  font-weight: 200;
  text-align: center;
}
.event-card{
  border-radius: 15px;
  margin-bottom: 10px;
}
.event-card ::v-deep .card-body {
    padding: 1rem;
}
.event-body{
  padding-top: 15px;
  padding-left: 45px;
  padding-right: 90px;
}

/* Styling the search bar group */
::v-deep .search-group {
    position: relative;
    margin-right: 20px;
    border: 1px solid #C9D2DF;
    border-radius: 5px;
    max-width:500px;
}
::v-deep .task-search-input, .input-group-text {
    height:50px;
    border: none;
    border-radius: 5px;
    background: white;
    color: #9CA3AD; 
}
::v-deep .task-search-input::placeholder {
    color: #9CA3AD;
    font-size:12px;
    opacity: 1;
}
::v-deep .b-dropdown .btn-secondary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 5px;
    color: #9CA3AD;
    background: white;
    font-size:12px;
    height:50px;
    padding-right: 35px;
    min-width:150px;
    text-align:left;
}
::v-deep .b-dropdown .btn-secondary:active,
::v-deep .btn-secondary:not(:disabled):not(.disabled):active, 
::v-deep .btn-secondary:not(:disabled):not(.disabled).active, 
::v-deep .show > .btn-secondary.dropdown-toggle {
    color:#519EFF !important;
    background-color: white !important;
}
::v-deep .b-dropdown .btn-secondary:focus {
    box-shadow:none !important;
}
.divider {
    display: inline-block;
    width: 0;
    height: 20px;
    margin: auto;
    border-right: 1.8px solid #D2D8E2;
}

/* dropdown menu-related styling */
::v-deep .filter-dropdown .b-dropdown-form {
    padding: 20px 30px 30px 30px;
}
::v-deep .filter-dropdown .dropdown-menu {
    width:100.4%;
    right: -1px !important;
    left: auto !important;
    top:45px !important;
    transform: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 16px 48px #1617182B !important;
}
.worker-select ::v-deep .multiselect__select {
  padding-top:16px !important;
}
.worker-select ::v-deep .multiselect--active .multiselect__select {
  padding-top:12px !important;
}
</style>