<template>
<b-modal id="edit-code-rates" @hide="resetModal" title="Edit practice fee rates" hide-footer size="xl">
<b-form @submit="onSubmit">
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>

    <b-row align-h="center">
        <b-col cols=9>
            <b-card bg-variant="light" text-variant="dark">
                <b-row>
                    <b-col>
                        <b-card-text><b>Code:</b> {{ code.cpt_code || 'N/A'}}</b-card-text>
                        <b-card-text><b>Description:</b> {{ code.short || 'N/A' }}</b-card-text>
                    </b-col>
                    <b-col>
                        <b-card-text><b>Modifiers:</b> {{ code.modifiers || 'N/A'}}</b-card-text>
                        <b-card-text><b>POS/TOS:</b> {{ code.pos || 'N/A'}}, {{ code.tos || 'N/A'}}</b-card-text>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
    
    <!-- Step one here -->
    <div class="mb-4">

    <b-card class="mt-2 mb-2">
    <b-form-group
        id="rates-master"
        label="Practice fee rates"
        description="To edit payer-specific rates, view/edit payer rates and select a payer"
        label-size="lg"
        label-cols-lg="3">
        
        <b-form-row>
        <b-col>
            <b-form-group
            id="charge-fee"
            label="Charge fee:">
            <b-input-group prepend="$">
                <b-form-input v-model="form.charge_fee" type="number" required></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="medicare-fee"
            label="Medicare fee:">
            <b-input-group prepend="$">
                <b-form-input v-model="form.medicare_fee" type="number" required></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="cost"
            label="Cost:">
            <b-input-group prepend="$">
                <b-form-input v-model="form.cost" type="number" required></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="self-pay-fee"
            label="Self pay fee:">
            <b-input-group prepend="$">
                <b-form-input v-model="form.self_pay_fee" type="number" required></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        </b-form-row>
    </b-form-group>
    <b-form-group
        id="rates-surgery-center"
        label="Rates for Surgery Centers"
        label-size="lg"
        label-cols-lg="3"
        v-show="show_surgery_center_fields">
        <b-form-row >
        <b-col >
            <b-form-group
            id="medicare-fee-surgery"
            label="Medicare fee (surgery center):">
                <template #label>
                Medicare fee: <span class="badge badge-primary">Surgery Center</span>
                </template>
            <b-input-group prepend="$">
                <b-form-input v-model="form.medicare_fee_surgery_center" type="number" :required="show_surgery_center_fields"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="cost-surgery"
            label="Cost (surgery center):">
                <template #label>
                Cost: <span class="badge badge-primary">Surgery Center</span>
                </template>
            <b-input-group prepend="$">
                <b-form-input v-model="form.cost_surgery_center" type="number" :required="show_surgery_center_fields"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            id="self-pay-fee-surgery"
            label="Self pay fee (surgery center):">
                <template #label>
                Self pay fee: <span class="badge badge-primary">Surgery Center</span>
                </template>
            <b-input-group prepend="$">
                <b-form-input v-model="form.self_pay_fee_surgery_center" type="number" :required="show_surgery_center_fields"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
        </b-form-row>
    </b-form-group>
    <b-row>
        <b-col style="text-align:right mb-2">
            <b-form-checkbox type="checkbox" size="md" id="show_surgery_checkbox" v-model="show_surgery_center_fields" switch> Edit <span :class="show_surgery_center_fields ? 'badge badge-primary' : 'badge badge-secondary'">Surgery Center</span> fields </b-form-checkbox>
        </b-col>
    </b-row>
    </b-card>

    <b-card class="mt-2 mb-2">
    <b-form-group
        id="underpayment-group"
        label="Claim auto-flagging"
        label-size="lg"
        label-cols-lg="3">
    <b-form-row>
    <b-col cols="4">
        <b-form-group
            id="underpayment-pct"
            label="Underpayment percentage:"
            style="text-align:left">
        <b-input-group append="%">
            <b-form-input v-model="form.underpayment" type="number"></b-form-input>
        </b-input-group>
        </b-form-group>
    </b-col>
    </b-form-row>
    </b-form-group>
    </b-card>

    <b-row>
        <b-col style="text-align:right">
            <b-button @click="onSubmit" class="avo-primary-btn mt-2">
                Save new rates <b-icon icon="arrow-down-right-square"/> 
            </b-button>
        </b-col>
    </b-row>
    </div>

</b-form>
</b-modal>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from '../../common.js'

export default {
  name: 'PracticeFeeEditRates',
  components:{ },
  mixins: [ alertsMixin ],
  props: ['year', 'practiceId', 'codeInfo'],
  data: function(){
      return {
            code: {
                practice_id: this.practiceId,
                year: this.year,
                short: this.codeInfo.short,
                cpt_code: this.codeInfo.cpt_code,
                modifiers: this.codeInfo.modifiers,
                pos: this.codeInfo.pos,
                tos: this.codeInfo.tos,
            },
            form: {
                underpayment_percent: this.codeInfo.underpayment_percent,
                charge_fee: this.codeInfo.charge_fee,
                medicare_fee: this.codeInfo.medicare_fee,
                cost: this.codeInfo.cost,
                self_pay_fee: this.codeInfo.self_pay_fee,
                medicare_fee_surgery_center: this.codeInfo.medicare_fee_surgery_center,
                cost_surgery_center: this.codeInfo.cost_surgery_center,
                self_pay_fee_surgery_center: this.codeInfo.self_pay_fee_surgery_center,
            },

            show_surgery_center_fields: false,

            practice_id: this.practiceId,
    }
  },
  methods: {
    clearData(){
        this.form = { 
                underpayment_percent: null,
                charge_fee: null,
                medicare_fee: null,
                cost: null,
                self_pay_fee: null,
                medicare_fee_surgery_center: null,
                cost_surgery_center: null,
                self_pay_fee_surgery_center: null, };
        
        this.hideError();
    },
    validateInputs(){
        // TODO: Need to add a bunch of input validation here:
        //  check that the modifiers are modifiers, the POS, TOS are correct etc
        this.hideError();
        
        return true
    },
    onSubmit(){
        if (this.validateInputs()){
            const vm = this;
            const data = {  cpt_code: this.code.cpt_code,
                            modifiers: this.code.modifiers,
                            pos: this.code.pos,
                            tos: this.code.tos,
                            charge_fee: this.form.charge_fee || null,
                            medicare_fee: this.form.medicare_fee || null,
                            self_pay_fee: this.form.self_pay_fee || null, 
                            cost: this.form.cost || null,
                            medicare_fee_surgery_center: this.form.self_pay_fee_surgery_center || null,
                            self_pay_fee_surgery_center: this.form.self_pay_fee_surgery_center || null,
                            cost_surgery_center: this.form.cost_surgery_center || null,
                            year: this.code.year,
                         }   
            
                console.log(data);
                authenticated_request({
                    method: 'PUT',
                    url: make_url("/practices/" + this.practice_id + "/codes"),
                    data: data,
                }).then(()=>{
                    vm.clearData();
                    vm.hideModal();
                }).finally(()=>{
                    vm.$emit('submitted');
                });
        } else {
            this.showError("Invalid data received");
        }
    },
    setPayerInfo(bool){
        // if (this.do_show_payer_info_tab != bool){
        //     this.clearData();
        // }
        this.do_show_payer_info_tab = bool;
    },
    resetModal(){
        this.hideError();
        this.clearData();
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
    hideModal(){
        this.$bvModal.hide('edit-code-rates');
    },
    codeWithDescription(code){
        if (code.code && code.short){
            return (code.code + ": " + code.short)
        }
        return "Code/description missing"
    },
  },
}
</script>

<style scoped>
</style>