<template>
<div>
    <b-card class="notification-list-card">
        <div class="d-flex flex-row justify-content-between mb-2">
            <div class="summary-card-header-txt pl-2">
                Notifications
            </div>
        </div>
        <b-overlay :show="notificationsLoading" variant="white" style="min-height:100px;">
        <div
            v-for="notification in notifications"
            :key="notification.notification_id"
            class="notification-banner mb-2"
            dismissible>

            <div class="d-flex justify-content-between align-items-start mb-2">
                <div>{{notification.description}}</div>
                <b-icon @click="clearNotification(notification.notification_id)" icon="x" style="color:var(--dark);" class="cursor-pointer ml-1" scale="1.45"></b-icon>
            </div>
            <div class="d-flex justify-content-between">
                <div style="color:#E7A33A;cursor:pointer;">
                    <span v-if="'task_id' in notification.payload" @click="setActiveTab('WorkClaimTasks')">View Tasks</span>
                    <span v-else-if="'document_id' in notification.payload" @click="setActiveTab('WorkClaimDocuments')">View Documents</span>
                    <span v-else-if="'note_id' in notification.payload" @click="setActiveTab('WorkClaimNotes')">View Notes</span>
                    <span v-else-if="'claim_flag' in notification.payload">Go to Issues Dashboard</span>
                    <span v-else @click="setActiveTab('WorkClaimForm')">View Claim Details</span>
                    <b-icon icon="chevron-right" />
                </div>
                <div v-if="notification.occurred_at" class="avo-text-light-small">{{formatDatetime(notification.occurred_at)}}</div>
            </div>
        </div>
        <div v-if="notifications.length == 0 && !notificationsLoading" class="no-notifications pt-4">
            You have no notifications for this claim.
        </div>
        </b-overlay>
    </b-card>
</div>
</template>

<script>
import { make_url, authenticated_request } from '../common.js'

export default {
  name: 'CurrentUser',
  components: {},
  props: ['claimId', 'preauthId'],
  data() {
      return {
          notifications: [],
          hasUnreadNotifs: false,
          showNotifications: false,
          currentPage: 1,
          perPage: 7,
          notificationsLoading: true
      }
  },
  methods: {
      getNotifications(){
        var url = make_url("/notifications/users/" + this.user_id);
        if(this.user_worker_id){
            url = make_url("/notifications/workers/" + this.user_worker_id);
        } 
        var vm = this;
        authenticated_request({
            method: "post",
            url: url,
            data: {
                page: vm.currentPage,
                per_page: vm.perPage,
                claim_id: vm.claimId
            }
        }).then(function(response){
            vm.notifications = response.data.notifications;
        }).catch(function(error){
            console.log(error.response)
        }).finally(()=>{
            vm.notificationsLoading = false;
        });
      },
      formatDatetime(date) {
        const dateObj = new Date(date);
        return dateObj.toLocaleString();
      },
      clearNotification(notification_id){
        var vm = this;
        authenticated_request({
            method: "delete",
            url: make_url("/notifications/clear"),
            data: {
                notification_id: notification_id
            }
        }).then(function(){
            vm.getNotifications();
        }).catch(function(error){
            console.log(error.response)
        })
      },
      // For notification navidation
      setActiveTab: function(component){
        this.$store.commit("setWorkedClaimActiveTab", component);
      },
  },
  computed: {
      user_id: function() {
        return localStorage.getItem("user_id");
      },
      user_worker_id: function() {
        return localStorage.getItem("worker_id");
      },
  },
  created() {
      this.getNotifications();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.notification-banner {
    background: #FDF9E7 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 5px;
    color: #011828;
    font-size: 13px;
    font-weight: 400;
    padding: 10px 10px 10px 15px;
    text-align: left;
}
.notification-list-card ::v-deep .card-body {
    padding: 1.25rem 10px;
    padding-bottom: 10px;
}
.no-notifications {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 200;
}
</style>