<template>
<div>
    <b-row>
        <b-col>
            <b-alert
                v-model="showAlert"
                variant="danger"
                dismissible>
                <b>{{alertMessage}}</b>
            </b-alert>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <div class="avo-header-text-med mb-3">Payments From Patient</div>
            <div class="billing-card">
                <b-row class="px-3">
                    <b-col cols="6" class="p-3">
                        <b-row class="mx-0 mb-2">
                            <b-col cols="6">
                                <span class="avo-text-light">SUMMARY</span>
                            </b-col>
                            <b-col cols="2" class="text-right">
                                <span class="avo-text-light">BILLED</span>
                            </b-col>
                            <b-col cols="2" class="text-right">
                                <span class="avo-text-light">PAID</span>
                            </b-col>
                            <b-col cols="2" class="text-right">
                                <span class="avo-text-light">BALANCE</span>
                            </b-col>
                        </b-row>
                        <b-row  class="mx-0 mb-2 pb-1" style="border-bottom:1px solid #E2E4EB;">
                            <b-col cols="6">
                                Responsible For:
                            </b-col>
                            <b-col cols="2" class="text-right">
                                ${{groupedCodesAndTotals.totalCharged}}
                            </b-col>
                            <b-col cols="2" class="text-right">
                                ${{groupedCodesAndTotals.totalPaid.toFixed(2)}}
                            </b-col>
                            <b-col cols="2" class="text-right">
                                ${{groupedCodesAndTotals.totalBalance}}
                            </b-col>
                        </b-row>
                        <b-row v-for="(code, index) in groupedCodesAndTotals.patientResponsibilityCodes" :key="index" class="mx-0 mb-1">
                            <b-col cols="6">
                                <span class="data-text">{{code.code}}: </span><span class="avo-text-light-small">{{code.short}}</span>
                            </b-col>
                            <b-col cols="2" class="text-right">
                                <span class="data-text">${{calculateChargedAmount(code)}}</span>
                            </b-col>
                            <b-col cols="2" class="text-right">
                                <span class="data-text">${{Number(code.total_patient_payments || 0).toFixed(2)}}</span>
                            </b-col>
                            <b-col cols="2" class="text-right">
                                <span class="data-text">${{calculateCodeBalance(code)}}</span>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="6" class="p-4" style="background-color:#F3F8FD;max-height:100%">
                        <b-row class="mb-5">
                            <b-col cols="4">
                                <div class="avo-text-light">Patient</div>
                                <div class="data-text">{{claim.patient_name}}</div>
                            </b-col>
                            <b-col cols="4">
                                <div class="avo-text-light">Account No.</div>
                                <div class="data-text">#{{claim.patient_id}}</div>
                            </b-col>
                            <b-col cols="4">
                                <div class="avo-text-light">DOB</div>
                                 <div class="data-text">{{formatDateShort(claim.patient.date_of_birth)}}</div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                <div class="avo-text-light">Phone</div>
                                <div class="data-text"><b-icon icon="telephone" /> {{claim.patient ? (claim.patient.telephone || "-") : "-"}}</div>
                            </b-col>
                            <b-col cols="4">
                                <div class="avo-text-light">Email</div>
                                <a v-if="claim.patient" :href="'mailto:'+claim.patient.email" class="data-text"><b-icon icon="envelope" /> {{claim.patient.email || "-"}}</a>
                            </b-col>
                            <b-col cols="4">
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <div class="d-flex justify-content-between align-items-center my-4">
                <div class="d-flex flex-column">
                    <div class="avo-header-text-med">Payments From Patient</div>
                </div>
            </div>
        </b-col>
    </b-row>
    <itemized-payments :source="'patient'"></itemized-payments>
</div>
</template>

<script>
import { make_url, authenticated_request, formatDateShort, alertsMixin } from '../../common.js'

import ItemizedPayments from '../payments/ItemizedPayments.vue'

export default {
    name: 'PaymentsPatient',
    mixins: [alertsMixin],
    components: {
        ItemizedPayments,
    },
    data(){
        return {
            cptCodeReportData: {},
            addPaymentData: null,
            eras: [],
            
            codesLoading: true,
        }
    },
    methods: {
        getCodes: function(){
            console.log("getting codes from patient tab!!!")
            const url = make_url("/reports/claims/" + this.claim.claim_id + "/cpt_codes");
            const self = this;
            const promise = authenticated_request({
                method: "get",
                url: url
            }).then(function(response){
                self.cptCodeReportData = response.data;
            }).catch(function(error){
                console.log(error.response)
            }).finally(()=>{
                self.codesLoading = false;
            });
            return promise;
        },
        showAddPaymentModal(){
            this.addPaymentData = {
                'payerResponsibility': JSON.parse(JSON.stringify(this.groupedCodesAndTotals.payerResponsibilityCodes)),
                'patientResponsibility': JSON.parse(JSON.stringify(this.groupedCodesAndTotals.patientResponsibilityCodes)),
            }
            this.$bvModal.show('new-claim-payment');
        },

        calculateChargedAmount(code){
            return Number(code.patient_responsibility || 0).toFixed(2)
        },
        calculateCodeBalance(code){
            return Number((code.patient_responsibility || 0) - (parseFloat(code.total_patient_payments) || 0)).toFixed(2)
        },

        formatDateShort(datetime) {
            return formatDateShort(datetime)
        },

        //error methods
        showError(alertMessage){
            this.setShowAlert(true, alertMessage);
        },
        hideError(){
            this.setShowAlert(false);
        },

    },
    computed: {
        claim() {
            return this.$store.state.claims.workedClaim;
        },
        groupedCodesAndTotals(){
            var returnObj = {
                patientResponsibilityCodes: [],
                totalCharged: 0,
                totalPaid: 0,
                totalBalance: 0,
            }
            if(!this.cptCodeReportData.codes){
                return returnObj 
            }
            this.cptCodeReportData.codes.forEach(function(code){
                if(code.responsibility_type == 1){
                    returnObj.patientResponsibilityCodes.push(code);
                    returnObj.totalCharged += Number(code.patient_responsibility)
                    returnObj.totalPaid += Number(code.total_patient_payments || 0)
                } else if (code.responsibility_type == 3){
                    returnObj.patientResponsibilityCodes.push(code);
                    returnObj.totalCharged += Number(code.patient_responsibility)
                    returnObj.totalPaid += Number(code.total_patient_payments || 0)
                }
            });
            returnObj.totalBalance = (returnObj.totalCharged - returnObj.totalPaid).toFixed(2)
            return returnObj
        },
    },
    mounted: function(){
        this.getCodes();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.billing-card {
    /* padding: 20px; */
    border-radius: 5px;
    background: white;
    border: 1px solid #E2E4EB;
}

</style>