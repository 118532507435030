<template>
    <b-container class="p-0">
        <b-overlay :show="statsLoading" variant="white">
        <div class="d-flex justify-content-between mt-4 mb-5" style="padding-right:25%">
            <div class="stat-col">
                <div class="claim-stat-box">
                    <b-iconstack class="mr-4 ml-1" style="color:#3591EB;">
                        <b-icon icon="circle-fill" style="color:#F3F8FD" scale="2.2"></b-icon>
                        <b-icon icon="circle" scale="2.2"></b-icon>
                        <b-icon icon="hourglass" scale="1"></b-icon>
                    </b-iconstack>
                    <div class="d-flex flex-column justify-content-center">
                        <div class="avo-text-light neg-margin">Claim Age</div>
                        <div class="stat-text">{{claim.age}} <span style="font-size:20px;color:#929BAA;font-weight:400;">days</span></div>
                    </div>
                </div>
            </div>
            <div class="stat-col">
                <div class="claim-stat-box">
                    <b-iconstack class="mr-4 ml-1" style="color:#3591EB;">
                        <b-icon icon="circle-fill" style="color:#F3F8FD" scale="2.2"></b-icon>
                        <b-icon icon="circle" scale="2.2"></b-icon>
                        <b-icon icon="check2-square" scale="1"></b-icon>
                    </b-iconstack>
                    <div class="d-flex flex-column justify-content-center">
                        <div class="avo-text-light neg-margin">Upcoming Task</div>
                        <div class="stat-text">
                            <span v-if="claim_stats.days_until_upcoming_task > 1">
                                {{claim_stats.days_until_upcoming_task}} 
                            </span>
                            <span v-else-if="claim_stats.days_until_upcoming_task == 1">
                                Tomorrow
                            </span>
                            <span v-else-if="claim_stats.days_until_upcoming_task == 0">
                                Today
                            </span>
                            <span v-else>
                                N/A
                            </span>
                            <span style="font-size:20px;color:#929BAA;font-weight:400;">
                                <span v-if="claim_stats.days_until_upcoming_task > 1">
                                    days
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="stat-col">
                <div class="claim-stat-box">
                    <b-iconstack class="mr-4 ml-1" style="color:#3591EB;">
                        <b-icon icon="circle-fill" style="color:#F3F8FD" scale="2.2"></b-icon>
                        <b-icon icon="circle" scale="2.2"></b-icon>
                        <b-icon icon="list-ul" scale="1"></b-icon>
                    </b-iconstack>
                    <div class="d-flex flex-column justify-content-center">
                        <div class="avo-text-light neg-margin">Last Action</div>
                        <div class="stat-text">
                            <span v-if="claim_stats.days_since_last_action > 1">
                                {{claim_stats.days_since_last_action}} 
                            </span>
                            <span v-else-if="claim_stats.days_since_last_action == 1">
                                Yesterday
                            </span>
                            <span v-else-if="claim_stats.days_since_last_action == 0">
                                Today
                            </span>
                            <span v-else>
                                N/A
                            </span>
                            <span style="font-size:20px;color:#929BAA;font-weight:400;">
                                <span v-if="claim_stats.days_since_last_action > 1">
                                    days ago
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="claim.flag" class="stat-col">
                <div class="claim-stat-box">
                    <b-iconstack class="mr-4 ml-1" style="color:#3591EB;">
                        <b-icon icon="circle-fill" style="color:#F3F8FD" scale="2.2"></b-icon>
                        <b-icon icon="circle" scale="2.2"></b-icon>
                        <b-icon icon="exclamation-triangle" scale="1"></b-icon>
                    </b-iconstack>
                    <div class="d-flex flex-column justify-content-center">
                        <div class="avo-text-light neg-margin">Current Issue</div>
                        <div class="stat-text"><span style="font-size:22px">{{claim.flag}}</span></div>
                    </div>
                </div>
            </div>
            <div class="stat-col">
                <div class="claim-stat-box">
                    <b-iconstack class="mr-4 ml-1" style="color:#3591EB;">
                        <b-icon icon="circle-fill" style="color:#F3F8FD" scale="2.2"></b-icon>
                        <b-icon icon="circle" scale="2.2"></b-icon>
                        <b-icon icon="receipt" scale="1"></b-icon>
                    </b-iconstack>
                    <div class="d-flex flex-column justify-content-center">
                        <div class="avo-text-light neg-margin">Payment Received</div>
                        <div class="stat-text">{{claim_stats.payment_percentage_received || 0}}%</div>
                    </div>
                </div>
            </div>
        </div>
        </b-overlay>
        <b-row>
            <b-col cols="8">
                <!-- TODO: make this a component, maybe make it a table -->
                <b-card class="quick-details-card mb-4">
                    <div class="d-flex flex-row justify-content-between">
                        <div class="summary-card-header-txt pl-2">
                            Quick Details
                        </div>
                        <div @click="setActiveTab('WorkClaimForm')" class="avo-text-light pr-2 mt-1 cursor-pointer" >
                            View full Claim <b-icon icon="chevron-right"></b-icon>
                        </div>
                    </div>
                    <b-row class="mt-2 striped-row details-row">
                        <b-col cols="4" class="pr-0">
                        <b-row>
                            <b-col cols="5" class="label-text mr-0 pr-0">
                            AVO CLAIM ID
                            </b-col>
                            <b-col class="data-text pr-0 pl-1">
                            {{claim.claim_id}}
                            </b-col>
                        </b-row>
                        </b-col>
                        <b-col cols="4" class="pr-0">
                        <b-row>
                            <b-col cols="5" class="label-text mr-0 pr-0">
                            PATIENT
                            </b-col>
                            <b-col class="data-text pl-2 pr-0">
                            {{claim.patient_name || "-"}}
                            </b-col>
                        </b-row>
                        </b-col>
                        <b-col cols="4" class="pr-0">
                        <b-row>
                            <b-col cols="4" class="label-text mr-0 pr-0">
                            PAYER
                            </b-col>
                            <b-col class="data-text pl-0">
                            {{claim.payer_name || "-"}}
                            </b-col>
                        </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="details-row">
                        <b-col cols="4" class="pr-0">
                        <b-row>
                            <b-col cols="5" class="label-text mr-0 pr-0">
                            SUBMITTED
                            </b-col>
                            <b-col class="data-text pr-0 pl-1">
                            {{formatDate(claim.invoiced_date)}}
                            </b-col>
                        </b-row>
                        </b-col>
                        <b-col cols="4" class="pr-0">
                        <b-row>
                            <b-col cols="5" class="label-text mr-0 pr-0">
                            ACCOUNT
                            </b-col>
                            <b-col class="data-text pl-2 pr-0">
                            {{claim.patient ? (claim.patient.account_no || "-") : "-"}}
                            </b-col>
                        </b-row>
                        </b-col>
                        <b-col cols="4" class="pr-0">
                        <b-row>
                            <b-col :cols="4" class="label-text mr-0 pr-0">
                            PHONE
                            </b-col>
                            <b-col class="data-text pl-0">
                            {{claim.payer_phone || "-"}}
                            </b-col>
                        </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="striped-row details-row">
                        <b-col cols="4" class="pr-0">
                        <b-row>
                            <b-col cols="5" class="label-text mr-0 pr-0">
                            CODES
                            </b-col>
                            <b-col class="data-text pr-0 pl-1">
                            <u v-if="claim.cpt_codes.length > 0">
                            <span v-for="(code, index) in claim.cpt_codes" :key=index>
                                {{code.code}}<span v-if="index < (claim.cpt_codes.length - 1)">,</span>
                            </span>
                            </u>
                            <span v-else>
                                -
                            </span>
                            </b-col>
                        </b-row>
                        </b-col>
                        <b-col cols="4" class="pr-0">
                        <b-row>
                            <b-col cols="5" class="label-text mr-0 pr-0">
                            PROVIDER
                            </b-col>
                            <b-col class="data-text pl-2 pr-0">
                            {{claim.provider_name || "-"}}
                            </b-col>
                        </b-row>
                        </b-col>
                        <b-col cols="4" class="pr-0">
                        <b-row>
                            <b-col :cols="4" class="label-text mr-0 pr-0">
                            TAX ID
                            </b-col>
                            <b-col class="data-text pl-0">
                            {{claim.practice ? claim.practice.tax_id || "-" : "-"}}
                            </b-col>
                        </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="details-row">
                        <b-col cols="4" class="pr-0">
                        <b-row>
                            <b-col cols="5" class="label-text mr-0 pr-0">
                            DOS
                            </b-col>
                            <b-col class="data-text pr-0 pl-1">
                            {{formatDate(claim.date_of_service)}}
                            </b-col>
                        </b-row>
                        </b-col>
                        <b-col cols="4" class="pr-0">
                        <b-row>
                            <b-col cols="5" class="label-text mr-0 pr-0">
                            COMPANY
                            </b-col>
                            <b-col class="data-text pl-2 pr-0">
                            {{claim.practice ? claim.practice.name || "-" : "-"}}
                            </b-col>
                        </b-row>
                        </b-col>
                        <b-col cols="4" class="pr-0">
                        <b-row>
                            <b-col :cols="4" class="label-text mr-0 pr-0">
                            NPI
                            </b-col>
                            <b-col class="data-text pl-0">
                            {{claim.practice ? claim.practice.group_npi || "-" : "-"}}
                            </b-col>
                        </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="striped-row details-row">
                        <b-col cols="4" class="pr-0">
                        <b-row>
                            <b-col cols="5" class="label-text mr-0 pr-0">
                            TIMELY FILE
                            </b-col>
                            <b-col class="data-text pr-0 pl-1">
                            <span v-if="claim.timely_days_left">
                                {{claim.timely_days_left}} days left
                            </span>
                            <span v-else>
                                -
                            </span>
                            </b-col>
                        </b-row>
                        </b-col>
                        <b-col cols="4" class="pr-0">
                        <b-row>
                            <b-col cols="5" class="label-text mr-0 pr-0">
                            FACILITY
                            </b-col>
                            <b-col class="data-text pl-2 pr-0">
                            {{claim.practice_location ? claim.practice_location.name || "-" : "-"}}
                            </b-col>
                        </b-row>
                        </b-col>
                        <b-col cols="4" class="pr-0">
                        <b-row>
                            <b-col :cols="4" class="label-text mr-0 pr-0">
                            PTAN
                            </b-col>
                            <b-col class="data-text pl-0">
                            {{claim.practice_location ? claim.practice_location.ptan || "-" : "-"}}
                            </b-col>
                        </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="details-row">
                        <b-col cols="4" class="pr-0">
                        <b-row>
                            <b-col cols="5" class="label-text mr-0 pr-0">
                            DATA SOURCE
                            </b-col>
                            <b-col class="data-text pr-0 pl-1">
                            {{ renderDataSource(claim) }}
                            </b-col>
                        </b-row>
                        </b-col>
                        <b-col cols="4" class="pr-0">
                        <b-row>
                            <b-col cols="5" class="label-text mr-0 pr-0">
                            {{ renderDataSourceKeyName(claim) || "PRIMARY KEY" }}
                            </b-col>
                            <b-col  class="data-text pl-2 pr-0">
                            {{ claim.data_source_id || "-" }}
                            </b-col>
                        </b-row>
                        </b-col>
                        <b-col cols="4">
                        <b-row>
                            <b-col cols="5" class="label-text mr-0 pr-0">
                            PROCEDURE
                            </b-col>
                            <b-col class="data-text pr-0 pl-1">
                            {{ claim.procedure_type || "-" }}
                            </b-col>
                        </b-row>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="worklog-header-card">
                    <div class="d-flex flex-row justify-content-between">
                    <div class="summary-card-header-txt pl-2">
                        Work Logs
                    </div>
                    <div @click="setActiveTab('WorkClaimWorkLog')" class="avo-text-light pr-2 mt-1 cursor-pointer" >
                        View all Work Logs <b-icon icon="chevron-right"></b-icon>
                    </div>
                    </div>
                </b-card>
                <b-card class='worklog-body-card mb-4'>
                    <work-log :hideSearchGroup="true" :hidePagination="true" :logsPerPage="4" :embedded="true"/>
                </b-card>
                <activity-list :claimId="claim.claim_id" :hideFooter="true"/>
            </b-col>
            <b-col cols="4" class="pl-0">
                <notification-box :claimId="claim.claim_id" class="mb-4"/>
                <task-list @viewAllClicked="setActiveTab('WorkClaimTasks')" :claimId="claim.claim_id" :screen="false" class="mb-4"/>
                <b-card class="note-header-card">
                    <div class="d-flex flex-row justify-content-between">
                    <div class="summary-card-header-txt pl-2">
                        Notes
                    </div>
                    <div @click="setActiveTab('WorkClaimNotes')" class="avo-text-light pr-2 mt-1 cursor-pointer" >
                        View all Notes <b-icon icon="chevron-right"></b-icon>
                    </div>
                    </div>
                </b-card>
                <b-card class='note-body-card'>
                    <notes-list :claimId="claim.claim_id" :limitFirstN="2" mode="inline" :hidePagination="true" :hideHeader="true" :hideViewAll="true"/>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { make_url, authenticated_request, formatDateShort as formatDate } from '../../common.js'
import TaskList from '../tasks/TaskList.vue'
import NotesList from '../notes/NotesList.vue'
import ActivityList from '../activity/ActivityList.vue'
import NotificationBox from '../NotificationBox.vue'
import WorkLog from '../worklog/WorkLog.vue'

export default {
    name: 'WorkClaimSummary',
    components: {
        TaskList,
        NotesList,
        ActivityList,
        NotificationBox,
        WorkLog
    },
    data(){
        return {
            claim_stats: {
                days_until_upcoming_task: null,
                days_since_last_action: null,
                payment_percentage_received: null,
            },

            dataSourceLoading: true,
            statsLoading: true,
        }
    },
    methods: {
        getClaimStats: function(){
            var vm = this;
            authenticated_request({
                method: "get",
                url: make_url("/reports/claims/" + this.claim.claim_id + "/stats")
            }).then(function(response){
                const stats = response.data;
                vm.claim_stats = stats;
                return stats;
            }).catch(function(error){
                console.log(error.response)
            }).finally(()=>{
                vm.statsLoading = false;
            });
        },
        renderDataSource: function(claim){
            // Check if claim.data_source starts with ALLSCRIPTS
            if(claim.data_source.startsWith("ALLSCRIPTS")){
                return "Allscripts";
            }
            // Check if claim.data_source starts with DATACENTER
            if(claim.data_source.startsWith("ECLINICALWORKS")){
                return "eCW";
            }
            return "Unknown";
        },
        renderDataSourceKeyName: function(claim){
            // Check if claim.data_source starts with ALLSCRIPTS
            if(claim.data_source.startsWith("ALLSCRIPTS")){
                return "VOUCHER_NO";
            }
            // Check if claim.data_source starts with DATACENTER
            if(claim.data_source.startsWith("ECLINICALWORKS")){
                return "INVOICE_ID";
            }
            return "SOURCE_ID";
        },
        formatDate,
        setActiveTab: function(component){
            this.$store.commit("setWorkedClaimActiveTab", component);
        },
        
    },
    mounted() {
        this.getClaimStats();
    },
    computed: {
        claim() {
            return this.$store.state.claims.workedClaim;
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* increasing b-containers max-width for larger screens */
@media only screen and (min-width : 800px) {
   .container { max-width: 1400px; } 
} 

.claim-stat-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 7px 7px 5px 17px;
    min-width: 180px;
    /*max-width: 200px;*/
    height: 80px;
    border: 1px solid #E2E4EB;
    border-radius: 5px;
}
.stat-col{
    margin-right:24px;
}
.neg-margin {
    margin-bottom: -7px;
}

/* card styles */
.note-header-card,
.worklog-header-card {
  border-radius: 5px 5px 0px 0px;
  border: 1px solid #E2E4EB;
}
.note-body-card,
.worklog-body-card {
  border-radius: 0px 0px 5px 5px;
  border: 1px solid #E2E4EB;
  border-top: none;
}
.note-body-card .card-body,
.worklog-body-card .card-body {
  padding: 0 7px;
}

/* quick view styles */
.striped-row {
    background-color: #F3F8FD;
}
.details-row {
    margin-left: -20px;
    margin-right: -20px;
    min-height: 40px;
    align-items: center;
}
</style>