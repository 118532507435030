<template>
  <div>
  <job-overlay :job="job" 
    @finished="onFinished" 
    :message="''"
    :delay="3000" 
    :showSpinner="false" 
    :showStatus="false">
    <dl id="claim-summary">
      <div v-for="total in totals" v-bind:key="total.label" class="text-right">
        <dd
          v-b-tooltip.hover.right="{ variant: 'dark' }"
          title="Total outstanding claim balances" 
        >{{ formatDollars(total.total, false) }}</dd>
        <dt>{{ total.label }}</dt>
      </div>
    </dl>
  </job-overlay>
  </div>
</template>

<script>
import { make_url, authenticated_request, formatDollars } from "../../common.js";
import JobOverlay from "../JobOverlay.vue";

export default {
  name: "ClaimSummary",
  props: ["issues"],
  components: {
    JobOverlay,
  },
  data() {
    return {
      totals: [
        { label: "0-30 Days", total: "  --" },
        { label: "31-60 Days", total: "  --" },
        { label: "61-90 Days", total: "  --" },
        { label: "91-120 Days", total: "  --" },
        { label: "121+ Days", total: "  --" },
      ],
      
      job: null,
    };
  },
  methods: {
    formatDollars,
    onFinished() {
      this.getClaimSummary();
    },
    getClaimSummary() {
      var self = this;
      var url;
      if (!this.issues) {
        url = make_url("/reports/claims/summary");
      } else {
        url = make_url("/reports/issues/claims/summary");
      }
      authenticated_request({
        method: "get",
        url: url,
      }).then(function (response) {
        self.totals = response.data;
        self.job = response.data?.payload?.job || null;
      });
    },
  },
  beforeMount() {
    this.getClaimSummary();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
dl {
  font-size: 20px;
  color: var(--blue);
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  width: 90%;
  margin-bottom: 25px;
}
dt {
  font-size: 14px;
  font-weight: 400;
  color: #64728c;
}
</style>