<template>
    <div>
      <b-container style="margin-left:0px">
      <div class="sticky-top" style="z-index:10">
        <b-row style="border-bottom:1px solid #E6EEF9;padding-top:15px;padding-bottom:10px;background-color:white;">
          <b-col cols="12" class="d-flex justify-content-end align-items-center">
            <div>
              <b-button @click="workClaim" class="avo-primary-btn mr-3">
                <span v-if="(claim.worker != null) && (!hasPermission('assign_claims')) && (claim.worker.worker_id) && (user_worker_id != claim.worker.worker_id)">
                  View Claim
                </span>
                <span v-else>
                  Work Claim
                </span>
              </b-button>
            </div>
            <div>
              <b-icon @click="closePreview()" icon="x" font-scale="2.5" style="color: var(--gray-800);cursor:pointer" />
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="mt-4 quickview-header">
          <b-row align-v="center" :class="{'mb-3':!hasPermission('assign_claims')}">
              <b-col class="avo-header-text pr-0" cols="">
                {{claim.claim_id}} - {{claim.patient_name || "No patient assigned"}}
              </b-col>
              <div v-if="!hasPermission('assign_claims')" class="d-flex justify-content-end align-items-center">
                <b-avatar v-if="issues ? claim.issues_worker : claim.worker" :text="issues ? claim.issues_worker_initials : claim.worker_initials" class="mr-2" style="width:32px;height:32px;"></b-avatar>
                <div v-if="issues ? claim.issues_worker : claim.worker" class="d-flex flex-column align-items-start">
                    <span style="color:black;font-size:14px;">{{issues ? claim.issues_worker_name : claim.worker_name}}</span>
                    <span class="avo-text-light-small" style="margin-top:0px;padding-top:0px">Assigned</span>
                </div>
                <div v-else style="font-weight:500;font-size:14px;margin-top:5px;margin-left:15px;color:var(--gray)">No assigned worker</div>
              </div>

              <b-col cols="" class="text-right pl-0" v-if="(claim != null) && (worklogOptionsMap != null)">
                <b-icon v-b-modal.new-worklog role="button" icon="list-ul" font-size="1.2rem" class="mr-3"
                v-b-popover.hover.bottom="'Add new worklog'"></b-icon>
                <work-log-add :claim="claim"/>
                <b-icon v-b-modal.new-task role="button" icon="calendar-4" font-size="1.2rem" class="mr-3"
                v-b-popover.hover.bottom="'Add new task'"></b-icon>
                <task-add :selectedClaim="claim"/>
                <b-icon v-b-modal.new-document role="button" icon="paperclip" font-size="1.2rem" class="mr-3"
                v-b-popover.hover.bottom="'Add new document'"></b-icon>
                <document-add idType="claim" :id="claim.claim_id"/>
                <b-icon v-b-modal.new-note role="button" icon="file-earmark" font-size="1.2rem" class="mr-3"
                v-b-popover.hover.bottom="'Add new note'"></b-icon>
                <note-add :claimId="claim.claim_id" />
              </b-col>
          </b-row>
        <b-row>
          <b-col>
            <div class="alert-div">
              <b-alert
                  :show="showAlert"
                  dismissible
                  variant="danger">
                  <b>{{alertMessage}}</b>
              </b-alert>
            </div>
          </b-col>
        </b-row>
        <div class="d-flex align-items-center justify-content-start flex-wrap mt-2">
          <div class="d-flex flex-column mr-4">
            <div class="avo-text-light">Timely Filing</div>
            <div :class="['data-card', timelyFilingClass(claim.timely_days_left)]">
              <b-icon class="mr-2" icon="clock" font-scale="1.5"></b-icon>
              <div class="mr-1">{{claim.timely_days_left || "--"}} </div>
              <span v-if="claim.timely_days_left">days left</span>
            </div>
          </div>
          <div class="d-flex flex-column mr-4">
            <div class="avo-text-light">Follow Up</div>
            <div class="data-card">
              <b-iconstack class="mr-1 ml-1" style="color:#519eff">
                <b-icon icon="circle" scale="1.8"></b-icon>
                <b-icon icon="calendar-4" scale="1"></b-icon>
              </b-iconstack>
              <span style="margin-left:5px" 
                v-b-tooltip="{ variant: 'dark', placement: 'bottom', title: 'To set a new Follow Up date, create a Task' }"
              >
                <span v-if="claim.follow_up">{{formatFollowupDate(claim.follow_up.due_date)}}</span>
                <span v-else>-</span>
              </span>
            </div>
          </div>
          <div class="d-flex flex-column">
            <div class="avo-text-light">Status</div>
            <b-overlay :show="transitionLoading">
              <div class="data-card">
                  <div :class="['status-icon',  claim.state.short]"></div>
                  <div class="">{{claim.state.label}} </div>
              </div>
            </b-overlay>
          </div>
        </div>
        <b-row>
            <b-col cols="8" xl="6">
                <div v-if="hasPermission('assign_claims')" class="d-flex flex-column mt-3">
                  <span class="avo-text-light"> Assigned</span>
                  <claim-worker-select :issues="issues" />
                </div>
              </b-col>
              <b-col v-if="hasPermission('assign_claims')" cols="4" xl="6">
                <div class="avo-text-light mt-3">Claim Actions</div>
                <multiselect 
                placeholder="Pick action" 
                :options="!issues ? actions : issues_actions" 
                :searchable="false" 
                :reset-after="true" 
                :show-labels="false"
                @select="dispatchAction"></multiselect>
              </b-col>
          </b-row>
      </div>
      <div class="mt-4 label-text neg-margin">
        <div class="pl-4" style="text-decoration:underline var(--blue) 3px;text-underline-offset:1.2rem">
          Summary
        </div>
        <hr>
      </div>
      <b-row>
        <b-col cols="4">
          <b-row>
            <b-col cols="5" class="label-text mr-0 pr-0">
              Claim ID
            </b-col>
            <b-col class="data-text pr-0 pl-2">
              {{claim.claim_id}}
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4" class="pr-0">
          <b-row>
            <b-col cols="5" class="label-text mr-0 pr-0">
              Timely File
            </b-col>
            <b-col class="data-text pl-2 pr-0">
              {{timelyFileDate(claim.timely_days_left)}}
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4" class="pr-0">
          <b-row>
            <b-col cols="4" class="label-text mr-0 pr-0">
              Status
            </b-col>
            <b-col class="data-text pl-0">
              {{claim.state.label}}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="4" class="pr-0">
          <b-row>
            <b-col cols="5" class="label-text mr-0 pr-0">
              DOS
            </b-col>
            <b-col class="data-text pl-2 pr-0">
              {{formatDate(claim.date_of_service)}}
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4" class="pr-0">
          <b-row>
            <b-col cols="5" class="label-text mr-0 pr-0">
              Claim Age
            </b-col>
            <b-col class="data-text pl-2 pr-0">
              {{claim.age || "--"}} 
              <span v-if="claim.age"> {{claim.age != 1 ? "days" : "day"}}</span>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4" class="pr-0">
          <b-row>
            <b-col cols="4" class="label-text mr-0 pr-0">
              Visit Type <!-- Changed from: Procedure type -->
            </b-col>
            <b-col class="data-text pl-0">
              {{claim.procedure_type || "--"}}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="4" class="pr-0">
          <b-row>
            <b-col cols="5" class="label-text mr-0 pr-0">
              Patient
            </b-col>
            <b-col class="data-text pr-0 pl-1">
              {{claim.patient_name || "--"}}
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4" class="pr-0">
          <b-row>
            <b-col cols="5" class="label-text mr-0 pr-0">
              Invoiced Date
            </b-col>
            <b-col class="data-text pr-0 pl-1">
              {{formatDate(claim.invoiced_date)}}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="4" class="pr-0">
          <b-row>
            <b-col cols="5" class="label-text mr-0 pr-0">
              Account No
            </b-col>
            <b-col class="data-text pr-0 pl-1">
              {{claim.patient ? claim.patient.account_no || "--" : "--"}}
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4" class="pr-0">
          <b-row>
            <b-col cols="5" class="label-text mr-0 pr-0">
              Codes
            </b-col>
            <b-col class="data-text pl-2 pr-0">
            <u v-if="claim.cpt_codes">
            <span v-for="(code, index) in claim.cpt_codes" :key=index>
                {{code.code}}<span v-if="index < (claim.cpt_codes.length - 1)">,</span>
            </span>
            </u>
            <div v-else>
              -
            </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="4" class="pr-0">
          <b-row>
            <b-col cols="5" class="label-text mr-0 pr-0">
              Insurance
            </b-col>
            <b-col class="data-text pr-0 pl-1">
              {{claim.payer_name || "--"}}
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4" class="pr-0">
          <b-row>
            <b-col cols="5" class="label-text mr-0 pr-0">
              Tax ID
            </b-col>
            <b-col class="data-text pl-2 pr-0">
              {{(claim.practice ? claim.practice.tax_id : null) || "--"}}
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4" class="pr-0">
          <b-row>
            <b-col cols="4" class="label-text mr-0 pr-0">
              Phone
            </b-col>
            <b-col class="data-text pl-0">
              {{claim.payer_phone || "--"}}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="4" class="pr-0">
          <b-row>
            <b-col cols="5" class="label-text mr-0 pr-0">
              NPI
            </b-col>
            <b-col class="data-text pr-0 pl-1">
              {{(claim.provider ? claim.provider.npi : "-") || "--"}}
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4" class="pr-0">
          <b-row>
            <b-col cols="5" class="label-text mr-0 pr-0">
              Group NPI
            </b-col>
            <b-col class="data-text pl-2 pr-0">
              {{(claim.practice ? claim.practice.group_npi : "-") || "--"}}
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4" class="pr-0">
          <b-row>
            <b-col :cols="4" class="label-text mr-0 pr-0">
              Fax
            </b-col>
            <b-col class="data-text pl-0">
              {{claim.payer_fax || "--"}}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="isMedicareClaim" class="mt-2">
        <b-col cols="4" class="pr-0">
          <b-row>
            <b-col cols="4" class="label-text mr-0 pr-0">
              PTAN
            </b-col>
            <b-col class="data-text">
              {{(claim.practice_location ? claim.practice_location.ptan : "-") || "--"}}
            </b-col>
          </b-row>
        </b-col>    
        <b-col cols="4" class="pr-0">
          <b-row>
            <b-col cols="5" class="label-text mr-0 pr-0">
              Provider PTAN
            </b-col>
            <b-col class="data-text pl-0 pr-0">
              {{(claim.provider ? claim.provider.ptan : "-") || "--"}}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="4" class="pr-0">
          <b-row>
            <b-col cols="5" class="label-text mr-0 pr-0">
              Provider
            </b-col>
            <b-col class="data-text pr-0 pl-1">
              {{claim.provider_name || "--"}}
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4" class="pr-0">
          <b-row>
            <b-col cols="5" class="label-text mr-0 pr-0">
              Facility
            </b-col>
            <b-col class="data-text pl-2 pr-0">
              {{(claim.practice_location ? claim.practice_location.name : "-") || "--"}}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-2 mb-4">
        <b-col cols="4" class="pr-0">
          <b-row>
            <b-col cols="5" class="label-text mr-0 pr-0">
              Company
            </b-col>
            <b-col class="data-text pr-0 pl-1">
              {{(claim.practice ? claim.practice.name : "-") || "--"}}
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="5" class="pr-0">
          <b-row>
            <b-col cols="4" class="label-text mr-0 pr-0">
              Address
            </b-col>
            <b-col class="data-text pl-2 pr-0">
              {{(claim.practice_location ? claim.practice_location_address : "-") || "--"}}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col>
        <claim-balance-card :claim="claim" />
        </b-col>
      </b-row>
      <div class="neg-margin">
        <notes-list :claimId="claim.claim_id" mode="inline" :hidePagination="true" :hideViewAll="true" :limitFirstN="2" />
      </div>
      <div class="neg-margin">
        <activity-list-embedded :claimId="claim.claim_id" :hideViewAll="true" />
      </div>
      </b-container>
    </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import NotesList from '../notes/NotesList.vue'
import ActivityListEmbedded from '../activity/ActivityListEmbedded.vue'
import ClaimWorkerSelect from '../claims/ClaimWorkerSelect.vue'
import { make_url, authenticated_request, 
         listUserRoleNames, 
         permissionsMixin, alertsMixin, 
         formatDecimal, 
         formatDateShort as formatDate, 
         formatDate as formatFollowupDate } from '../../common.js'
import NoteAdd from "../notes/NoteAdd.vue"
import DocumentAdd from "../documents/DocumentAdd.vue"
import WorkLogAdd from "../worklog/WorkLogAdd.vue"
import TaskAdd from "../tasks/TaskAdd.vue"
import Multiselect from 'vue-multiselect'
import ClaimBalanceCard from './ClaimBalanceCard.vue'

export default {
  name: 'ClaimPreview',
  mixins: [permissionsMixin, alertsMixin],
  props: ['issues', 'initialClaimId'],
  components: {
    ClaimBalanceCard,
    ActivityListEmbedded,
    NotesList,
    ClaimWorkerSelect,
    NoteAdd,
    DocumentAdd,
    WorkLogAdd,
    TaskAdd,
    Multiselect,
  },
  data(){
      return {
        //Worker assignment/search data
        unassigned: {worker_id: null, full_name: "Unassigned", initials: ""},
        selectableWorkers: [{worker_id: null, full_name: "Unassigned", initials: ""}],
        workersLimit: 5,
        
        //Action dropdown data
        transitionLoading: false,
        actions: ['Submitted to Payer', 'Pending', 'Rejected/Denied',
          'AR Review', 'Client/Management Assistance', 'Posting', 'Patient Balance', 'Close Claim', 'Move to Issues Board'],
        issues_actions: ['Move to Claims Board'],
      }
  },
  methods: {
    formatDate,
    formatDecimal,
    formatFollowupDate,
    transitionClaim(transition) {
      this.transitionLoading = true;
      this.setShowAlert(false, null);
      if(transition == 'move_to_issues' || transition == 'move_to_claims'){
        const url = make_url("/claims/" + this.claim.claim_id);
        const vm = this;
        const promise = authenticated_request({
            method: "put",
            url: url,
            data: {
              'issue_dash': transition == 'move_to_issues' ? 1 : 0
            }
        }).then(function(response){
            if (response) {
              // refresh claims table
              vm.$store.commit("setRefresh", true);
            }
        }).catch(function(error){
            console.log(error.response)
            if (error.response.data) {
              vm.setShowAlert(true, error.response.data.msg);
            }
            
        }).finally(()=>{
            vm.transitionLoading = false;
        });
        return promise;

      } 
      else {
        const url = make_url("/claims/" + this.claim.claim_id + "/transitions/" + transition);
        const vm = this;
        const promise = authenticated_request({
            method: "put",
            url: url,
        }).then(function(response){
            if (response) {
                vm.$store.commit("setNewClaimStateValue", response.data.state);
                vm.$store.commit("setNewClaimStateLabel", response.data.label);
                vm.$store.commit("setNewClaimStateShort", response.data.short);
            }
        }).catch(function(error){
            console.log(error.response)
            if (error.response.data) {
              vm.setShowAlert(true, error.response.data.msg);
            }
            
        }).finally(()=>{
            vm.transitionLoading = false;
        });
        return promise;
      }
    },
    timelyFilingClass: function(daysLeft) {
        var filingClass = '';
        filingClass = daysLeft < 30 ? 'filing-red': daysLeft < 60 ? 'filing-yellow' : 'filing-blue';
        return filingClass;
    },
    timelyFileDate: function(daysLeft) {
      if (daysLeft) {
        var date = new Date();
        date.setDate(date.getDate() + daysLeft);
        var month = (date.getMonth() + 1).toString();
        if (month.length == 1){
          month = "0" + month;
        }
        var day = (date.getDate()).toString();
        if (day.length == 1){
          day = "0" + day;
        }
        var new_date = month + '/'+ day + '/'+ date.getFullYear();
        return new_date
      }
      return "-"
    },
    rerouteToClaimView: function(claim_id) {
      const routeToNavigateTo = { name: this.issues ? 'issue-claim-view' : 'claim-view', params: { initialClaimId: claim_id } };
      if (this.$route.path != this.$router.resolve(routeToNavigateTo).href) this.$router.push(routeToNavigateTo);
    },
    workClaim: function(do_assign=true) {
      // if worker clicks on unassigned non-issue claim or if issue worker clicks on issue claim with unassigned issue worker
      const is_unassigned_regular_claim = !this.issues && this.claim.worker_id == null;
      const is_unassigned_issues_claim = this.issues && this.claim.issues_worker == null;

      const is_worker = this.userRolesList.includes('Worker');
      const is_issues_worker = this.userRolesList.includes('Issues Worker');
      
      const can_assign_regular_claim = is_worker && is_unassigned_regular_claim;
      const can_assign_issues_claim = is_issues_worker && is_unassigned_issues_claim;
      
      const can_assign = can_assign_regular_claim || can_assign_issues_claim;

      const is_not_superadmin = !this.userRolesList.includes('Superadmin');

      const claim_id = this.claim.claim_id;
      if (do_assign && is_not_superadmin && can_assign) {
        this.assignSelfToClaim();
      } else {
        this.$store.commit("setWorkedClaim", this.claim);
      }
      this.rerouteToClaimView(claim_id);
    },
    closePreview: function(){
      this.$store.commit("clearSelectedClaim");
    },
    assignSelfToClaim: function() {
      console.log("Assigning self to claim");
      const vm = this;
      const promise = authenticated_request({
        method: "post",
        url: vm.claimsUrl,
      }).then(function(response){
        if (response){
          vm.updateAssignedWorker();
        }
      }).catch(function(errors){
          vm.setShowAlert(true, errors);
      });
      return promise;
    },
    updateAssignedWorker: function(){
      const vm = this;
      const promise = authenticated_request({
        method: "get",
        url: vm.claimsUrl,
      }).then(function(response){
        if (response){
          var workers = response.data.workers;
          if (workers){
            var worker = workers[0];
            if (!vm.issues){
              vm.claim.worker = worker;
              vm.claim.worker_name = worker.name;
              vm.claim.worker_initials = worker.initials;
            }
            else{
              vm.claim.issues_worker = worker;
              vm.claim.issues_worker_name = worker.name;
              vm.claim.issues_worker_initials = worker.initials;
            }
            vm.$store.commit("setWorkedClaim", vm.claim);
          }
        }
      }).catch(function(errors){
          vm.setShowAlert(true, errors);
      });
      return promise;
    },
    dispatchAction(action) {
      switch (action) {
        case 'Submitted to Payer':
          this.transitionClaim('submit');
          break
        case 'Pending':
          this.transitionClaim('pending');
          break
        case 'Rejected/Denied':
          this.transitionClaim('reject_or_deny');
          break
        case 'AR Review':
          this.transitionClaim('ar_review');
          break
        case 'Client/Management Assistance':
          this.transitionClaim('client_mgmt_assistance');
          break
        case 'Posting':
          this.transitionClaim('posting');
          break
        case 'Patient Balance':
          this.transitionClaim('patient_balance');
          break
        case 'Close Claim':
          this.transitionClaim('close');
          break
        case 'Move to Claims Board':
          this.transitionClaim('move_to_claims');
          break
        case 'Move to Issues Board':
          this.transitionClaim('move_to_issues');
          break
      }
    }
  },
  computed: {
    claim() {
      return this.$store.state.claims.selectedClaim;
    },
    claimsUrl() {
      const url_suffix = this.issues ? 'issues/claims/' : 'claims/';
      const url = make_url("/me/workers/" + url_suffix + this.claim.claim_id).toString();
      return url
    },
    userRolesList(){
      return listUserRoleNames();
    },
    user_worker_id: function() {
      return localStorage.getItem("worker_id");
    },
    isMedicareClaim(){
      return (this.claim.insurance_type == 3);
    },
    worklogOptionsMap(){
      return this.$store.state.claims.worklogOptionsMap;
    }
  },
  updated: function() {
    console.log("Updated called")
    if (this.initialClaimId) {
      console.log("Got initial claim Id");
      this.workClaim(false);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.status-bar-text {
  color: #045E9E;
  font-weight: 500;
  font-size: 13px;
  padding-left: 5px;
}
.parallelogram {
  width: 50%;
  height: 12px;
  transform: skew(-55deg);
  background: #dde4f5;
}
.parallelogram-active {
  background: #4460a7;
}
.parallelogram-current-state {
  width: 160px;
  margin-right: 40px;
  margin-top: 2px;
}
.data-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 38px;
  max-width:175px;
  margin-right:none;
  border-radius: 5px;
  padding-left:10px;
  padding-right:15px;
  font-weight:500;
  font-size: 14px;
  background:#F7F8FA;
}
.neg-margin {
  margin-left: -15px;
  margin-right: -15px;
}

/* assigned muliselect styling */
.assigned-multiselect ::v-deep .multiselect__single{
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 6px;
}

/* Styles for the claim status box and different claim status icons */
.status-icon {
    min-width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 10px;
}

/* dropdown styles */
.dropdown {
  border-radius: 5px;
}
.dropdown ::v-deep .btn-secondary {
  background-color: #F7F8FA;
  border: none;
  height:38px;
  color:var(--dark);
  font-size: 14px;
  font-weight: 500;
}
::v-deep .b-dropdown .btn-secondary:active,
::v-deep .btn-secondary:not(:disabled):not(.disabled):active, 
::v-deep .btn-secondary:not(:disabled):not(.disabled).active, 
::v-deep .show > .btn-secondary.dropdown-toggle {
    color: black;
    background-color: #F7F8FA;
}
::v-deep .b-dropdown .btn-secondary:focus {
    box-shadow:none !important;
}
::v-deep .b-dropdown .btn-secondary:hover {
    background-color: #dde4f5;
}
::v-deep .avo-dropdown .dropdown-menu {
    left: auto !important;
    right: 0 !important;
    font-size: 14px;
    font-weight: 500;
    color: #64728C;
}
::v-deep .multiselect__select {
    top:0;  
}
::v-deep .dropdown-toggle::after {
    color: var(--gray);
    vertical-align: middle;
    margin-left: 7px;
    border: none!important;
    font-family: 'Font Awesome 5 Free' !important;
    content: "\f078" !important;
    font-weight: 900 !important;
}
.filing-red {
  background: #FFF3EB 0% 0% no-repeat padding-box;
  color: #FD4739;
}
.filing-yellow {
  background: #FDF9E7 0% 0% no-repeat padding-box;
  color: #E7A33A;
}
.filing-blue {
  background: #E0EBF3 0% 0% no-repeat padding-box;
  color: #2473AB;
}
</style>