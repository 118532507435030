<template>
    <div class="notes-container">
       <notes-list :claimId="claim.claim_id" mode="list"/>
    </div>
   
</template>

<script>
// import { make_url, authenticated_request } from '../../common.js'
import NotesList from '../notes/NotesList.vue'

export default {
    name: 'WorkClaimNotes',
    components: {
        NotesList,
    },
    computed: {
        claim() {
            return this.$store.state.claims.workedClaim;
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.notes-container {
    margin-top: 25px;
}
</style>