<template>
<div v-if="claims">
  <div :class="selectedClaim ? 'claims-table-wrapper-condensed':'avo-table-lrg-wrapper'">
    <b-table id="claims-table" 
      :sort-by.sync="sortBy" 
      :sort-desc.sync="sortDesc"
      :per-page="perPage" 
      :current-page="currentPage" 
      :busy.sync="tableIsBusy"
      :items="claimsTableProvider"
      :fields="claimsFields"
      :selectable="true"
      :sticky-header="'80vh'"
      select-mode="single"
      @row-selected="setSelected"
      class="avo-table-lrg"
      primary-key="claim_id"
      ref="claimsTable"
      sort-icon-left
      show-empty
      striped>

      <template #empty>
        <div class="text-center text-dark my-2 avo-table-lrg" style="min-height:500px;">
          <strong>{{ busyMessage }}</strong>
        </div>
      </template>

      <template #table-busy>
        <div class="text-center text-dark my-2 avo-table-lrg" style="min-height:500px;">
          <b-spinner class="align-middle mr-2" />
          <strong>{{ busyMessage }}</strong>
        </div>
      </template>

      <template #cell(claim_id)="data">
        <div><strong>{{ renderSourceId(data.item) }}</strong></div>
        <div class="subtext">Avo Claim ID: {{ data.value }}</div>
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center mb-1">
            <div :class="['status-icon',  data.item.state.short]"></div>
            <div class="claim-state">{{ data.item.state.label }}</div>
          </div>
          <div v-if="data.item.substatus_1" class="avo-text-light-small">
            - {{ worklogOptionsMap['claim_substatuses'][data.item.substatus_1]}}</div>
          <div v-if="data.item.substatus_2" class="avo-text-light-small">
            - {{ worklogOptionsMap['claim_substatuses'][data.item.substatus_2]}}</div>
          <div v-if="data.item.substatus_3" class="avo-text-light-small">
            - {{ worklogOptionsMap['claim_substatuses'][data.item.substatus_3]}}</div>
        </div>
      </template>
      
      <template #cell(patient)="data">
        <div>{{ data.item.patient_name || '--' }} </div>
        <div v-if="data.item.patient.account_no">Account No: {{ data.item.patient.account_no }}</div>
        <div class="subtext" v-if="data.item.patient_id">Avo Patient ID: {{ data.item.patient_id }}</div>
      </template>
      
      <template #cell(assigned)="data">
        <div v-if="!issues">
          <span v-if="data.item.worker_name">
          {{ data.item.worker_name }}
          </span>
          <span v-else>
            --
          </span>
        </div>
        <div v-else>
          <span v-if="data.item.issues_worker_name">
          Issues Worker: <br /> 
            {{ data.item.issues_worker_name }}
          </span>
          <span v-else>
            --
          </span>
        </div>
      </template>
      
      <template #cell(payer)="data">
        <div>{{ data.item.payer_name || '--' }}</div>
        <div class="subtext d-flex flex-column">
          <div v-if="data.item.payer_address">{{ data.item.payer_address || "--" }}</div>
        </div>
      </template>

      <template #cell(practice)="data">
        <span v-if="data.item.practice">
        <div>{{ data.item.practice.name || '--' }}</div>
        </span>
        <span v-else>
          --
        </span>
      </template>
      
      <template #cell(provider)="data">
        <div>
            {{ data.item.provider_name || '--' }}<span v-if="data.value.suffix">, {{ data.value.suffix }}</span>
        </div>
        <div class="subtext d-flex flex-column">
          <div v-if="data.item.pracitce_location">{{ data.item.practice_location.name || "--" }}</div>
          <div v-if="data.item.practice_location_address">{{ formatAddress(data.item.practice_location_address) }}</div>
        </div>
      </template>

      <template #cell(cpt_codes)="data">
          <div v-if="data.item.cpt_codes">
          <span v-for="(code, index) in data.item.cpt_codes" :key=index>
              {{code.code}}<span v-if="index < (data.item.cpt_codes.length - 1)">,</span>
          </span>
          </div>
          <div v-else>
            --
          </div>
      </template>

      <template #cell(procedure_type)="data">
        <div>{{ data.item.procedure_type || '--' }}</div>
      </template>
      
      <template #cell(age)="data">
        <div><span v-if="data.value">{{data.value}} Days</span><span v-else>--</span></div> 
        <div><span class="avo-text-light" v-if="data.item.date_of_service">{{ formatDate(data.item.date_of_service) }}</span><span v-else>--</span></div> 
      </template>

      <template #cell(balance)="data">
          <div>{{ formatDollars(data.item.balance) }}</div>
          <div class="subtext">
            <div>{{ formatDollars(data.item.invoiced) }}</div>
            <div>Claim Amount</div>
          </div>
      </template>

      <template #cell(timely_days_left)="data">
        <div 
          :class="['timely-filing-icon', timelyFilingClass(data.value)]"
          v-b-tooltip.hover.left="{variant: 'dark'}"
          :title="!data.value ? 'No timely filing data set for this insurance': 'Number of days remaining since DOS for initial filing, as set for this insurance. Other deadlines may apply.'"
          >
          {{ formatNumber(data.value) }}
        </div>
        <div style="font-size:12px;color:var(--gray);" v-if="data.value">Days Left</div>
      </template>
    </b-table>
  </div>

  <b-pagination
    v-if="showPagination"
    align="center"
    v-model="currentPage"
    :total-rows="totalRows"
    :per-page="perPage"
    aria-controls="claims-table"
  />
</div>
</template>

<script>
import { make_url, 
  cancellable_request, 
  formatAddressShort as formatAddress, 
  formatDateShort as formatDate, 
  formatDollars,
  formatNumber,
} from '../../common'
import { mapState } from 'vuex'
import errorStore from '../../errorStore.js'

export default {
  name: 'ClaimList',
  props: ['issues', 'initialClaimId'],
  data(){
    return {
      current_request: null,
      claims: [],
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      sortBy: null,
      sortDesc: false,
      tableIsBusy: false,
    }
  },
  mounted() {
    if (this.worklogOptionsMap == null) {
      this.refreshWorkLogOptionsMap();
    }
  },
  methods: {
    showError: function(message) {
      errorStore.commit("displayAlerts", [message]);
    },
    claimsTableProvider: function(){
      if (!this.searchReady) {
        return this.claims;
      }
      if (this.current_request) {
        this.current_request.cancel("Fetching claims again, cancelling previous claims fetch");
      }

      let params = this.search_params;
      console.log("Executing claimsTableProvider handler with params: ", params);
      params.per_page = this.perPage;
      params.page = this.currentPage;

      // Handling if an initialClaimId was passed in the props/route
      delete params.claim_id;
      if (this.initialClaimId && !this.refreshNeeded) {
        console.log("Got an initialClaimId: " + this.initialClaimId);
        params.claim_id = this.initialClaimId;
      } 
      
      if(this.sortBy){
          params.sort = this.sortBy + ":" + (this.sortDesc?"desc":"asc")
      }
      params.issues_claims = this.issues
      
      // If initialClaimId, then only pass claim_id in params
      if (this.initialClaimId) {
        params = { claim_id: this.initialClaimId };
      }

      const url = make_url("/reports/claims");
      const vm = this;
      const [initialPromise, source] = cancellable_request({
          method: "get",
          url: url,
          params: params,
      });
      this.current_request = source;

      const promise = initialPromise.then(function(response){
          const items = response.data.claims;
          vm.perPage = response.data.pagination.per_page;
          vm.totalRows = response.data.pagination.total;
          vm.claims = items;
          console.log("Returning from promise with " + items.length + " claims");
          return items || [];
      }).catch(function(error){
          console.log(error);
      }).finally(()=>{
          if (vm.initialClaimId) {
            if (vm.claims.length > 0) {
              vm.setSelectedClaim(vm.claims[0]);
            } else {
              vm.showError(`Could not locate a claim with Avo ID: ${vm.initialClaimId}`);
              vm.$router.push({ name: 'claims' });
            }
          }
      });
      
      return promise;
    },
    setSelected: function(items){
        this.setSelectedClaim(items[0]);
    },
    timelyFilingClass: function(daysLeft) {
        if (daysLeft == null || daysLeft == '') {
            return "filing-none";
        }
        var filingClass = '';
        filingClass = daysLeft < 30 ? 'filing-red': daysLeft < 60 ? 'filing-yellow' : 'filing-blue';
        return filingClass;
    },
    formatAddress,
    formatDate,
    formatDollars,
    formatNumber,
    renderSourceId: function(item) {
      if (item.data_source && item.data_source_id) {
        let dataSource;
        if (item.data_source.startsWith("ALLSCRIPTS")) {
          dataSource = 'Allscripts';
        } else if 
        (item.data_source.startsWith("ECLINICALWORKS")) {
          dataSource = 'eCW';
        } else {
          dataSource = "Unknown";
        }
        return `${item.data_source_id} (${dataSource})`;
      }
      const sourceIds = item.import_source_ids;
      if (sourceIds.allscripts_source_id) {
        return sourceIds.allscripts_source_id + " (Allscripts)";
      } else if (sourceIds.ecw_source_id) {
        return sourceIds.ecw_source_id + " (eCW)";
      } else {
        return "Unknown";
      }
    },
    setSelectedClaim: function(claim) {
      this.$store.commit("setSelectedClaim", claim);
    },
    refreshWorkLogOptionsMap() {
      this.$store.dispatch("fetchWorklogOptionsMap")
    },
  },
  computed: {
    showPagination: function() {
      // pagination does not support all filters exactly, such as min_balance and max_balance
      // so we handle the more glaring possible pagination errors here
      if (this.claims.length < this.perPage && this.currentPage == 1) {
        return false;
      }
      return this.totalRows > this.perPage;
    },
    userIsAuthenticated: function() {
      return errorStore.state.userIsAuthenticated;
    },
    tableIsInitializing: function() {
      return !this.userIsAuthenticated || !this.searchReady;
    },
    ...mapState({
      searchReady: state => state.claims.searchReady,
      search_params: state => state.claims.searchParams,
      refreshNeeded: state => state.claims.refresh,
      newClaimStateValue: state => state.claims.newClaimStateValue,
      newClaimStateLabel: state => state.claims.newClaimStateLabel,
      newClaimStateShort: state => state.claims.newClaimStateShort,
      newClaimWorker: state => state.claims.newClaimWorker,
      selectedClaim: state => state.claims.selectedClaim,
      worklogOptionsMap: state => state.claims.worklogOptionsMap,
    }),
    claimsTable: function(){
      return this.$refs.claimsTable;
    },
    claimsFields: function(){
      const fields = [
        {
          label: 'Claim ID',
          key: 'claim_id',
          tdClass: 'claim-id-col'
        },
        {
          label: 'Patient',
          key: 'patient',
          sortable: true,
        },
        {
          label: this.issues ? 'Issues Worker' : 'Assigned',
          key: 'assigned',
        },
        {
          key: 'payer',
          sortable: true,
        },
        {
          key: 'practice',
          tdClass: 'max-width-col'
        },
        {
          key: 'provider',
          tdClass: 'max-width-col'
        },
        {
          label: 'CPT Codes',
          key: 'cpt_codes',
          tdClass: 'max-width-col'
        },
        {
          label: 'Visit Type',
          key: 'procedure_type',
        },
        {
            label: 'Age (DOS)',
            key: 'age',
            sortable: true,
            thClass: 'max-width-col',
            tdClass: 'right-align-col',
        },
        {
            key: 'balance',
            sortable: true,
            thClass: 'max-width-col',
            tdClass: 'right-align-col',
        },
        { 
            label: 'Timely Filing',
            key: 'timely_days_left',
            sortable: true,
            thClass: 'max-width-col',
            tdClass: 'right-align-col',
        }
      ];
      if (this.selectedClaim){
        // return the field four fields
        return fields.slice(0, 4);
      } else {
        return fields;
      }
    },
    busyMessage: function() {
      if (this.tableIsInitializing) {
        return "Initializing...";
      }
      if (this.tableIsBusy) {
        return "Fetching claims for you ..."
      }
      else {
        return "No claims found, please check your selected filters";
      }
    },
  },
  watch: {
      issues: function(){
        this.claimsTable.refresh();
      },
      refreshNeeded: function(){
        if (this.refreshNeeded){
          this.claimsTable.refresh();
        }
      },
      search_params: function(){
          this.currentPage = 1;
          this.claimsTable.refresh();
      },
      selectedClaim: function(){
        if (this.selectedClaim == null){
          this.claimsTable.clearSelected();
        }
      },
      newClaimStateShort: function(){
        if (this.newClaimStateShort != null){
          for (var index = 0; index < this.claims.length; index++){
            if (this.claims[index].claim_id == this.selectedClaim.claim_id){
              this.claims[index].state.value = this.newClaimStateValue;
              this.claims[index].state.label = this.newClaimStateLabel;
              this.claims[index].state.short = this.newClaimStateShort;
              this.$store.commit("clearNewClaimState");
              this.setSelectedClaim(this.claims[index]);
              break;
            }
          }
        }
      },
      newClaimWorker: function(){
        if (this.newClaimWorker != null){
          for (var index = 0; index < this.claims.length; index++){
            if (this.claims[index].claim_id == this.selectedClaim.claim_id){
              if (this.newClaimWorker.worker_id == null){
                if (!this.issues){
                  this.claims[index].worker_name = null;
                  this.claims[index].worker_initials = null;
                  this.claims[index].worker = null;
                }
                else{
                  this.claims[index].issues_worker_name = null;
                  this.claims[index].issues_initials = null;
                  this.claims[index].issues_worker = null;
                }
              }
              else{
                if (!this.issues){
                  this.claims[index].worker_name = this.newClaimWorker.worker_name;
                  this.claims[index].worker_initials = this.newClaimWorker.worker_initials;
                  this.claims[index].worker = {'worker_id': this.newClaimWorker.worker_id};
                }
                else{
                  this.claims[index].issues_worker_name = this.newClaimWorker.worker_name;
                  this.claims[index].issues_initials = this.newClaimWorker.worker_initials;
                  this.claims[index].issues_worker = {'worker_id': this.newClaimWorker.worker_id};
                }
              }
              this.$store.commit("setNewClaimWorker", null);
              this.setSelectedClaim(this.claims[index]);
              break;
            }
          }
        }
      },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Style for table when claim quickview is open */
.claims-table-wrapper-condensed ::v-deep .b-table-sticky-header {
    border-radius: 15px 0px 0px 15px;
    border: 1px solid #E6EEF9;
    margin-bottom: 20px;
}
.claims-table-wrapper ::v-deep .b-table-sticky-header {
    border-radius: 15px;
    border: 1px solid #E6EEF9;
    margin-bottom: 20px;
}

.avo-table-lrg .subtext {
    font-size: 12px;
    color: var(--gray);
    margin-top:7px;
}
.avo-table-lrg .claim-flag {
    font-size: 12px;
    color: var(--red);
    margin-top:7px;
}
.avo-table-lrg .claim-state {
    font-size: 12px;
    color: var(--gray-800);
    margin-top:4px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.avo-table-lrg::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.avo-table-lrg {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Styles for timely filing icons */
.timely-filing-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    width: 47px;
    height: 38px;
    border-radius: 5px; 
    font-size: 18px;
    font-weight: 700;
    background: var(--gray-light) 0% 0% no-repeat padding-box;
    border: 1px solid black;
}
.filing-red {
  background: #FFF3EB 0% 0% no-repeat padding-box;
  border: 1px solid #FD4739;
  color: #FD4739;
}
.filing-yellow {
  background: #FDF9E7 0% 0% no-repeat padding-box;
  border: 1px solid #EEC05A;
  color: #E7A33A;
}
.filing-blue {
  background: #E0EBF3 0% 0% no-repeat padding-box;
  border: 1px solid #2473AB;
  color: #2473AB;
}
.filing-none {
  background: #F5F8FA 0% 0% no-repeat padding-box;
  border: 1px solid var(--gray-light);
  color: var(--gray-light);
}
</style>