<template>
<b-modal id="edit-payment-responsibilities" title="Payment Responsibility" hide-footer size="md">
<b-alert
    v-model="showAlert"
    variant="danger"
    dismissible>
    <b>{{alertMessage}}</b>
</b-alert>
<b-form @submit="onSubmit" class="p-3">
    <b-form-group label="Responsibility Type" label-for="payment-responsibility-type">
      <button-select v-model="paymentResponsibilityType" :options="paymentResponsibilityTypeOptions"/>
    </b-form-group>
    <b-form-group label="Responsible Insurance" label-for="responsible-payer-id">
      <b-form-select v-if="payersOptions" v-model="responsiblePayerId" id="responsible-payer-id" :options="payersOptions" placeholder="Responsible payer" />
      <b-form-input v-else type="number" v-model="responsiblePayerId" id="responsible-payer-id" placeholder="Responsible payer id" />
    </b-form-group>
    <b-row>
      <b-col class="mt-2" style="text-align:right">
        <b-button @click="onSubmit" :disabled="updateInProgress" class="avo-primary-btn">
          Save
          </b-button>
      </b-col>
    </b-row>
</b-form>
</b-modal>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from '../../common.js'
import ButtonSelect from '../ButtonSelect.vue'

export default {
  name: 'PaymentResponsibilityEdit',
  components: {
    ButtonSelect,
  },
  mixins: [alertsMixin],
  props: {
    insuranceOptions: {
      type: Array || null,
      required: false,
      default: null
    },
    responsiblePartyData: {
      type: Object || null,
      required: false,
      default: null
    },
  },
  data: function(){
      return {
        paymentResponsibilityTypeOptions: [
          {
            value: 'UNKNOWN',
            text: 'Unknown'
          },
          {
            value: 'PATIENT',
            text: 'Patient'
          },
          {
            value: 'INSURANCE',
            text: 'Insurance'
          }
        ],
        paymentResponsibilityType: this.responsiblePartyData?.responsible_party_type || null,
        responsiblePayerId: this.responsiblePartyData?.responsible_payer_id || null,
        updateInProgress: false,
      }
  },
  methods: {
    onSubmit(e){
      e.preventDefault();
      this.updateInProgess = true;
      const vm = this;
      this.updateClaimResponsibility()
        .then(
          (success) => {
            vm.emitSubmitted();
            vm.$store.commit("refreshActivityLog");
            vm.hideModal();
          },
          (error) => {
            vm.showAvoAlert(error);
          }
        ).finally(() => {
          vm.updateInProgress = false;
        });
    },
    updateClaimResponsibility(){
      const promise = authenticated_request({
        url: make_url('/claims/' + this.claim.claim_id + '/responsible_party'),
        method: 'PUT',
        data: {
          responsible_party_type: this.paymentResponsibilityType || null,
          responsible_payer_id: this.responsiblePayerId || null,
        }
      });
      return promise;
    },
    hideModal(){
      this.$bvModal.hide('edit-payment-responsibilities');
    },
    emitSubmitted(){
      this.$emit('submitted');
    },
  },
  computed: {
    claim() {
        return this.$store.state.claims.workedClaim;
    },
    payersFromProps() {
      return this.insuranceOptions;
    },
    payersFromStore() {
      const insurances = this.$store.state.claims?.insurances;
      if (!insurances) {
        return null;
      }
      const payersOptions = insurances.map(payer => {
          return {
            value: payer.payer_id,
            text: payer.name,
          };
        });
      return payersOptions;
    },
    payersOptions() {
      return this.payersFromProps || this.payersFromStore;
    },
  },
  watch: {
    responsiblePartyData: {
      handler(newValue, oldValue) {
        this.paymentResponsibilityType = newValue?.responsible_party_type || null;
        this.responsiblePayerId = newValue?.responsible_payer_id || null;
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>