<template>
    <multiselect
        openDirection="bottom"
        placeholder="Search by code or description" 
        label="code"
        :custom-label="codeWithDescription"
        v-model="selectedCode"
        track-by="code"
        :options="selectableCodes"
        :multiple="multiple"
        :loading="codesLoading"
        :close-on-select="false" 
        :internal-search="false"
        :show-labels="false"
        @search-change="debounceCPTCodes"
        :showNoOptions="true">
        <template slot="noResult">
            No codes matched your search
        </template>
        <template slot="noOptions">
            Start typing a code or description...
        </template>
    </multiselect>
</template>

<script>
import { make_url, authenticated_request, debounce } from '../../common.js'
import Multiselect from 'vue-multiselect'

export default {
  name: 'CodeMultiselect',
  components: { 
      Multiselect,
  },
  props: {
      value: [Object, Array],
      multiple: {
          type: Boolean,
          default: false
      },
  },
  data: function(){
      return {
          selectableCodes: [],
          selectedCode: null,
          codeSearchText: "",
          codesLoading: false,
      }
  },
  methods: {
    codeSearchChange: function(searchQuery) {
        this.codesLoading = true;
        this.codeSearchText = searchQuery
        if (searchQuery.length > 0 ) {
            this.CPTAutocomplete();
        } else {
            this.codesLoading = false;
        }
    },
    CPTAutocomplete() {
        var vm = this;
        const url = make_url("/reports/codes/autocomplete");
        authenticated_request({
            method: "get",
            url: url,
            params: {
                search: this.codeSearchText,
                limit: 10
            }
        }).then(function(response){
            vm.codesLoading = false;
            const codes = response.data.codes;
            vm.selectableCodes = codes || [];
            return codes || [];
        }).catch(function(errors){
            console.log(errors.response)
            vm.showError(errors);
        });
    },
    codeWithDescription(code){
        if (code.code && code.short){
            return (code.code + ": " + code.short)
        }
        return "Code/description missing"
    },
  },
  watch: {
      selectedCode() {
          this.$emit('input', this.selectedCode)
      },
  },
    created(){
        this.debounceCPTCodes = debounce(this.codeSearchChange, 300)
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::v-deep .multiselect__spinner {
    background: #DDE4F5 !important;
}
::v-deep .invalid .multiselect__tags {
    border:1px solid #DC3545;
    border-radius: 5px;
}
</style>