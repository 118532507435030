<template>
<div id="edit-claim">
<b-modal id="claim-edit" @show="refreshSelections" @hide="refreshSelections" title="Edit Claim" hide-footer size="md">
<b-form>
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <div>
        <div>
            <b-form-group
            id="patient"
            label="Patient">
            <b-input-group>
                <multiselect
                    openDirection="bottom"
                    v-model="patient" 
                    :options="selectable_patients"
                    placeholder="Select a patient" 
                    label="name" 
                    :searchable="true"
                    :show-labels="false"
                    :allow-empty="true"
                    :internalSearch="true"
                    track-by="patient_id">
                </multiselect>
            </b-input-group>
            </b-form-group>
        </div>
        <div>
            <b-form-group
            id="payer"
            label="Payer">
            <b-input-group>
                <multiselect
                    openDirection="bottom"
                    v-model="payer" 
                    :options="selectable_payers"
                    placeholder="Select a payer" 
                    label="name" 
                    :searchable="true"
                    :show-labels="false"
                    :allow-empty="true"
                    :internalSearch="true"
                    track-by="payer_id">
                </multiselect>
            </b-input-group>
            </b-form-group>
        </div>
        <div>
            <b-form-group
            id="provider"
            label="Provider">
            <b-input-group>
                <multiselect
                    openDirection="bottom"
                    v-model="provider" 
                    :options="selectable_providers"
                    placeholder="Select a provider" 
                    label="name" 
                    :searchable="true"
                    :show-labels="false"
                    :allow-empty="true"
                    :internalSearch="true"
                    track-by="provider_id">
                </multiselect>
            </b-input-group>
            </b-form-group>
        </div>
        <div>
            <b-form-group
            id="practice"
            label="Practice">
            <b-input-group>
                <multiselect
                    openDirection="bottom"
                    v-model="practice" 
                    :options="selectable_practices"
                    placeholder="Select a practice" 
                    label="name" 
                    :searchable="true"
                    :show-labels="false"
                    :allow-empty="true"
                    :internalSearch="true"
                    track-by="practice_id">
                </multiselect>
            </b-input-group>
            </b-form-group>
        </div>
        <div v-if="practice">
            <b-form-group
            id="practice-locations"
            label="Practice Location">
            <b-input-group>
                <multiselect
                    openDirection="bottom"
                    v-model="practice_location" 
                    :options="selectable_practice_locations"
                    placeholder="Select a practice locations" 
                    label="name" 
                    :searchable="true"
                    :show-labels="false"
                    :allow-empty="true"
                    :internalSearch="true"
                    track-by="location_id">
                </multiselect>
            </b-input-group>
            </b-form-group>
        </div>
        <div>
            <b-form-group
            id="invoiced"
            label="Invoiced">
            <b-input-group>
                <b-form-input type="number" v-model="invoiced"></b-form-input>
            </b-input-group>
            </b-form-group>
        </div>
        <div>
            <b-form-group
            id="invoiced-date"
            label="Invoiced Date">
            <b-input-group>
                <b-form-input type="date" 
                v-model="invoiced_date"
                today-button
                reset-button
                close-button></b-form-input>
            </b-input-group>
            </b-form-group>
        </div>

        <div class="mt-4" style="text-align:right">
            <b-button @click="submitClaim" variant="outline-primary" class="avo-primary-btn" :disabled="submitting">
                <b-overlay :show="submitting" variant="white">Submit Claim Changes</b-overlay>
            </b-button>
        </div>
    </div>
</b-form>
</b-modal>
</div>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from '../../common.js'
import Multiselect from 'vue-multiselect'

export default {
  name: 'ClaimEdit',
  props: ['issues'],
  mixins: [alertsMixin],
  components: {
      Multiselect
  },
  data: function(){
      return {
          patient: null,
          selectable_patients: [],
          payer: null,
          selectable_payers: [],
          provider: null,
          selectable_providers: [],
          practice: null,
          selectable_practices: [],
          practice_location: null,
          practice_locations_dict: {},
          selectable_practice_locations: [],
          invoiced: null,
          invoiced_date: null,

          submitting: false,
      }
  },
  methods: {
    getOptions(){
        var self = this;
        authenticated_request({
            method: "get",
            url: make_url("/reports/claims/options")
        }).then(function(response){
            self.setShowAlert(false);
            self.selectable_patients = response.data.patients || [];
            self.selectable_payers = response.data.payers || [];
            self.selectable_providers = response.data.providers || [];
            self.selectable_practices = response.data.practices || [];
            self.practice_locations_dict = response.data.practice_locations || [];
        }).catch(function(error){
            self.setShowAlert(true, error);
        }).finally(()=>{
            self.$emit('loaded');
        });
    },
    submitClaim(){
        this.submitting = true;
        var data = {
            "invoiced": this.invoiced,
            "invoiced_date": this.invoiced_date + "T00:00:00+00:00",
            "patient_id": (this.patient != null) ? this.patient.patient_id : null,
            "payer_id": (this.payer != null) ? this.payer.payer_id : null,
            "provider_id": (this.provider != null) ? this.provider.provider_id : null,
            "practice_id": (this.practice != null) ? this.practice.practice_id : null,
            "practice_location_id": (this.practice_location != null) ? this.practice_location.location_id : null
        }

        var self = this;
        authenticated_request({
            method: "put",
            url: make_url("/claims/" + this.workedClaim.claim_id),
            data: data
        }).then(function(response){
            if (response){
                self.refreshWorkedClaimData();
            }
        }).catch(function(error){
            self.setShowAlert(true, error);
            self.submitting = false
        });
    },
    datetimeToDate(datetime){
        if (datetime != "-"){
            datetime = datetime.split("T")[0];
        }
        return datetime;
    },
    refreshSelections(){
        this.patient = this.selectable_patients.find(selectable_patients => selectable_patients.patient_id === this.workedClaim.patient_id);
        this.payer = this.selectable_payers.find(selectable_payers => selectable_payers.payer_id === this.workedClaim.payer_id);
        this.provider = this.selectable_providers.find(selectable_providers => selectable_providers.provider_id === this.workedClaim.provider_id);
        this.practice = this.selectable_practices.find(selectable_practices => selectable_practices.practice_id === this.workedClaim.practice_id);
        if (this.practice != null){
            this.selectable_practice_locations = this.practice_locations_dict[this.practice.practice_id] || [];
            this.practice_location = this.selectable_practice_locations.find(selectable_practice_locations => selectable_practice_locations.location_id === this.workedClaim.practice_location_id);
        }
        else{
            this.practice_location = null;
        }
    },
    refreshWorkedClaimData(){
        var self = this;
        const url = make_url("/reports/claims");
        authenticated_request({
            method: "get",
            url: url,
            params: {
                'claim_id': this.workedClaim.claim_id,
                'issues_claims': self.issues,
            }
        }).then(function(response){
            var claim = response.data.claims[0];
            self.$store.commit("setWorkedClaim", claim);
            self.$store.commit("refreshActivityLog");
            self.$bvModal.hide('claim-edit');
        }).catch(function(error){
            self.setShowAlert(true, error);
        }).finally(()=>{
            self.submitting = false;
        });
    },
  },
  computed: {
    workedClaim() {
        return this.$store.state.claims.workedClaim;
    },
  },
  watch: {
      workedClaim: function() {
        this.invoiced = this.workedClaim.invoiced;
        this.invoiced_date = this.datetimeToDate(this.workedClaim.invoiced_date);
        this.refreshSelections();
      },
      selectable_patients: function(){
          this.patient = this.selectable_patients.find(selectable_patients => selectable_patients.patient_id === this.workedClaim.patient_id);
      },
      selectable_payers: function(){
          this.payer = this.selectable_payers.find(selectable_payers => selectable_payers.payer_id === this.workedClaim.payer_id);
      },
      selectable_providers: function(){
          this.provider = this.selectable_providers.find(selectable_providers => selectable_providers.provider_id === this.workedClaim.provider_id);
      },
      selectable_practices: function(){
          this.practice = this.selectable_practices.find(selectable_practices => selectable_practices.practice_id === this.workedClaim.practice_id);
      },
      practice: function(){
          if (this.practice != null){
            this.selectable_practice_locations = this.practice_locations_dict[this.practice.practice_id] || [];
            this.practice_location = this.selectable_practice_locations.find(selectable_practice_locations => selectable_practice_locations.location_id === this.workedClaim.location_id);
          }
          else{
              this.practice_location = null;
          }
      }
  },
  async mounted(){
    this.getOptions();
    this.invoiced = this.workedClaim.invoiced;
    this.invoiced_date = this.datetimeToDate(this.workedClaim.invoiced_date);
  }
}
</script>

<style scoped>

</style>