<template>
   <div class="activity-container">
       <activity-manager :claimId="claim.claim_id" :perPage=10 />
   </div>
</template>

<script>
// import { make_url, authenticated_request } from '../../common.js'
import ActivityManager from '../activity/ActivityManager.vue'

export default {
    name: 'WorkClaimActivity',
    components: {
        ActivityManager
    },
    computed: {
        claim() {
            return this.$store.state.claims.workedClaim;
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.activity-container {
    padding-top: 25px;
}
</style>