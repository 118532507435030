<template>
<b-modal id="edit-code-payers" @hide="resetModal" title="Payer rates" hide-footer size="xl" @hidden="onModalHidden">
<b-form @submit="onSubmit">
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>

    <b-row align-h="center">
        <b-col cols=9>
            <b-card bg-variant="light" text-variant="dark">
                <b-row>
                    <b-col>
                        <b-card-text><b>Code:</b> {{ code.cpt_code || 'N/A'}}</b-card-text>
                        <b-card-text><b>Description:</b> {{ code.short || 'N/A' }}</b-card-text>
                    </b-col>
                    <b-col>
                        <b-card-text><b>Modifiers:</b> {{ code.modifiers || 'N/A'}}</b-card-text>
                        <b-card-text><b>POS/TOS:</b> {{ code.pos || 'N/A'}}, {{ code.tos || 'N/A'}}</b-card-text>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
            
    <div v-if="staged_payers.length <=0">
        <b-button v-b-modal.new-payers-modal class="avo-primary-btn mt-4">Add more payers</b-button>
    </div>

    <div v-if="staged_payers.length > 0">
    <b-card class="mt-4">
    <h5>New payers</h5>
    <b-table-simple striped class="mt-1">
        <b-thead>
            <b-tr>
                <b-th />
                <b-th>Payer</b-th>
                <b-th>Charge Fee</b-th>
                <b-th>Contracted Fee</b-th>
                <b-th>Allowed Amount</b-th>
                <b-th v-if="show_surgery_center_fields">Charge Fee <span :class="show_surgery_center_fields ? 'badge badge-primary' : 'badge badge-secondary'">Surgery Center</span></b-th>
                <b-th v-if="show_surgery_center_fields">Contracted Fee <span :class="show_surgery_center_fields ? 'badge badge-primary' : 'badge badge-secondary'">Surgery Center</span></b-th>
                <b-th v-if="show_surgery_center_fields">Allowed Amount <span :class="show_surgery_center_fields ? 'badge badge-primary' : 'badge badge-secondary'">Surgery Center</span></b-th>
                <b-th />
            </b-tr>
        </b-thead>
        <b-tbody>
            <b-tr v-for="(payer,payer_index) in staged_payers" :key="'new'+payer_index">
                <b-td>
                    <b-icon icon="dot" font-scale="1.25" class="mb-2" style="color: #3455eb;" />
                </b-td>
                <b-td>
                    {{ payer.payer_object? payer.payer_object.name : none || "N/A"}}
                    <div class="avo-text-light">{{ payer.payer_object ? payer.payer_object.full_address : none || "No address" }}</div>
                </b-td>
                <b-td>
                    {{payer.charge_fee ? '$'+payer.charge_fee : "N/A"}}
                </b-td>
                <b-td>
                    {{payer.contracted_fee ? '$'+payer.contracted_fee : "N/A"}}
                </b-td>
                <b-td>
                    {{payer.allowed_amount ? '$'+payer.allowed_amount : "N/A"}}
                </b-td>
                <b-td v-if="show_surgery_center_fields">
                    {{payer.charge_fee_surgery_center ? '$'+payer.charge_fee_surgery_center : "N/A"}}
                </b-td>
                <b-td v-if="show_surgery_center_fields">
                    {{payer.contracted_fee_surgery_center ? '$'+payer.contracted_fee_surgery_center : "N/A"}}
                </b-td>
                <b-td v-if="show_surgery_center_fields">
                    {{payer.allowed_amount_surgery_center ? '$'+payer.allowed_amount_surgery_center : "N/A"}}
                </b-td>
                <b-td>
                    <b-icon @click="removePayerRow(payer_index)" icon="x-circle" font-scale="1.25" class="mb-2" style="color: var(--gray-800); cursor:pointer" />
                </b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>

    <b-row class="mt-1">
        <b-col>
        <b-button v-b-modal.new-payers-modal class="avo-primary-btn">Stage more payers</b-button>
        </b-col>
        <b-col style="text-align:right">
            <b-button @click="onSubmit" class="avo-primary-btn">
                Save new payers <b-icon icon="arrow-down-right-square"/> 
            </b-button>
        </b-col>
    </b-row>
    </b-card>
    </div>
    
    <b-overlay :show="!payerRatesLoaded">
    <div v-if="old_payers.length > 0">
    <b-table-simple striped class="mt-4">
        <b-thead>
            <b-tr>
                <b-th />
                <b-th>Payer</b-th>
                <b-th>Charge Fee</b-th>
                <b-th>Contracted Fee</b-th>
                <b-th>Allowed Amount</b-th>
                <b-th v-if="show_surgery_center_fields">Charge Fee <span :class="show_surgery_center_fields ? 'badge badge-primary' : 'badge badge-secondary'">Surgery Center</span></b-th>
                <b-th v-if="show_surgery_center_fields">Contracted Fee <span :class="show_surgery_center_fields ? 'badge badge-primary' : 'badge badge-secondary'">Surgery Center</span></b-th>
                <b-th v-if="show_surgery_center_fields">Allowed Amount <span :class="show_surgery_center_fields ? 'badge badge-primary' : 'badge badge-secondary'">Surgery Center</span></b-th>
                <b-th />
            </b-tr>
        </b-thead>
        <b-tbody>
            <b-tr v-for="(payer,payer_index) in old_payers" :key="payer_index">
                <b-td />
                <b-td>
                    {{ payer.name || "N/A"}}
                    <div class="avo-text-light">{{ payer.full_address || "No address" }}</div>
                </b-td>
                <b-td>
                    {{payer.charge_fee ? '$'+payer.charge_fee : "N/A"}}
                </b-td>
                <b-td>
                    {{payer.contracted_fee ? '$'+payer.contracted_fee : "N/A"}}
                </b-td>
                <b-td>
                    {{payer.allowed_amount ? '$'+payer.allowed_amount : "N/A"}}
                </b-td>
                <b-td v-if="show_surgery_center_fields">
                    {{payer.charge_fee_surgery_center ? '$'+payer.charge_fee_surgery_center : "N/A"}}
                </b-td>
                <b-td v-if="show_surgery_center_fields">
                    {{payer.contracted_fee_surgery_center ? '$'+payer.contracted_fee_surgery_center : "N/A"}}
                </b-td>
                <b-td v-if="show_surgery_center_fields">
                    {{payer.allowed_amount_surgery_center ? '$'+payer.allowed_amount_surgery_center : "N/A"}}
                </b-td>
                <b-td>
                        <b-dropdown class="avo-action-dropdown" no-caret style="min-width:none" :right="true">
                            <template #button-content>
                            <b-icon icon="three-dots"></b-icon>
                            </template>
                                <b-dropdown-item @click="viewEditPayerRatesModal(payer, payer_index)"><b-icon variant="secondary" class="mr-2" icon="pencil"></b-icon> Edit payer rates</b-dropdown-item>
                                <b-dropdown-item @click="viewDeletePayerRatesModal(payer_index)"><b-icon variant="secondary" class="mr-2" icon="trash"></b-icon> Remove payer from code</b-dropdown-item>
                        </b-dropdown>   

                        <b-modal class="modal" :id="'remove-payer-rates-modal-'+payer_index" hide-footer hide-header>
                            <div class="avo-header-text mt-2 mb-4 bold" style="text-align:center">
                                Remove fee details for {{payer.name}}?
                            </div>
                            <div class="avo-text-light mb-4">
                                You will lose all fee rates associated with {{payer.name}} for CPT code {{cpt_code}} in {{ year }}. This cannot be undone.
                            </div>
                            <div style="text-align:right">
                                <b-button-group>
                                <b-button class="avo-basic-btn ml-2" @click="hideDeletePayerRatesModal(payer_index)">Cancel</b-button>
                                <b-button class="avo-primary-btn ml-2" @click="deletePayerRates(payer_index, payer.payer_id, cpt_code, year)" variant="primary">Yes, delete fee details</b-button>
                                </b-button-group>
                            </div>
                        </b-modal>
                </b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
    <b-form-checkbox type="checkbox" size="md" id="show_surgery_checkbox" v-model="show_surgery_center_fields" switch> Show <span :class="show_surgery_center_fields ? 'badge badge-primary' : 'badge badge-secondary'">Surgery Center</span> fields </b-form-checkbox>
    </div>

    <div v-else style="text-align:center" class="mt-4">
    No payers on file
    </div>
    </b-overlay>

    <b-modal id="new-payers-modal" title="Add payers" hide-footer size="lg">

        <div v-for="(payer, index) in new_payers" :key="`payer-${index}`">
            <b-card class="mt-2 mb-2">
                <b-row>
                    <b-col style="text-align:left">
                        <h5>Payer {{ index + 1 }}</h5>
                    </b-col>
                    <b-col style="text-align:right">
                    <b-icon @click="removeNewPayerRow(index)" icon="x-circle" font-scale="1.25" class="mb-2" style="color: var(--gray-800); cursor:pointer" />
                    </b-col>
                </b-row>
                <b-form-row>
                <b-col>
                <b-form-group
                id="payer"
                label="Payer:"
                label-size="lg"
                label-cols-lg="3">
                <b-input-group>
                        <payer-multiselect
                            v-model="payer.payer_object"
                        />
                </b-input-group>
                </b-form-group>
                </b-col>
                </b-form-row>
                <b-form-group
                id="rates-master"
                label="Rates:"
                label-size="lg"
                label-cols-lg="3">
                <b-form-row>
                <b-col>
                    <b-form-group
                    id="charge-fee"
                    label="Charge fee:">
                    <b-input-group prepend="$">
                        <b-form-input v-model="payer.charge_fee" type="number" required></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                    id="contracted-fee"
                    label="Contracted fee:">
                    <b-input-group prepend="$">
                        <b-form-input v-model="payer.contracted_fee" type="number" required></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                    id="allowed-amount"
                    label="Allowed amount:">
                    <b-input-group prepend="$">
                        <b-form-input v-model="payer.allowed_amount" type="number" required></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </b-col>
                </b-form-row>
                </b-form-group>
                <b-form-group
                id="rates-surgery-center"
                label="Rates for surgery centers:"
                label-size="lg"
                label-cols-lg="3"
                v-show="show_surgery_center_fields">
                    <b-form-row>
                    <b-col>
                        <b-form-group
                        id="charge-fee-surgery-center"
                        label="Charge fee:">
                            <template #label>
                            Charge fee: <span class="badge badge-primary">Surgery Center</span>
                            </template>
                        <b-input-group prepend="$">
                            <b-form-input v-model="payer.charge_fee_surgery_center" type="number" :required="show_surgery_center_fields"></b-form-input>
                        </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group
                        id="contracted-fee-surgery-center"
                        label="Contracted fee:">
                            <template #label>
                            Contracted fee: <span class="badge badge-primary">Surgery Center</span>
                            </template>
                        <b-input-group prepend="$">
                            <b-form-input v-model="payer.contracted_fee_surgery_center" type="number" :required="show_surgery_center_fields"></b-form-input>
                        </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group
                        id="allowed-amount-surgery-center"
                        label="Allowed amount:">
                            <template #label>
                            Allowed amount: <span class="badge badge-primary">Surgery Center</span>
                            </template>
                        <b-input-group prepend="$">
                            <b-form-input v-model="payer.allowed_amount_surgery_center" type="number" :required="show_surgery_center_fields"></b-form-input>
                        </b-input-group>
                        </b-form-group>
                    </b-col>
                    </b-form-row>
                </b-form-group>
                <b-form-checkbox type="checkbox" size="md" id="show_surgery_checkbox" v-model="show_surgery_center_fields" switch> Show <span :class="show_surgery_center_fields ? 'badge badge-primary' : 'badge badge-secondary'">Surgery Center</span> fields </b-form-checkbox>
            </b-card>
        </div>
        
        <b-row>
            <b-col style="text-align:left">
                <b-button @click="addNewPayer" class="avo-primary-btn mt-2">
                    <b-icon icon="plus-circle"></b-icon> Add another payer
                </b-button>
            </b-col>
            <b-col style="text-align:right">
                <b-button @click="stageNewPayers" class="avo-primary-btn mt-2">
                    <b-icon icon="clipboard-plus" /> Stage payer details
                </b-button>
            </b-col>
        </b-row>
    </b-modal>

</b-form>

<!-- Edit payer rates modal -->
<b-modal v-if="selectedPayer" id="edit-payer-rates" :title="'Edit rates for ' + selectedPayer.name" hide-footer size="lg">
<b-form>
    <b-card class="mt-2 mb-2">
    <b-form-group
            id="rates-master"
            label="Rates:"
            label-size="lg"
            label-cols-lg="3">
            <b-form-row>
            <b-col>
                <b-form-group
                id="charge-fee"
                label="Charge fee:">
                <b-input-group prepend="$">
                    <b-form-input v-model="payer.charge_fee" type="number" required></b-form-input>
                </b-input-group>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                id="contracted-fee"
                label="Contracted fee:">
                <b-input-group prepend="$">
                    <b-form-input v-model="payer.contracted_fee" type="number" required></b-form-input>
                </b-input-group>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                id="allowed-amount"
                label="Allowed amount:">
                <b-input-group prepend="$">
                    <b-form-input v-model="payer.allowed_amount" type="number" required></b-form-input>
                </b-input-group>
                </b-form-group>
            </b-col>
            </b-form-row>
            </b-form-group>
            <b-form-group
            id="rates-surgery-center"
            label="Rates for surgery centers:"
            label-size="lg"
            label-cols-lg="3"
            v-show="show_surgery_center_fields">
                <b-form-row>
                <b-col>
                    <b-form-group
                    id="charge-fee-surgery-center"
                    label="Charge fee:">
                        <template #label>
                        Charge fee: <span class="badge badge-primary">Surgery Center</span>
                        </template>
                    <b-input-group prepend="$">
                        <b-form-input v-model="payer.charge_fee_surgery_center" type="number" :required="show_surgery_center_fields"></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                    id="contracted-fee-surgery-center"
                    label="Contracted fee:">
                        <template #label>
                        Contracted fee: <span class="badge badge-primary">Surgery Center</span>
                        </template>
                    <b-input-group prepend="$">
                        <b-form-input v-model="payer.contracted_fee_surgery_center" type="number" :required="show_surgery_center_fields"></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                    id="allowed-amount-surgery-center"
                    label="Allowed amount:">
                        <template #label>
                        Allowed amount: <span class="badge badge-primary">Surgery Center</span>
                        </template>
                    <b-input-group prepend="$">
                        <b-form-input v-model="payer.allowed_amount_surgery_center" type="number" :required="show_surgery_center_fields"></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </b-col>
                </b-form-row>
            </b-form-group>
    <b-row>
        <b-col style="text-align:right mb-2">
            <b-form-checkbox type="checkbox" size="md" id="show_surgery_checkbox" v-model="show_surgery_center_fields" switch> Edit <span :class="show_surgery_center_fields ? 'badge badge-primary' : 'badge badge-secondary'">Surgery Center</span> fields </b-form-checkbox>
        </b-col>
    </b-row>
    </b-card>

    <b-row>
        <b-col style="text-align:right">
            <b-button @click="updatePayerRates" class="avo-primary-btn mt-2">
                Save new rates <b-icon icon="arrow-down-right-square"/> 
            </b-button>
        </b-col>
    </b-row>

</b-form>
</b-modal>
<!-- Old:
    {{ old_payers }}
Staged:
    {{ staged_payers }} -->
</b-modal>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from '../../common.js'
import PayerMultiselect from '../multiselects/PayerMultiselect.vue'

export default {
  name: 'PracticeFeeEditPayers',
  components:{
    PayerMultiselect,
  },
  mixins: [ alertsMixin ],
  props: ['year', 'practiceId', 'codeInfo'],
  data: function(){
      return {
            practice_id: this.practiceId,
            cpt_code: this.codeInfo.cpt_code,

            code: {
                practice_id: this.practiceId,
                year: this.year,
                short: this.codeInfo.short,
                cpt_code: this.codeInfo.cpt_code,
                modifiers: this.codeInfo.modifiers,
                pos: this.codeInfo.pos,
                tos: this.codeInfo.tos,
            },

            payer: null,
            selectedPayer: null,

            old_payers: [],

            staged_payers: [],

            new_payers: [{  payer_object: null,
                        charge_fee: null, 
                        contracted_fee: null, 
                        allowed_amount: null, 
                        charge_fee_surgery_center: null, 
                        contracted_fee_surgery_center: null, 
                        allowed_amount_surgery_center: null, }],

            show_surgery_center_fields: false,

            payersOptions: [],

            payerRatesLoaded: false,
            
            selectedFee: null,
            selectedFeeIndex: null,
      }
  },
  methods: {
    clearData(){
        this.old_payers = [];
        
        this.staged_payers = [];
        
        this.new_payers = [{ payer_object: null,
                    charge_fee: null, 
                    contracted_fee: null, 
                    allowed_amount: null, 
                    charge_fee_surgery_center: null, 
                    contracted_fee_surgery_center: null, 
                    allowed_amount_surgery_center: null, }];
        
        this.hideError();
    },
    validateInputs(){
        this.hideError();

        for (var i = 0; i < this.staged_payers.length; i++){
            if (this.staged_payers[i].payer_object == null){
                this.showError("Please remove empty payers")
                return false
            }
        }
        
        return true
    },
    addNewPayer(){
        const newPayer = {  payer_object: null,
                            charge_fee: null, 
                            contracted_fee: null, 
                            allowed_amount: null, 
                            charge_fee_surgery_center: null, 
                            contracted_fee_surgery_center: null, 
                            allowed_amount_surgery_center: null, }
        this.new_payers.push(newPayer);
    },
    stageNewPayers(){
        this.staged_payers = this.staged_payers.concat(this.new_payers);
        this.new_payers = [{ 
            payer_object: null,
            charge_fee: null, 
            contracted_fee: null, 
            allowed_amount: null, 
            charge_fee_surgery_center: null, 
            contracted_fee_surgery_center: null, 
            allowed_amount_surgery_center: null, }];
        this.$bvModal.hide('new-payers-modal');
    },
    removeNewPayerRow(index){
        this.new_payers.splice(index, 1);
    },
    removePayerRow(index){
        this.staged_payers.splice(index, 1);
    },
    viewEditPayerRatesModal(payer, index) {
        this.selectedPayer = payer;
        this.payer = {...this.selectedPayer};
        this.selectedPayerIndex = index;
        this.$nextTick().then(()=>{this.$bvModal.show('edit-payer-rates');});
    },
    hideEditPayerRatesModal() {
        this.$bvModal.hide('edit-payer-rates');
    },
    viewDeletePayerRatesModal(index) {
        this.$bvModal.show('remove-payer-rates-modal-'+index);
    },
    hideDeletePayerRatesModal(index) {
        this.$bvModal.hide('remove-payer-rates-modal-'+index);
    },
    deletePayerRates(index, payer_id, cpt_code, year) {
        const vm = this;
        authenticated_request({
            method: "delete",
            url: make_url("/practices/" + vm.practice_id + "/fees/payers/" + payer_id +"/codes/" + cpt_code),
            data: { "year": year }
        }).then(function(){
            vm.getPayerFees();
            vm.hideDeletePayerRatesModal(index);
        });
    },
    updatePayerRates(){
        const vm = this;
        const data = { 
            'modifiers': vm.payer.modifiers,
            'pos': vm.payer.pos,
            'tos': vm.payer.tos,
            'year': vm.payer.year,
            'allowed_amount': vm.payer.allowed_amount || null,
            'charge_fee': vm.payer.charge_fee || null,
            'contracted_fee': vm.payer.contracted_fee || null,
        };
        const surgery_center_data = {
            'allowed_amount_surgery_center': vm.payer.allowed_amount_surgery_center || null,
            'charge_fee_surgery_center': vm.payer.charge_fee_surgery_center || null,
            'contracted_fee_surgery_center': vm.payer.contracted_fee_surgery_center || null,
        };
        authenticated_request({
            method: "put",
            url: make_url("/practices/" + vm.practice_id + "/payers/" + vm.payer.payer_id + "/codes/" + vm.payer.cpt_code + "/fees"),
            data: vm.show_surgery_center_fields ? {...data, ...surgery_center_data} : data,
        }).then(function(){
            vm.getPayerFees();
            vm.hideEditPayerRatesModal();
        });
    },
    formatModifiers(modifiers_array){
        const modifier_filter = (code_string) => { return (code_string && code_string.length == 2) };
        const distinct_filter = (value, index, self) => { return self.indexOf(value) === index; }
        const modifier_formatter = (code_string) => { return code_string.toUpperCase() };
        return modifiers_array.filter(modifier_filter)
                                .map(modifier_formatter)
                                .filter(distinct_filter)
                                .sort().join(",")
    },
    onSubmit(){
        if (this.validateInputs()){
            const vm = this;
            vm.payerRatesLoaded = false;
            const data = {  code_info: {
                                        cpt_code: this.code.cpt_code,
                                        modifiers: this.code.modifiers,
                                        pos: this.code.pos,
                                        tos: this.code.tos,
                                        year: this.code.year,
                                        }, 
                            payer_fees: this.staged_payers.map((value) => { return {
                                            payer_id: value.payer_object.payer_id,
                                            cpt_code: this.code.cpt_code,
                                            modifiers: this.code.modifiers,
                                            pos: this.code.pos,
                                            tos: this.code.tos,
                                            year: this.code.year,
                                            charge_fee: value.charge_fee || null,
                                            contracted_fee: value.contracted_fee || null,
                                            allowed_amount: value.allowed_amount || null,
                                            charge_fee_surgery_center: value.charge_fee_surgery_center || null,
                                            contracted_fee_surgery_center: value.contracted_fee_surgery_center || null,
                                            allowed_amount_surgery_center: value.allowed_amount_surgery_center || null,
                                            };}),
                         }   
            
                console.log(data);
                authenticated_request({
                    method: 'POST',
                    url: make_url("/practices/" + this.practice_id + "/fees/payers/multiadd"),
                    data: data,
                }).then(()=>{
                    vm.clearData();
                    vm.getPayerFees();
                }).finally(()=>{
                    vm.$emit('new-data-added');
                });
        }
    },
    onModalHidden(){
        this.$emit('modal-hidden');
    },
    resetModal(){
        this.hideError();
        this.clearData();
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
    hideModal(){
        this.$bvModal.hide('edit-code-payers');
    },
    codeWithDescription(code){
        if (code.code && code.short){
            return (code.code + ": " + code.short)
        }
        return "Code/description missing"
    },
    getPayerFees(){
        const vm = this;

        // get list of payer fees already set

        var params = {  'modifiers': this.code.modifiers,
                        'pos': this.code.pos,
                        'tos': this.code.tos,
                        'year': this.code.year};
        params.per_page = 20;
        params.page = 1;

        authenticated_request({
            method: "get",
            url: make_url("/practices/" + this.practice_id + "/codes/" + this.cpt_code + "/fees").toString(),
            params: params
        }).then(function(response){
            vm.old_payers = response.data.payer_fees;
        }).finally(()=>{
            vm.payerRatesLoaded = true;
        });

    },
  },
    created(){
        const vm = this;

        this.getPayerFees();
    }
}
</script>

<style scoped>
</style>