<template>
  <b-overlay variant="transparent" :show="loading">
    <b-card class="avo-info-card">
      <div class="d-flex justify-content-between align-items-center">
        <div class="w-50">
          <b-row>
            <b-col cols="6" class="label-text pr-0"> Claim Amount: </b-col>
            <b-col class="data-text">
              ${{ formatDecimal(claim.invoiced) }}
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="6" class="label-text pr-0"> Payor: </b-col>
            <b-col class="data-text">
              ${{ formatDecimal(payment_data.payor_paid) }}
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="6" class="label-text pr-0"> Patient: </b-col>
            <b-col class="data-text">
              ${{ formatDecimal(payment_data.patient_paid) }}
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="6" class="label-text pr-0"> Adjustments: </b-col>
            <b-col class="data-text">
              ${{ formatDecimal(claim.adjustments) }}
            </b-col>
          </b-row>
        </div>
        <div class="d-flex flex-column justify-content-center">
          <div class="label-text">Balance</div>
          <div class="total-amount-text">
            ${{ formatDecimal(claim.balance) }}
          </div>
        </div>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import { authenticated_request, formatDecimal, make_url } from "../../common";

export default {
  name: "ClaimBalanceCard",
  props: {
    claim: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      payment_data: {
        patient_paid: null,
        payor_paid: null,
      },
      loading: true,
    };
  },
  methods: {
    formatDecimal,
    clearPaymentData() {
      this.payment_data = {
        patient_paid: null,
        payor_paid: null,
      };
    },
    getPaymentData() {
      this.loading = true;
      const url = make_url(
        `/reports/claims/${this.claim_id}/payments/payor_and_patient`
      );
      const vm = this;
      authenticated_request({
        url: url,
        method: "GET",
      })
        .then((response) => {
          vm.payment_data = response.data;
        })
        .finally(() => {
          vm.loading = false;
        });
    },
  },
  computed: {
    claim_id: function () {
      return this?.claim?.claim_id;
    },
  },
  watch: {
    claim: {
      handler(newValue) {
        this.clearPaymentData();
        if (newValue) {
          this.getPaymentData();
        }
      },
      immediate: true,
    }
  },
};
</script>