<template>
<b-modal :id="modalId" @hide="resetModal" title="New claim payment" scrollable hide-footer size="lg">
<b-form @submit="onSubmitEdit">
    <b-row class="px-2">
        <b-col cols="4">
            <label class="selecter-label">Received From</label>
            <b-input-group>
                <multiselect
                    :disable="insurancesLoading"
                    openDirection="bottom"
                    placeholder="Who has responsibility"
                    label="name"
                    v-model="receivedFrom"
                    :options="receivedFromOptions"
                    :internal-search="false"
                    track-by="payer_id"
                    :show-labels="false"
                    :showNoOptions="false"
                    :allow-empty="true">
                <template slot="option" slot-scope="props">
                <div v-if="props.option.payer_id">
                    {{props.option.name}} <span class="avo-text-light" v-if="props.option.primary_insurance"> -- Primary</span>
                </div>
                <div v-else style="border-top:1px solid #E2E4EB;margin:-12px -12px 0 -12px;">
                    <div style="padding:12px 12px 0 12px;">Patient</div>
                </div>
                </template>
                </multiselect>
            </b-input-group>
        </b-col>

        <b-col cols="4">
            <label class="selecter-label">Payment Type</label>
            <b-form-select
                v-if="payment.payment_source == 1"
                v-model="payment.patient_payment_type"
                :options="paymentOptionsMap.patient_payment_types"
                :state="fieldStates('patient_payment_type')"
                required>
                <template #first>
                <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                </template>
            </b-form-select>
            <b-form-select
                v-else-if="payment.payment_source == 2"
                v-model="payment.payer_payment_type"
                :options="paymentOptionsMap.payer_payment_types"
                :state="fieldStates('payer_payment_type')"
                required>
                <template #first>
                <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                </template>
            </b-form-select>
            <b-form-select
                v-else
                disabled>
            </b-form-select>
        </b-col>

        <b-col cols="4">
            <label class="selecter-label">Payment Method</label>
            <b-form-select
                v-if="payment.payment_source == 1"
                v-model="payment.patient_payment_method"
                :options="paymentOptionsMap.patient_payment_methods"
                :state="fieldStates('patient_payment_method')"
                required>
                <template #first>
                <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                </template>
            </b-form-select>
            <b-form-select
                v-else-if="payment.payment_source == 2"
                v-model="payment.payer_payment_method"
                :options="paymentOptionsMap.payer_payment_methods"
                :state="fieldStates('payer_payment_method')"
                required>
                <template #first>
                <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                </template>
            </b-form-select>
            <b-form-select
                v-else
                disabled>
            </b-form-select>
        </b-col>
    </b-row>
    <b-row class="mb-4 px-2">
        <b-col cols="4" class="">
            <label for="payment-date" class="selecter-label">Date</label>
            <b-form-input type="date"
                id="payment-date"
                :state="fieldStates('payment_date')"
                v-model="payment.payment_date"
                placeholder=""
                value-as-datetime
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" />
        </b-col>
        
        <b-col cols="4" class="">
            <label for="payment-invoice-no" class="selecter-label">Invoice Number</label>
            <b-form-input id="payment-invoice-no" :state="fieldStates('invoice_no')" v-model="payment.invoice_no"></b-form-input>
        </b-col>
        <b-col cols="4" class="text-right">
            <label for="payment-amount" class="selecter-label">Total Amount</label>
            <b-input-group prepend="$">
                <b-form-input
                    class="text-right"
                    type="number"
                    id="payment-amount"
                    placeholder="Start typing a total payment amount"
                    :state="fieldStates('amount')"
                    disabled
                    v-model="payment.amount">
                </b-form-input>
                <b-form-invalid-feedback>{{fieldErrMessages('amount')}}</b-form-invalid-feedback>
            </b-input-group>            
        </b-col>
    </b-row>
    <b-row v-if="payment.payment_source" class="selecter-label">
        <b-col cols="6">
            <b-row>
                <b-col cols="2">
                    Code
                </b-col>
                <b-col cols="1" class="p-0"> 
                    Units
                </b-col>
                <b-col cols="3" class="text-right">
                    Billed
                </b-col>
                <b-col cols="3" class="pl-1 text-right">
                    Paid
                </b-col>
                <b-col cols="3" class="pl-1 text-right">
                    Allowed
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="6">
            <b-row>
                <b-col cols="3">
                    Adjustments
                </b-col>
                <b-col cols="3" class="pl-1">
                </b-col>
                <b-col cols="3" class="pl-1">
                </b-col>
                <b-col cols="3" class="pl-1">
                    Comment
                </b-col>
            </b-row>
        </b-col>
    </b-row>
    <div v-if="payment.payment_source">
        <b-row v-for="(code) in codePayments" :key="code.claim_code_id" class="payment-row avo-text-light">
            <b-col cols="6" class="">
                <b-row>
                    <b-col cols="2" class="d-flex flex-column">
                        <div>{{code.code}}</div>
                        <div class="d-flex flex-wrap">
                            <div v-for="(modifier, index) in code.modifiers" :key="index" class="modifier-badge">
                                {{modifier}}
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="1" class="p-0"> 
                        <div class="fake-input text-right">
                            {{code.units}}
                        </div>
                    </b-col>
                    <b-col cols="3" class="pl-3">
                        <div class="fake-input text-right">
                            ${{Number(((code.unit_charge || 0) * (code.units || 0))).toFixed(2)}}
                        </div>
                        <div class="d-flex justify-content-end mt-1">
                            <div v-if="payment.payment_source == 1" class="avo-text-light-small">Patient owes: ${{Number((code.patient_responsibility || 0)).toFixed(0)}}</div>
                            <div v-else-if="payment.payment_source == 2" class="avo-text-light-small">Payer owes: ${{Number((code.payer_responsibility || 0)).toFixed(0)}}</div>
                        </div>
                    </b-col>
                    <b-col cols="3" class="pl-1">
                        <b-input-group prepend="$">
                            <b-form-input
                            type="number"
                            class="text-right"
                            v-model="code.amount"
                            placeholder="Type amount"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="3" class="pl-1">
                        <b-input-group prepend="$">
                            <b-form-input
                                type="number"
                                class="text-right"
                                v-model="code.allowed_amount"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="6" class="adjustment-col">
                <b-row>
                    <b-col cols="3">
                        <b-form-select
                            v-model="code.adjustments[0].group_code"
                            required>
                            <template #first>
                            <b-form-select-option :value="null" disabled>Group</b-form-select-option>
                            </template>
                            <option
                                v-for="(option, idx) in paymentOptionsMap.adjustment_group_codes_short"
                                :key="idx"
                                :value="idx">
                                {{option}} - {{paymentOptionsMap.adjustment_group_codes[idx]}}
                            </option>
                        ></b-form-select>
                    </b-col>
                    <b-col cols="3" class="pl-1">
                        <b-form-input
                            v-model="code.adjustments[0].reason_code"
                            placeholder="Reason code"></b-form-input>
                    </b-col>
                    <b-col cols="3" class="pl-1">
                        <b-input-group prepend="$">
                            <b-form-input
                            type="number"
                            v-model="code.adjustments[0].amount"
                            class="text-right"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="3" class="pl-1">
                        <b-form-input
                            v-model="code.adjustments[0].remark_code"
                            placeholder="Remark code"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-collapse :id="'collapse'+code.claim_code_id" v-model="code.adjDropdownVisible" >
                            <b-row class="mt-1">
                                <b-col cols="3">
                                    <b-form-select
                                        v-model="code.adjustments[1].group_code"
                                        required>
                                        <template #first>
                                        <b-form-select-option :value="null" disabled>Group</b-form-select-option>
                                        </template>
                                        <option
                                            v-for="(option, idx) in paymentOptionsMap.adjustment_group_codes_short"
                                            :key="idx"
                                            :value="idx">
                                            {{option}} - {{paymentOptionsMap.adjustment_group_codes[idx]}}
                                        </option>
                                    ></b-form-select>
                                </b-col>
                                <b-col cols="3" class="pl-1">
                                    <b-form-input
                                        v-model="code.adjustments[1].reason_code"
                                        placeholder="Reason code"></b-form-input>
                                </b-col>
                                <b-col cols="3" class="pl-1">
                                    <b-input-group prepend="$">
                                        <b-form-input
                                            type="number"
                                            v-model="code.adjustments[1].amount"
                                            class="text-right"></b-form-input>
                                    </b-input-group>
                                </b-col>
                                <b-col cols="3" class="pl-1">
                                    <b-form-input
                                        v-model="code.adjustments[1].remark_code"
                                        placeholder="Remark code"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="mt-1">
                                <b-col cols="3">
                                    <b-form-select
                                        v-model="code.adjustments[2].group_code"
                                        required>
                                        <template #first>
                                        <b-form-select-option :value="null" disabled>Group</b-form-select-option>
                                        </template>
                                        <option
                                            v-for="(option, idx) in paymentOptionsMap.adjustment_group_codes_short"
                                            :key="idx"
                                            :value="idx">
                                            {{option}} - {{paymentOptionsMap.adjustment_group_codes[idx]}}
                                        </option>
                                    ></b-form-select>
                                </b-col>
                                <b-col cols="3" class="pl-1">
                                    <b-form-input
                                        v-model="code.adjustments[2].reason_code"
                                        placeholder="Reason code"></b-form-input>
                                </b-col>
                                <b-col cols="3" class="pl-1">
                                    <b-input-group prepend="$">
                                        <b-form-input
                                            type="number"
                                            v-model="code.adjustments[2].amount"
                                            class="text-right"></b-form-input>
                                    </b-input-group>
                                </b-col>
                                <b-col cols="3" class="pl-1">
                                    <b-form-input
                                        v-model="code.adjustments[2].remark_code"
                                        placeholder="Remark code"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-collapse>
                        <b-button
                            :aria-expanded="code.adjDropdownVisible ? 'true' : 'false'"
                            :aria-controls="['collapse'+code.claim_code_id]"
                            @click="code.adjDropdownVisible = !code.adjDropdownVisible"
                            variant="outline-primary"
                            class="add-adjustments-btn mt-1">{{code.adjDropdownVisible ? 'Hide adjustments' : 'Show more adjustments'}}</b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
    <b-row class="mt-4">
        <b-col style="text-align:right">
            <b-button @click="onSubmitEdit" class="avo-primary-btn">
                Save payment
            </b-button>
        </b-col>
    </b-row>
</b-form>
</b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { make_url, authenticated_request } from '../../common.js'
export default {
  name: 'ClaimPaymentEdit',
  props: ['claimId', 'payerId', 'patientId', 'eraList', 'codesData', 'modalId'],
  components: {
      Multiselect
  },
  data: function(){
      return {
            payment: {
                invoice_no: null,
                // batch_no: null,
                amount: null,
                payment_source: null,
                patient_payment_type: null,
                patient_payment_method: null,
                payer_payment_type: null,
                payer_payment_method: null,
                payment_date: null,
                payer_id: this.payerId,
                patient_id: this.patientId,
            },
            adjustmentProto: {
                group_code: null,
                reason_code: null,
                remark_code: null,
                amount: null,
            },
            payment_validator: {
                payment_source: null,
                patient_payment_type: null,
                patient_payment_method: null,
                payer_payment_type: null,
                payer_payment_method: null,
                invoice_no: null,
                // batch_no: null,
                amount: null,
                payment_date: null,
            },
            paymentOptionsMap: [],
            codePayments: [],


            patientOption: {'payer_id': null, 'patient_id': this.patientId, 'name': 'Patient'},
            receivedFromOptions: [],
            receivedFrom: null,

            insurancesLoading: false,
            era: null,
            eras: [],
      }
  },
  methods: {
    setValidator(payload){
        this.payment_validator = {
            payment_source: !this.payment.payment_source ? "required" : null,
            invoice_no: !this.payment.invoice_no ? "required" : null,
            amount: !this.payment.amount ? " " : this.payment.amount == 0 ? ["Enter one or more code payments below"] : (payload && 'amount' in payload ? payload['amount']: null),
            payment_date: !this.payment.payment_date ? "required" : null,
        }
    },
    validate() {
        if(this.payment_validator.payment_source !== null){
            return false;
        }
        for(const field in this.payment_validator){
            if(this.payment_validator[field]){
                return false;
            }
        } return true;
    },
    fieldStates(field) {
        return this.payment_validator[field] ? false : null;
    },
    fieldErrMessages(field) {
        return this.payment_validator[field] ? this.payment_validator[field][0] : null;
    },
    editPayment(){
        var params = JSON.parse(JSON.stringify(this.payment))
        if (this.payment.payment_date){
            params.payment_date = this.payment.payment_date + "T00:00:00+00:00"
        }
        if (this.getFromPayerId){
            params.payer_id = this.getFromPayerId
        }
        delete params.payer_name;

        params.code_payments = this.codePayments.map(payment => ({
            code_payment_id: payment.code_payment_id,
            claim_code_id: payment.claim_code_id,
            amount: payment.amount,
            allowed_amount: payment.allowed_amount,
            adjustments: payment.adjustments
        }))

        const url = make_url("/claims/" + this.claimId + "/payments/codes/adjustments");
        const self = this;
        const promise = authenticated_request({
            method: "put",
            url: url,
            data: params
        }).then(function(response){
            if (response){
                console.log(response)
                self.$bvModal.hide(self.modalId);
                self.$store.commit("setUpdateBilling", true);
                self.$store.commit("setUpdatePayments", true);
                self.$store.commit("refreshActivityLog");
            }
        }).catch(function(error){
            console.log(error.response)
            self.handleErrors(error);
        });
        return promise;
    },
    onSubmitEdit(){
        this.setValidator()
        console.log(this.validate())
        if (this.validate()){
            this.editPayment();
        }
    },
    handleErrors(error) {
        if (error.response.status == 400) {
            // handles field validation
            if ("payload" in error.response.data) {
                const payload = error.response.data.payload;
                this.setValidator(payload);
            }
        }
    },
    clearValidator(){
        this.payment_validator = {
            payment_source: null,
            patient_payment_type: null,
            patient_payment_method: null,
            payer_payment_type: null,
            payer_payment_method: null,
            invoice_no: null,
            amount: null,
            payment_date: null,
        }
    },
    resetModal(){
        this.era = null;
        this.receivedFrom = null;
        this.insurancesLoading = false;
        this.payment = {
            payment_source: null,
            patient_payment_type: null,
            patient_payment_method: null,
            payer_payment_type: null,
            payer_payment_method: null,
            invoice_no: null,
            amount: null,
            payment_date: null,
            payer_id: this.payerId,
            patient_id: this.patientId,
        }
        this.$emit('clear-edit-data');
        this.clearValidator();
    },
    getPatientInsurances: function() {
        this.insurancesLoading = false;
        const url = make_url("/patients/reports/" + this.claim.patient_id + "/insurance");
        const self = this;
        const promise = authenticated_request({
            method: "get",
            url: url
        }).then(function(response){
            var insurances = response.data.insurance;
            var primary_ins = [];

            // move the primary insurance to the top of the options list
            primary_ins = insurances.splice(insurances.findIndex(item => item.primary_insurance == "True"))
            if(primary_ins.length > 0){
                insurances.unshift(primary_ins[0])
            }
            
            // see if the insurance attached to this payment is already an option for the multiselect; if not, add it
            var existingPayerOption = insurances.find(x => x.payer_id === self.payment.payer_id);
            if(!existingPayerOption && self.payment.payment_source == 2){
                insurances.push({'payer_id': self.payment.payer_id, 'name': self.payment.payer_name })
            }

            // see if the insurance attached to this claim is already an option for the multiselect; if not, add it
            var claimPayerOption = insurances.find(x => x.payer_id === self.claim.payer_id);
            if(!claimPayerOption){
                insurances.push({'payer_id': self.claim.payer_id, 'name': self.claim.payer_name })
            }

            // add the patient option to the end of options list
            insurances.push(self.patientOption);
            self.receivedFromOptions = insurances;

            // set the existing/starting received_from data prop (v-model for the multiselect)
            if(self.payment.payment_source && self.payment.payment_source == 2){
                self.receivedFrom = self.receivedFromOptions.find(x => x.payer_id === self.payment.payer_id);
            } else if (self.payment.payment_source && self.payment.payment_source == 1){
                self.receivedFrom = self.patientOption;
            }

            return insurances;
        }).catch(function(error){
            self.handleErrors(error);
        }).finally(()=>{
            self.insurancesLoading = false;
        });
        return promise;
    },
  },
  computed: {
    claim() {
        return this.$store.state.claims.workedClaim;
    },
    getFromPayerId(){
        return this.receivedFrom ? this.receivedFrom.payer_id : null
    },
    codePaymentAmounts() {
      return this.codePayments.map(form => form.amount)
    },

  },
  watch: {
    codesData(newValue){
        if(newValue){
            var copiedData = JSON.parse(JSON.stringify(newValue))
            var code_payments = copiedData['code_payments'];
            this.codePayments = code_payments;
            delete copiedData['code_payments'];

            this.payment.payment_id = copiedData.payment_id;
            this.payment.amount = copiedData.amount;
            this.payment.invoice_no = copiedData.invoice_no;
            this.payment.patient_payment_type = copiedData.patient_payment_type;
            this.payment.patient_payment_method = copiedData.patient_payment_method;
            this.payment.payer_payment_type = copiedData.payer_payment_type;
            this.payment.payer_payment_method = copiedData.payer_payment_method;
            this.payment.payment_date = copiedData.payment_date.split('T')[0]; 
            
            // initial payments data sometimes lacks patient ids; using the one passed as a prop instead;
            // this.payment.patient_id = newValue.patient_id;
            this.payment.payer_id = copiedData.payer_id;
            this.payment.payer_name = copiedData.payer_name;
            this.payment.payment_source = copiedData.payment_source;
            
            this.getPatientInsurances()
            
            // populate empty adjustments for each code payment
            var vm = this;
            code_payments.forEach(function (codePayment) {
                const numAdjustments = codePayment.adjustments ? codePayment.adjustments.length : 0;
                for(let i = 0; i < 3 - numAdjustments; i++){
                    const emptyAdjustment = JSON.parse(JSON.stringify(vm.adjustmentProto));
                    codePayment.adjustments.push(emptyAdjustment)
                }
            })
        }
    },
    receivedFrom(newValue){
        if(newValue){
            if(newValue.payer_id){
                this.payment.payment_source = 2;
            } else {
                this.payment.payment_source = 1;
            }
        } 
    },
 
    codePaymentAmounts(){
        this.payment.amount = this.codePayments.reduce(function(prev, cur) {
            var temp = parseFloat(prev || 0) + parseFloat(cur.amount || 0);
            return temp.toFixed(2);
        }, 0);
    },
  },
  mounted() {
    //   this.getPatientInsurances()
  },
  beforeMount: function() {
      var self = this;
      authenticated_request({
          method: "get",
          url: make_url("/lookup/claims/payments/types")
      }).then(function(response){
          self.paymentOptionsMap = response.data
      }).catch(function(errors){
          console.log(errors);
      });
  }
}
</script>

<style scoped>
::v-deep .modal-lg{
    max-width: 1200px;
}
::v-deep .modal-body{
    padding: 1rem 3rem 2rem 3rem;
}

/* styling the nav pill filter group */
::v-deep .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: var(--blue);
}
::v-deep a {
    color: var(--blue);
    font-size: 12px;
}
::v-deep .nav-link:hover {
    background-color: #EEF1FA;
}

.payment-row {
    border-bottom:2px solid #E2E4EB;
    padding-bottom: 5px;
    margin-bottom: 10px;
}
::v-deep .payment-row .form-control,
::v-deep .payment-row .custom-select,
::v-deep .payment-row .input-group-text {
    font-size: 14px;
}
::v-deep .payment-row .form-control {
    padding: 0.375rem 0.5rem 0.375rem 0.375rem;
}
::v-deep .payment-row .input-group-text {
    padding: 0.375rem 0.5rem;
}

.adjustment-col {
    margin:-7px 0 -7px 0;
    margin-bottom:3px;
    padding:7px 15px;
    /* background-color: #EEF1FA; */
    background: transparent linear-gradient(101deg, #F2F6FC 0%, #E6EEF9 100%) 0% 0% no-repeat padding-box;
    border-radius: 15px;
}
::v-deep .add-adjustments-btn,
::v-deep .add-adjustments-btn:focus  {
    height:23px !important;
    width:100% !important;
    box-shadow: none !important;
    border: 2px solid #7374E5 !important;
    border-radius: 5px !important;
    background: white !important;
    color: #7374E5 !important;
    font-size: 12px !important;
    padding: 0;
}
::v-deep .add-adjustments-btn:hover{
    background: #F1F1FC 0% 0% no-repeat padding-box !important;
    color: #7374E5 !important;
    border: 2px solid #7374E5 !important;
}
::v-deep .add-adjustments-btn:active{
    border: none !important;
    background: #7374E5 0% 0% no-repeat padding-box !important;
    color: white !important;
}

.no-codes {
  font-size: 16px;
  font-weight: 200;
  text-align: center;
}
.modifier-badge {
    display:flex;
    justify-content: center;
    align-items: center;
    color: var(--gray);
    font-size: 11px;
    border-radius: 5px;
    background-color: #E6EEF9;
    margin-right:4px;
    margin-top:4px;
    padding: 3px 5px;
}
.fake-input {
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #F7F8FA;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

/* Removes b-form-input number type arrows on Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Removes b-form-input number type arrows on Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.input-group > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}


</style> 