<template>
<div>
    <b-row align-v="center" align-h="between" class="mb-4">
        <b-col sm="12" md="7" lg="6" class="d-flex align-items-center">
            <b-input-group class="search-group">
                <b-input-group-prepend>
                    <span class="input-group-text"><b-icon icon="search"></b-icon></span>
                </b-input-group-prepend>
                <b-form-input
                    @keyup="debouncePayments"
                    @search="debouncePayments"
                    class="payment-search-input pl-1"
                    type="search" v-model="search.terms"
                    placeholder="Try typing a Payment ID, Invoice # or Payer"/>                
                <b-input-group-append >
                    <span class="divider"></span>
                    <span class="divider"></span>
                    <b-dropdown
                        class="dropdown filter-dropdown"
                        :boundary="'window'"
                        ref='dropdown'>
                        <template #button-content >
                        <b-icon-funnel class="mr-2" />{{filterInputLabel}}
                        </template>
                        <b-dropdown-form class="d-flex flex-column align-items-start">
                        <span style="font-size:20px;font-weight:300">Filters</span>

                        <div class="selecter-label">Payment Date After</div>
                        <b-form-input type="date"
                            ref="date1"
                            value-as-date
                            locale="en"
                            class="date-input"
                            v-model="search.start_date">
                        </b-form-input>

                        <div class="selecter-label">Payment Date Before</div>
                        <b-form-input type="date"
                            ref="date2"
                            value-as-date
                            locale="en"
                            class="date-input"
                            v-model="search.end_date">
                        </b-form-input>

                        <div class="selecter-label">CPT Code</div>
                        <b-input-group>
                            <multiselect
                                openDirection="bottom"
                                placeholder="Search by code or description" 
                                label="code"
                                :custom-label="codeWithDescription"
                                v-model="selectedCodes"
                                track-by="code"
                                :options="selectableCPTCodes"
                                :multiple="true"
                                :close-on-select="false"
                                :loading="cptLoading"
                                :internal-search="false"
                                :show-labels="false"
                                @search-change="debounceCPTCodes"
                                :showNoOptions="true">
                                <template slot="noResult">
                                    No codes matched your search
                                </template>
                                <template slot="noOptions">
                                    Start typing a code or description...
                                </template>
                            </multiselect>
                        </b-input-group>
                        
                        <div class="d-flex justify-content-end mt-4">
                            <b-button @click="clearFilters()" variant="outline-primary" class="avo-basic-btn mr-2">Clear Filters</b-button>
                            <b-button @click="getPayments()" variant="outline-primary" class="avo-primary-btn">Apply</b-button>
                        </div>
                        </b-dropdown-form>
                    </b-dropdown>
                </b-input-group-append>
            </b-input-group>
        </b-col>
    </b-row>
    <b-overlay :show="paymentsLoading" variant="white" style="min-height:200px;">
    <div v-if="payments.length==0 && !paymentsLoading" class="no-payments pt-4">
        No payments to display
    </div>
    <b-row v-for="(payment, index) in payments" :key="payment.payment_id">
        <!-- <b-col v-if="payment.code_payments && payment.code_payments.length > 0"> -->
        <b-col>
            <b-card class="payment-card mb-3 shadow">
                <b-row>
                    <b-col cols="4">
                        <b-row class="mb-3">
                            <b-col cols="4">
                                <div class="avo-text-light-small">Date</div>
                                <div class="data-text">{{formatDateShort(payment.payment_date)}}</div>
                            </b-col>
                            <b-col cols="4">
                                <div class="avo-text-light-small">Received from</div>
                                <div class="data-text">{{payment.payment_source == 2 ? payment.payer_name : 'Patient'}}</div>
                            </b-col>
                            <b-col cols="4">
                                <div class="avo-text-light-small">Total paid</div>
                                <div class="data-text">${{getTotalPaid(index).toFixed(2)}}</div>
                                
                            </b-col>
                        </b-row>
                        <b-row class="mb-3">
                            <b-col cols="4">
                                <div class="avo-text-light-small">Payment type</div>
                                <div class="data-text">{{payment.payment_source == 2 ? optionMaps.payer_payment_types[payment.payer_payment_type] : optionMaps.patient_payment_types[payment.patient_payment_type] }}</div>
                                
                            </b-col>
                            <b-col cols="4">
                                <div class="avo-text-light-small">Payment method</div>
                                <div class="data-text">{{payment.payment_source == 2 ? optionMaps.payer_payment_methods[payment.payer_payment_method] : optionMaps.patient_payment_methods[payment.patient_payment_method] }}</div>
                                
                            </b-col>
                            <b-col cols="4">
                                <div class="avo-text-light-small">Total allowed</div>
                                <div class="data-text">${{getTotalAllowed(index).toFixed(2)}}</div>
                            </b-col>
                        </b-row>
                        <b-row >
                            <b-col cols="4">
                                <div class="avo-text-light-small">Payment ID</div>
                                <div class="data-text">#{{payment.payment_id}}</div>
                            </b-col>
                            <b-col cols="4">
                                <div class="avo-text-light-small">Invoice No</div>
                                <div class="data-text">{{payment.invoice_no}}</div>
                            </b-col>
                            <b-col cols="4">
                                <div class="avo-text-light-small">Total adjustments</div>
                                <div class="data-text">${{getTotalAdjustments(index).toFixed(2)}}</div>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="8" class="d-flex justify-content-between pr-0">
                        <div class="code-payment-box p-3">
                            <b-row class="mb-2">
                                <b-col cols="2">
                                    <b-row>
                                        <b-col>
                                            <div class="avo-text-light-small">CODES</div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="6">
                                    <b-row>
                                        <b-col cols="3" class="text-center">
                                            <div class="avo-text-light-small">UNITS</div>
                                        </b-col>
                                        <b-col cols="3" class="text-right">
                                            <div class="avo-text-light-small">BILLED</div>
                                        </b-col>
                                        <b-col cols="3" class="text-right">
                                            <div class="avo-text-light-small">PAID</div>
                                        </b-col>
                                        <b-col cols="3" class="text-right">
                                            <div class="avo-text-light-small">ALLOWED</div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="4">
                                    <b-row>
                                        <b-col>
                                            <div class="avo-text-light-small">ADJUSTMENTS</div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <b-row v-for="(codePayment, index) in payment.code_payments" :key="index" class="data-text-small mb-1">
                                <b-col cols="2">
                                    <b-row>
                                        <b-col class="d-flex align-items-center">
                                            <div class="mr-1">{{codePayment.code}}</div>
                                            <div v-for="(modifier, index) in codePayment.modifiers" :key="index" class="modifier-badge">
                                                {{modifier}}
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="6">
                                    <b-row>
                                        <b-col cols="3" class="text-center">
                                            {{codePayment.units}}
                                        </b-col>
                                        <b-col cols="3" class="text-right">
                                            ${{Number(((codePayment.unit_charge || 0) * (codePayment.units || 0))).toFixed(2)}}
                                        </b-col>
                                        <b-col cols="3" class="text-right">
                                            ${{codePayment.amount}}
                                        </b-col>
                                        <b-col cols="3" class="text-right">
                                            ${{Number(codePayment.allowed_amount || 0).toFixed(2)}}
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="4">
                                    <b-row>
                                        <b-col>
                                            <div class="d-flex flex-wrap">
                                                <div v-for="(adjustment, index) in codePayment.adjustments" :key="index" class="mr-2">
                                                    <span v-if="adjustment.adjustment_id">
                                                        {{optionMaps.adjustment_group_codes_short[adjustment.group_code]}}-{{adjustment.reason_code}} ${{adjustment.amount}}
                                                        <span v-if="adjustment.remark_code">({{adjustment.remark_code}})</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </div>
                        <b-dropdown class="avo-action-dropdown" no-caret style="min-width:none" :right="true">
                            <template #button-content class="">
                                <b-icon icon="three-dots-vertical" role="button" scale="1.15"></b-icon>
                            </template>
                            <b-dropdown-item @click="showEditPaymentModal(index, payment)">Edit payment</b-dropdown-item>
                            <!-- <b-dropdown-item @click="deletePayment(index)">Delete payment</b-dropdown-item> -->
                        </b-dropdown>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
    </b-overlay>
    <claim-payment-edit :claim-id="claim.claim_id" :payer-id="claim.payer_id" :patient-id="claim.patient_id" :codesData="editPaymentData" :modalId="editModalId" @submitted="getCodes()" @clear-edit-data="clearEditData()" />  
</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { make_url, authenticated_request, formatDateShort, debounce } from '../../common.js'
import ClaimPaymentEdit from '../claims/ClaimPaymentEdit.vue'

export default {
  name: 'ItemizedPayments',
  props: ['source'],
  components: {
      Multiselect,
      ClaimPaymentEdit,
  },
  data: function(){
      return {
          search: {
              terms: '',
              start_date: '',
              end_date: '',
              codes: "",
          },

          selectableCPTCodes: [],
          selectedCodes: [],

          editPaymentData: null,
          editModalId: null,
          
          cptLoading: false,         

          payments: [],
          optionMaps: null,
          paymentsLoading: true
      }
  },
  methods: {
    getPayments(){
        var params = {
            search_term: this.search.terms,
            payment_source: this.paymentSource,
            codes: this.search.codes
        };

        if (this.search.start_date){
            params.start = this.search.start_date + "T00:00:00+00:00"
        }
        if (this.search.end_date){
            params.end = this.search.end_date + "T00:00:00+00:00"
        }
        var vm = this;
        vm.paymentsLoading = true;
        authenticated_request({
            method: "get",
            url: make_url("/reports/claims/"+ this.claim.claim_id + "/payments/summary").toString(),
            params: params
        }).then(function(response){
            vm.payments = response.data.payments || [];
            vm.optionMaps = response.data.paymentMaps;
        }).catch(function(error){
            console.log(error.response)
        }).finally(()=>{
            vm.paymentsLoading = false;
        });
    },
    deletePayment(index){
        console.log("deleteing"+ index)
    },
    showEditPaymentModal(index, payment){
        this.editModalId = 'edit-claim-payment-'+index;
        this.editPaymentData = payment;

        this.$nextTick(function () {
            this.$bvModal.show('edit-claim-payment-'+index);
        });
    },
    clearEditData(){
        this.editPaymentData = null;
    },
    
    getTotalAllowed(index){
        var total = 0;
        this.payments[index].code_payments?.forEach(function(code_payment){
            total += Number(code_payment.allowed_amount || 0);
        })
        return total;
    },
    getTotalPaid(index){
        var total = 0;
        this.payments[index].code_payments?.forEach(function(code_payment){
                total += Number(code_payment.amount || 0);
        })
        return total;
    },
    getTotalAdjustments(index){
        var total = 0;
        this.payments[index].code_payments?.forEach(function(code_payment){
            code_payment.adjustments.forEach(function(adjustment){
                total += Number(adjustment.amount || 0);
            })
        })
        return total;
    },
    
    // for cpt dropdown
    codeWithDescription(code){
        if (code.code && code.short){
            return (code.code + ": " + code.short)
        }
        return ""
    },
    cptSearchChange: function(searchQuery) {
        this.cptLoading = true;
        this.searchText = searchQuery
        if (searchQuery.length > 0 ) {
            this.CPTAutocomplete();
        } else {
            this.cptLoading = false;
        }
    },
    CPTAutocomplete() {
        var vm = this;
        const url = make_url("/reports/codes/autocomplete");
        authenticated_request({
            method: "get",
            url: url,
            params: {
                search: this.searchText,
                limit: 10
            }
        }).then(function(response){
            vm.cptLoading = false;
            const codes = response.data.codes;
            vm.selectableCPTCodes = codes || [];
            return codes || [];
        }).catch(function(errors){
            console.log(errors.response)
            vm.showError(errors);
        });
    },
    clearFilters: function(){
        this.search.terms = "";
        this.search.start_date = "";
        this.search.end_date = "";
        this.search.codes = "";
        this.selectedCodes = [];
        this.getPayments();
    },
    formatDateShort(datetime) {
      return formatDateShort(datetime)
    },
  },
  computed: {
    claim() {
        return this.$store.state.claims.workedClaim;
    },
    paymentSource(){
        if(this.source && this.source == 'patient'){
            return 1
        } else if (this.source && this.source == 'payer'){
            return 2
        } else {
            return null
        }
    },
    filterInputLabel: function(){
        const count = this.filterCount;
        if(count == 0) {
            return "Add Filters";
        } else {
            return count + " Filter(s)";
        }
    },
    filterCount: function() {
        var count = 0;
        for(const filter in this.search){
            if((filter === 'start_date' || filter === 'end_date') && this.search[filter] && filter !== "terms"){
                count += 1;
            }
        }
        return count;
    },
    updatePayments: function(){
        return this.$store.state.claims.updatePayments;
    }
  },
  watch: {
    updatePayments: function() {
        if (this.updatePayments){
            this.getPayments();
            this.$store.commit("setUpdatePayments", false);
        }
    },
    selectedCodes(newValue){
          var justCodes = [];
          if(newValue.length > 0){
              newValue.forEach(function(code){
                  justCodes.push(code.code)
              })
              this.search.codes = justCodes.join(",");
          } else {
              this.search.codes = "";
          }
      }
  },
  created() {
      this.debounceCPTCodes = debounce(this.cptSearchChange, 300)
      this.debouncePayments = debounce(this.getPayments, 300)
      this.getPayments();
  },
  mounted: function() {
    
  }
}
</script>

<style scoped>
::v-deep .multiselect__spinner {
    background: #DDE4F5;
}
.no-payments {
  font-size: 16px;
  font-weight: 200;
  text-align: center;
}
.payment-card {
    min-height:100px;
    border-radius: 10px;
    background: white;
    border: 1px solid #E2E4EB;
}
.code-payment-box {
    background: transparent linear-gradient(101deg, #EEF1FA 0%, #F8FAFD 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width:100%;
    height:100%;
}
.modifier-badge {
    display:flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 9px;
    border-radius: 4px;
    background-color: #223053;
    margin-right:2px;
    /* padding: 1px 2px 0px 2px; */
    padding: 2px 1px 0 0;
    min-height: 15px;
    min-width: 15px;
}

/* Styling the search bar group */
::v-deep .search-group {
    position: relative;
    border: 1px solid #C9D2DF;
    border-radius: 5px;
    max-width:650px;
}
::v-deep .payment-search-input, .input-group-text {
    height:50px;
    border: none;
    border-radius: 5px;
    background: white;
    color: #9CA3AD; 
}
::v-deep .payment-search-input::placeholder {
    color: #9CA3AD;
    font-size:12px;
    opacity: 1;
}
::v-deep .b-dropdown .btn-secondary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 5px;
    color: #9CA3AD;
    background: white;
    font-size:12px;
    height:50px;
    padding-right: 35px;
    min-width:150px;
    text-align:left;
}
::v-deep .b-dropdown .btn-secondary:active,
::v-deep .btn-secondary:not(:disabled):not(.disabled):active, 
::v-deep .btn-secondary:not(:disabled):not(.disabled).active, 
::v-deep .show > .btn-secondary.dropdown-toggle {
    color:#519EFF !important;
    background-color: white !important;
}
::v-deep .b-dropdown .btn-secondary:focus {
    box-shadow:none !important;
}
.divider {
    display: inline-block;
    width: 0;
    height: 20px;
    margin: auto;
    border-right: 1.8px solid #D2D8E2;
}

/* dropdown menu-related styling */
::v-deep .filter-dropdown .b-dropdown-form {
    padding: 20px 30px 20px 30px;
}
::v-deep .filter-dropdown .dropdown-menu {
    width:100.4%;
    right: -1px !important;
    left: auto !important;
    top:45px !important;
    transform: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 16px 48px #1617182B !important;
}

::v-deep .avo-action-dropdown .btn-secondary {
    padding: 0px;
    height:20px;
    margin-top:-10px;
}
</style>