<template>
<div v-if="practice || practiceId">
    <b-alert
        v-model="showAlert"
        variant="danger"
        style="margin-left:20px"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <b-row>
        <b-col cols="3" style="margin-left:20px">
        <multiselect 
            :show-labels="false"
            v-model="filters.payer"
            :hide-selected="false"
            :close-on-select="true"
            track-by="name"
            :custom-label="payerLabelWithAddress"
            :options="filters.options"
            :multiple="false"
            :internalSearch="true"
            placeholder="-- Select a Payer  --"
            @close="getFees">
        </multiselect>
        </b-col>

        
        <b-col>
        <b-input-group class="search-group">
            <b-input-group-prepend>
                <span class="input-group-text"><b-icon icon="search"></b-icon></span>
            </b-input-group-prepend>
            <b-form-input
                @keyup="debounceFees"
                @search="debounceFees"
                class="fee-search-input pl-1"
                type="search" v-model="search"
                placeholder="Filter fee schedule by code"/>    
        </b-input-group>
        </b-col>
        
        
        <b-col>
        <div :style="{visibility: filters.payer || search ? 'visible' : 'hidden'}">
            <b-button variant="outline-danger" @click="clearFilters">Clear Filters</b-button>
        </div>
        </b-col>
        
        <b-col>
        <b-button-group>
            <b-button :class="'avo-basic-btn mr-2 '+((selectedTab == 'master') ? 'not-collapsed' : '')" @click="selectTab('master')" variant="outline-primary">
                Master
            </b-button>
            <b-button :class="'avo-basic-btn mr-2 '+((selectedTab == 'surgery-center') ? 'not-collapsed' : '')" @click="selectTab('surgery-center')" variant="outline-primary">
                Surgery Center
            </b-button>
            <b-button :class="'avo-basic-btn mr-2 '+((selectedTab == 'self-pay') ? 'not-collapsed' : '')" @click="selectTab('self-pay')" variant="outline-primary">
                Self Pay
            </b-button>
        </b-button-group>
        </b-col>
        <b-col cols=1>
            <div>
                <multiselect 
                    :show-labels="false"
                    v-model="filters.year"
                    :hide-selected="false"
                    :close-on-select="true"
                    :allow-empty="false"
                    track-by="year"
                    label="year"
                    :options="yearOptions"
                    :multiple="false"
                    :internalSearch="true"
                    placeholder="-- Year --"
                    @close="getFees">
                </multiselect>
            </div>
        </b-col>
        <b-col class="col-auto">
            <div style="margin-right:20px">
                <b-button v-if="hasPermission('edit')" v-b-modal.add-new-code class="ml-2 avo-primary-btn">Add new code</b-button>
                <practice-fee-add :practiceId="practice_id" :year="currentYear" :payerInfoTab="false" @submitted="refreshTable()" />
            </div>
        </b-col>
    </b-row>
    <!-- TODO: The use of b-table-simple here presents maintainability problems, because we are matching 
            up the headers and the contents of each column only by the order they appear, while at the 
            same time we dynamically change the columns based on selected options on the page.
            If we ever need to change the layout here, it is worth taking the time to try to migrate 
            this to a b-table or refactor some other way. -->
    <b-overlay :show="!loaded" variant="white" style="min-height:250px;">
    <div v-if="fees.length > 0"  style="margin-left:20px; margin-right:20px">
        <b-table-simple borderless class="fee-table mt-4" style="font-size:14px;">
            <b-thead>
                <b-tr>
                    <b-th>
                        Code
                    </b-th>
                    <b-th>
                        Description
                    </b-th>
                    <b-th>
                        Modifiers
                    </b-th>
                    <b-th>
                        POS
                    </b-th>
                    <b-th>
                        TOS
                    </b-th>
                    <b-th>
                        <div v-if="selectedTab == 'master' || selectedTab == 'self-pay'">
                        Medicare Fee
                        </div>
                        <div v-if="selectedTab == 'surgery-center'">
                        Medicare Fee
                        <br/>
                        <span class="badge badge-primary">Surgery Center</span>
                        </div>
                    </b-th>
                    <b-th v-if="selectedTab == 'master' || selectedTab == 'surgery-center'">
                        <div v-if="selectedTab == 'master'">
                        Charge Fee
                        </div>
                        <div v-if="selectedTab == 'surgery-center'">
                        Charge Fee
                        <br/>
                        <span class="badge badge-primary">Surgery Center</span>
                        </div>
                        <!-- No charge fee column for self-pay -->
                    </b-th>
                    <b-th>
                        <div v-if="selectedTab == 'master'">
                        Contracted Fee
                        </div>
                        <div v-if="selectedTab == 'surgery-center'">
                        Contracted Fee
                        <br/>
                        <span class="badge badge-primary">Surgery Center</span>
                        </div>
                        <div v-if="selectedTab == 'self-pay'">
                        Self-pay Fee
                        </div>
                    </b-th>
                    <b-th>
                        <div v-if="selectedTab == 'master'">
                        Allowed Amount
                        </div>
                        <div v-if="selectedTab == 'surgery-center'">
                        Allowed Amount
                        <br />
                        <span class="badge badge-primary">Surgery Center</span>
                        </div>
                        <div v-if="selectedTab == 'self-pay'">
                        Self-pay Fee
                        <br/>
                        <span class="badge badge-primary">Surgery Center</span>
                        </div>
                    </b-th>
                    <b-th>
                        <div v-if="selectedTab == 'master' || selectedTab == 'self-pay'">
                        Cost
                        </div>
                        <div v-if="selectedTab == 'surgery-center'">
                        Cost
                        <br/>
                        <span class="badge badge-primary">Surgery Center</span>
                        </div>
                    </b-th>
                    <b-th v-if="selectedTab == 'self-pay'">
                        <div>
                        Cost
                        <br/>
                        <span class="badge badge-primary">Surgery Center</span>
                        </div>
                    </b-th>
                    <b-th>
                        Actions
                    </b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="(fee,index) in fees" :key="index">
                    <b-td>
                        {{fee.cpt_code || "N/A"}}
                    </b-td>
                    <b-td>
                        {{fee.short || "N/A"}}
                    </b-td>
                    <b-td>
                        {{fee.modifiers || "N/A"}}
                    </b-td>
                    <b-td>
                        {{fee.pos || "N/A"}}
                    </b-td>
                    <b-td>
                        {{fee.tos || "N/A"}}
                    </b-td>
                    <!-- Begin medicare column -->
                    <b-td v-if="selectedTab == 'master' || selectedTab == 'self-pay'">
                        {{fee.medicare_fee ? '$' + fee.medicare_fee : "N/A"}}
                    </b-td>
                    <b-td v-if="selectedTab == 'surgery-center'">
                        {{fee.medicare_fee_surgery_center ? '$'+fee.medicare_fee_surgery_center : "N/A"}}
                    </b-td>
                    <!-- Begin charge fee columns -->
                    <b-td v-if="selectedTab == 'master'" :class="filters.payer ? '': 'avo-text-light'">
                        {{fee.charge_fee ? '$'+fee.charge_fee : (filters.payer ? "N/A" : "-- Select a payer --")}}
                    </b-td>
                    <b-td v-if="selectedTab == 'surgery-center'" :class="filters.payer ? '': 'avo-text-light'">
                        {{fee.charge_fee_surgery_center ? '$'+fee.charge_fee_surgery_center : (filters.payer ? "N/A" : "-- Select a payer --")}}
                    </b-td>
                    <!-- Begin contracted fee columns -->
                    <b-td v-if="selectedTab == 'master'" :class="filters.payer ? '': 'avo-text-light'">
                        {{fee.contracted_fee ? '$'+fee.contracted_fee : (filters.payer ? "N/A" : "-- Select a payer --")}}
                    </b-td>
                    <b-td v-if="selectedTab == 'surgery-center'" :class="filters.payer ? '': 'avo-text-light'">
                        {{fee.contracted_fee_surgery_center ? '$'+fee.contracted_fee_surgery_center : (filters.payer ? "N/A" : "-- Select a payer --")}}
                    </b-td>
                    <!-- Begin allowed amt columns -->
                    <b-td v-if="selectedTab == 'master'" :class="filters.payer ? '': 'avo-text-light'">
                        {{fee.allowed_amount ? '$'+fee.allowed_amount : (filters.payer ? "N/A" : "-- Select a payer --")}}
                    </b-td>
                    <b-td v-if="selectedTab == 'surgery-center'" :class="filters.payer ? '': 'avo-text-light'">
                        {{fee.allowed_amount_surgery_center ? '$'+fee.allowed_amount_surgery_center : (filters.payer ? "N/A" : "-- Select a payer --")}}
                    </b-td>
                    <!-- Begin self-pay columns -->
                    <b-td v-if="selectedTab == 'self-pay'">
                        {{fee.self_pay_fee ? '$'+fee.self_pay_fee : "N/A"}}
                    </b-td>
                    <b-td v-if="selectedTab == 'self-pay'">
                        {{fee.self_pay_fee_surgery_center ? '$'+fee.self_pay_fee_surgery_center : "N/A"}}
                    </b-td>
                    <!-- Begin cost column -->
                    <b-td v-if="selectedTab == 'master' || selectedTab == 'self-pay'">
                        {{fee.cost ? '$' + fee.cost : "N/A"}}
                    </b-td>
                    <b-td v-if="selectedTab == 'surgery-center'">
                        {{fee.cost_surgery_center ? '$' + fee.cost_surgery_center : "N/A"}}
                    </b-td>
                    <!-- Extra self-pay cost column -->
                    <b-td v-if="selectedTab == 'self-pay'">
                        {{fee.cost_surgery_center ? '$' + fee.cost_surgery_center : "N/A"}}
                    </b-td>
                    <b-td>
                        <div>
                        <b-dropdown class="avo-action-dropdown" no-caret style="min-width:none" :right="true">
                            <template #button-content>
                            <b-icon icon="three-dots"></b-icon>
                            </template>
                                <b-dropdown-item @click="viewEditCodePayersModal(index, fee)"><b-icon variant="secondary" class="mr-2" icon="credit-card"></b-icon> View/edit payer rates</b-dropdown-item>
                                <b-dropdown-item @click="viewEditCodeRatesModal(index, fee)"><b-icon variant="secondary" class="mr-2" icon="percent"></b-icon> Edit practice fee rates</b-dropdown-item>
                            <div v-if="filters.payer">
                                <b-dropdown-item @click="viewRemovePayerFromCodeModal(index)"><b-icon variant="secondary" class="mr-2" icon="trash"></b-icon> Remove fees for {{ filters.payer.name }}</b-dropdown-item>
                            </div>
                            <b-dropdown-item @click="viewRemoveCodeModal(index)"><b-icon variant="secondary" class="mr-2" icon="trash-fill"></b-icon> Remove code from practice</b-dropdown-item>
                        </b-dropdown>                       

                        <b-modal class="modal" :id="'remove-payer-code-modal-'+index" hide-footer hide-header v-if="filters.payer">
                            <div class="avo-header-text mt-2 mb-4 bold" style="text-align:center">
                                Remove fee details for {{filters.payer.name}}?
                            </div>
                            <div class="avo-text-light mb-4">
                                You will lose all fee rates associated with {{filters.payer.name}} for CPT code {{fee.cpt_code}} in {{ currentYear }}. This cannot be undone.
                            </div>
                            <div style="text-align:right">
                                <b-button-group>
                                <b-button class="avo-basic-btn ml-2" @click="hideRemovePayerFromCodeModal(index)">Cancel</b-button>
                                <b-button class="avo-primary-btn ml-2" @click="removePayerFromCode(index, filters.payer.payer_id, fee.cpt_code, currentYear)" variant="primary">Yes, delete fee details</b-button>
                                </b-button-group>
                            </div>
                        </b-modal>

                        <b-modal class="modal" :id="'remove-code-modal-'+index" hide-footer hide-header>
                            <div class="avo-header-text mt-2 mb-4 bold" style="text-align:center">
                                Remove all fee details for CPT code {{fee.cpt_code}}?
                            </div>
                            <div class="avo-text-light mb-4">
                                You will lose all fee rates associated with CPT code: {{fee.cpt_code}} modifiers: {{fee.modifiers}} tos/pos: {{fee.tos}}/{{fee.pos}} for {{ currentYear }}. This cannot be undone.
                            </div>
                            <div style="text-align:right">
                                <b-button-group>
                                <b-button class="avo-basic-btn ml-2" @click="hideRemoveCodeModal(index)">Cancel</b-button>
                                <b-button class="avo-primary-btn ml-2" @click="removeCode(index, fee.cpt_code, fee.modifiers, fee.tos, fee.pos, currentYear)" variant="primary">Yes, delete fee details</b-button>
                                </b-button-group>
                            </div>
                        </b-modal>

                        </div>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <div>
            <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="page" align="center" />
        </div>
    </div>
    <div v-else-if="loaded" style="text-align:center">
        No fees match the selected filters
    </div>
    </b-overlay>
    <practice-fee-edit-rates v-if="selectedFee" :codeInfo="selectedFee" :practiceId="practice_id" :year="currentYear" :key="'rates'+(selectedFeeIndex || 0)" @submitted="refreshTable()" />
    <practice-fee-edit-payers v-if="selectedFee" :codeInfo="selectedFee" :practiceId="practice_id" :year="currentYear" :key="'payer'+(selectedFeeIndex || 0)" @modal-hidden="refreshTable()" />
</div>

</template>

<script>
import { make_url, authenticated_request, debounce, permissionsMixin, alertsMixin } from '../../common.js'
import PracticeFeeAdd from './PracticeFeeAdd.vue'
import PracticeFeeEditRates from './PracticeFeeEditRates.vue'
import PracticeFeeEditPayers from './PracticeFeeEditPayers.vue'
import Multiselect from 'vue-multiselect'

export default {
    name: 'PracticeFees',
    mixins: [permissionsMixin, alertsMixin ],
    props: ['practiceId'],
    components: {
        PracticeFeeEditRates,
        PracticeFeeEditPayers,
        PracticeFeeAdd,
        Multiselect,
    },
    data(){
    const current_year = new Date().getFullYear();
    return {
        fees: [],
        search: "",
        perPage: 10,
        totalRows: 1,
        page: 1,

        selectedTab: 'master',

        selectedFee: null,
        selectedFeeIndex: null,

        currentYear: current_year,

        filters: {
            payer: null,
            options: [],
            year: { year: current_year },
        },

        yearOptions: [],
        
        loaded: false,
    }
    },
    methods: {
        selectTab(string){
            this.selectedTab = string;
        },
        getFilters(){
            const vm = this;

            // Get available payers to select from
            authenticated_request({
                method: "get",
                url: make_url("/practices/" + vm.practice_id + "/fees/payers"),
            }).then(function(response){
                const payersData = response.data.payers;
                for (var index = 0; index < payersData.length; index++){
                vm.filters.options.push({payer_id: payersData[index].payer_id, 
                                         name: payersData[index].name,
                                         full_address: payersData[index]['full_address']});
                }
            });

            // Get available years to select from
            authenticated_request({
                method: "get",
                url: make_url("/practices/" + vm.practice_id + "/fees/years"),
            }).then(function(response){
                const yearsData = response.data.years;
                for (var index = 0; index < yearsData.length; index++){
                vm.yearOptions.push({year: yearsData[index].year});
                }
            });
        },
        getFees(clear_page=true){
            this.loaded = false;
            var params = {
                'search': this.search,
                'year' : this.filters.year ? this.filters.year.year: this.currentYear,
            };
            params.per_page = this.perPage;
            if(clear_page) {
                params.page = 1;
            }
            else {
                params.page = this.page;
            }
            var url;
            if (this.filters.payer) {
                url = make_url("/practices/" + this.practice_id + "/payers/" + this.filters.payer.payer_id + "/fees");
            } else {
                url = make_url("/practices/" + this.practice_id + "/codes");
            }
            const self = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
                params: params
            }).then(function(response){
                if (response) {
                    const fees = response.data.practice_codes;
                    self.fees = fees;
                    self.totalRows = response.data.pagination.total;
                    self.perPage = response.data.pagination.per_page;
                    return fees || [];
                }
            }).catch(function(error){
                self.showError(error);
            }).finally(() => {
                self.loaded = true;
            });
            
            return promise;
        },
        clearFilters(){
            this.filters.payer = null;
            this.search = "";
            this.refreshTable();
        },
        refreshTable(){
            this.getFees();
            this.clearFilterOptions();
            this.getFilters();
            this.selectedFee = null;
            this.selectedFeeIndex = null;
        },
        doSearch(){
            console.log("search");
        },
        clearFilterOptions(){
            console.log("clear");
            this.filters.options = [];
            this.yearOptions = [];
        },
        //error methods
        showError(alertMessage){
            this.setShowAlert(true, alertMessage);
            this.showSuccess = false;
        },
        hideError(){
            this.setShowAlert(false);
        },
        // Modals methods
        viewRemovePayerFromCodeModal(index) {
            this.$bvModal.show('remove-payer-code-modal-'+index);
        },
        hideRemovePayerFromCodeModal(index) {
            this.$bvModal.hide('remove-payer-code-modal-'+index);
        },
        removePayerFromCode(index, payer_id, cpt_code, year) {
            const vm = this;
            authenticated_request({
                method: "delete",
                url: make_url("/practices/" + vm.practice_id + "/fees/payers/" + payer_id +"/codes/" + cpt_code),
                data: { "year": year }
            }).then(function(){
                vm.getFees();
                vm.hideRemovePayerFromCodeModal(index);
            })
        },
        viewRemoveCodeModal(index) {
            this.$bvModal.show('remove-code-modal-'+index);
        },
        hideRemoveCodeModal(index) {
            this.$bvModal.hide('remove-code-modal-'+index);
        },
        removeCode(index, cpt_code, modifiers, tos, pos, year) {
            const vm = this;
            authenticated_request({
                method: "delete",
                url: make_url("/practices/" + vm.practice_id + "/codes/" + cpt_code),
                data: { "modifiers": modifiers, "tos": tos, "pos": pos, "year": year }
            }).then(function(){
                vm.getFees();
                vm.hideRemoveCodeModal(index);
            })
        },
        viewEditCodeRatesModal(index, fee) {
            this.selectedFee = fee;
            this.selectedFeeIndex = index;
            this.$nextTick().then(()=>{this.$bvModal.show('edit-code-rates');});
        },
        viewEditCodePayersModal(index, fee) {
            this.selectedFee = fee;
            this.selectedFeeIndex = index;
            this.$nextTick().then(()=>{this.$bvModal.show('edit-code-payers');});
        },
        payerLabelWithAddress({name, full_address}){
            if (full_address) {
                return name + " (" + full_address + ")";
            } else {
                return name;
            }
        }
    },
    computed: {
        practice: function(){
            return this.$store.state.practices.selected_practice;
        },
        practice_id: function(){
            if (this.practice != null){
                return this.practice.practice_id;
            } else if (this.practiceId){
                return this.practiceId
            }
            return null;
        },
        filterCount: function() {
            var count = 0;
            //TODO: count filters when they exist
            return count;
        },
        filterInputLabel: function(){
            const count = this.filterCount;
            if(count == 0) {
                return "Filters";
            } else {
                return count + " Filter(s)";
            }
        },
    },
    watch: {
        page(){
            this.getFees(false);
        }
    },
    created(){
        this.getFees();
        this.debounceFees = debounce(this.getFees, 900);
    },
    mounted(){
        this.getFilters();
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fee-table {
    border: 1px solid #C9D2DF;
    border-collapse: separate;
    border-spacing: 0px;
    border-radius: 5px;
}
::v-deep .fee-table th{
    padding: 20px;
    vertical-align: middle;
}
::v-deep .fee-table td{
    padding: 20px;
    vertical-align: middle;
}
::v-deep .fee-table tr:first-child th{
    border-bottom: 1px solid #C9D2DF;
}
::v-deep .fee-table tr:nth-child(odd) td{
    background:#F7F8FA;
}
::v-deep .fee-table tr:last-child td:first-child{
    border-radius:0px 0px 0px 5px;
}
::v-deep .fee-table tr:last-child td:last-child{
    border-radius:0px 0px 5px 0px;
}

/* Styling the search bar group */
::v-deep .search-group {
    position: relative;
    margin-right: 20px;
    border: 1px solid #C9D2DF;
    border-radius: 5px;
    max-width:650px;
}
::v-deep .fee-search-input, .input-group-text {
    /* height:50px; */
    border: none;
    border-radius: 5px;
    background: white;
    color: #9CA3AD; 
}
::v-deep .fee-search-input::placeholder {
    color: #9CA3AD;
    font-size:12px;
    opacity: 1;
}
::v-deep .b-dropdown .btn-secondary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 5px;
    color: #9CA3AD;
    background: white;
    font-size:12px;
    height:50px;
    min-width:150px;
    text-align:left;
}
::v-deep .b-dropdown .btn-secondary:active,
::v-deep .btn-secondary:not(:disabled):not(.disabled):active, 
::v-deep .btn-secondary:not(:disabled):not(.disabled).active, 
::v-deep .show > .btn-secondary.dropdown-toggle {
    color:#519EFF !important;
    background-color: white;
}
::v-deep .b-dropdown .btn-secondary:focus {
    box-shadow:none !important;
}
.divider {
    display: inline-block;
    width: 0;
    height: 20px;
    margin: auto;
    border-right: 1.8px solid #D2D8E2;
}

/* dropdown menu-related styling */
::v-deep .filter-dropdown .b-dropdown-form {
    padding: 20px 30px 30px 30px;
}
::v-deep .filter-dropdown .dropdown-menu {
    width:100.3%;
    right: -1px !important;
    left: auto !important;
    top:45px !important;
    transform: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 16px 48px #1617182B !important;
}
::v-deep .flag-dropdown .dropdown-menu {
    width: 200px;
    padding: 10px;
    left: -1px !important;
    top:48px !important;
    transform: none !important;
    font-size: 14px;
    font-weight: 500;
    color: #64728C;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 16px 48px #1617182B !important;
}
::v-deep .flag-dropdown .b-dropdown-form {
    padding: 0.2rem 0.8rem;
}
.selecter-label {
    color: #64728C;
    font-size: 14px;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom:5px;
}
/* This ensures at least one panel in the accordion will always be open */
.not-collapsed {
  pointer-events: none !important;
  background: #F2F6FC !important;
}
</style>