<template>
    <div class="documents-container">
       <document-list idType="claim" :id="claim.claim_id" perPage="8" mode="list"/>
    </div>
   
</template>

<script>
// import { make_url, authenticated_request } from '../../common.js'
import DocumentList from '../documents/DocumentList.vue'

export default {
    name: 'WorkClaimDocuments',
    components: {
        DocumentList,
    },
    computed: {
        claim() {
            return this.$store.state.claims.workedClaim;
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.documents-container {
    margin-top: 25px;
}
</style>