<template>
<div id="add-icd-code">
<b-modal id="new-icd-code" @hide="resetModal" title="Add an ICD Code" hide-footer size="md">
<b-form @submit="onSubmit">
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <b-row>
        <b-col cols="9">
            <b-form-group
            id="code"
            label="Code:">
            <b-input-group>
                <multiselect
                    openDirection="bottom"
                    v-model="icd_code" 
                    :options="icd_codes"
                    :custom-label="codeWithDescription"
                    placeholder="Type an ICD code to select" 
                    :searchable="true"
                    :show-labels="false"
                    :multiple="false"
                    :allow-empty="false"
                    :internalSearch="true"
                    @search-change="icdSearchChange"
                    :hideSelected="true">
                    <template slot="noResult">
                        No codes matched your search
                    </template>
                    <template slot="noOptions">
                        Start typing a code or description...
                    </template>
                </multiselect>
            </b-input-group>
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group
            id="pointer"
            label="Pointer:">
            <b-input-group>
                <b-form-input type="number" min="0" v-model="icd_code.pointer"></b-form-input>
            </b-input-group>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group
                id="description"
                label="Description:"
                style="text-align:left">
                <b-form-textarea v-model="icd_code.description">{{icd_code.description}}</b-form-textarea>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col style="text-align:center">
            <b-button @click="onSubmit" class="avo-primary-btn">
                Add ICD Code
            </b-button>
        </b-col>
    </b-row>
</b-form>
</b-modal>
</div>
</template>

<script>
import axios from "axios";
import Multiselect from 'vue-multiselect'
import { alertsMixin, make_url, authenticated_request } from '../../common.js'

export default {
  name: 'IcdCodeAdd',
  props: ['claimId', 'preauthId', 'currentCodes', 'newPreauth'],
  mixins: [
    alertsMixin
  ], 
  components: {
      Multiselect,
  },
  data: function(){
      return {
            icd_code: {
                code: null,
                description: null,
                pointer: null,
            },

            hasResults: false,
            results: [],
      }
  },
  computed: {
      icd_codes: function() {
          return this.results.map(
              val => ({ code: val[0], description: val[1] })
              )
      }
  },
  methods: {
    clearCode(){
        this.icd_code = {
            code: null,
            description: null,
            pointer: null,
        }
        this.hideError();
    },
    validateInputs(){
        this.hideError();
        
        //validate inputs
        if ((this.icd_code.code) == null || (this.icd_code.code == "")){
            this.showError("Missing field: code");
            return false;
        }
        if (this.currentCodes){
            for (var i = 0; i < this.currentCodes.length; i++){
                if (this.icd_code.code ==  this.currentCodes[i]['code']){
                    this.showError("Code already is associated with claim");
                    return false;
                }
            }
        }
        
        return true
    },
    addCodeToClaim(){
        let url;
        if (this.claimId !== null){
            url = make_url("/claims/" + this.claimId + "/icd_codes");
        } else if (this.preauthId !== null){
            url = make_url("/preauths/" + this.preauthId + "/icd_codes")
        }
        var data = {
            code: this.icd_code.code,
            description: (this.icd_code.description || ""),
            pointer: (this.icd_code.pointer || 0)
        }
        const self = this;
        const promise = authenticated_request({
            method: "post",
            url: url,
            data: data
        }).then(function(response){
            if (response){
                self.$store.commit("setUpdateCPTCodes", true);
                self.$bvModal.hide('new-icd-code');
                self.$emit('submitted');
                self.$store.commit("refreshActivityLog");   
            }
        }).catch(function(error){
            self.showError(error);
        });
        return promise;
    },
    onSubmit(){
        if (this.validateInputs()){
            if(this.newPreauth){
                console.log("storing for preauth")
                this.$store.commit("preauths/storeICDCode", this.icd_code);
                if(this.icd_code.pointer){
                    this.$store.commit("preauths/storePointer", this.icd_code.pointer);
                }
                this.$bvModal.hide('new-icd-code');
            } else {
                this.addCodeToClaim();
            }
        }
    },
    resetModal(){
        this.hideError();
        this.clearCode();
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
    // for icd dropdown
    codeWithDescription(code){
        if (code.code && code.description){
            return (code.code + ": " + code.description)
        }
        return ""
    },
    icdSearchChange: function(searchQuery){
        var self = this; 
        console.log(searchQuery);
        axios.get(
            "https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search",
            {
                params: { 
                    sf: "code,name",
                    terms: searchQuery
                }
            }
        ).then(
            function(response) {
                self.results = response.data[3];
                self.hasResults = true;
                self.icd_codes = response.data[3];
        }).catch(
            function(error){
                console.log(error);
        });
      }
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>

/* nav bar styling */
.nav-section {
    display: flex;
    justify-content: space-around;
    margin-left: -15px;
    margin-right: -15px;
    height: 40px;   
    border-bottom: 1px solid #E6EEF9;
}
.nav-section .nav-link {
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    color: var(--gray);
}
.nav-section .nav-link:hover{
    border:none;
    color: var(--dark);
    border-bottom: 2px solid var(--gray);
}
.nav-section .nav-item a.active {
    color: var(--blue);
    font-weight: 500;
    border-bottom: 2px solid var(--blue);
}
</style>