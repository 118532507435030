<template>
    <b-card :class="[{'card-height': mode === 'list', 'shadow': mode === 'list'}]">
        <template #header>
            <div class="d-flex align-items-center">
                <template v-if="mode === 'list'">
                    <b-avatar :text="note.initials" class="mr-2"/>
                    <div class="d-flex flex-column align-items-start">
                        <div>{{note.name}}</div>
                        <div class="created-text">Created on {{ new Date(note.created).toLocaleString("en-US", {month: 'numeric', day: 'numeric', year: 'numeric'}) }}</div>
                    </div>
                </template>
                <template v-else-if="mode === 'inline'">
                    <b-avatar icon="file-earmark" variant="primary" size="2rem" class="mr-3"/>
                    <strong>{{note.title}}</strong>
                </template>
            </div>        
        </template>
        <b-card-body :title="mode==='list'?note.title:''">
            <b-card-text>{{note.body}}</b-card-text>
        </b-card-body>
        <template #footer>
            <div class="d-flex p-1">
                <div class="d-flex flex-column justify-content-start">
                    <div class="mb-1"><strong>Tags</strong></div>
                    <div class="d-flex flex-wrap">
                        <b-badge>#{{note.label}}</b-badge>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <div v-if="mode === 'list'" class="modified-text">
                    Last modified on {{ new Date(note.updated).toLocaleString("en-US", {month: 'numeric', day: 'numeric', year: 'numeric'}) }}
                </div>
                <div v-if="mode === 'inline'" class="modified-text">
                    Last modified by {{note.name}} on {{ new Date(note.updated).toLocaleString("en-US", {month: 'numeric', day: 'numeric', year: 'numeric'}) }}
                </div>
            </div>
        </template>
    </b-card>
</template>

<script>
export default {
    name: 'NoteCard',
    props: [
        'note',
        'mode'
    ],
    components: {},
    data(){
      return {
          
        }
    }
}
</script>

<style scoped>
.card {
    margin-bottom: 24px;
    border-radius: 15px;
    border: 1px solid #E6EEF9;
}
.card-height {
    min-height: 350px;
}

.card-header, .card-footer {
    padding: 0.75rem 1rem;
    padding-bottom: 0px;
    background-color: transparent;
    border: none;
    border-radius:15px;
}
.card-footer {
    font-size:0.75rem;
    padding-bottom:10px;
}
.card-body {
    padding: 10px 10px 0px 20px;
}

.card-title {
    padding-left:10px;
    font-size:1rem;
}
.card-text {
    font-size:0.75rem;
}
.created-text {
    font-weight:300;
    font-size:12px;
}
.modified-text {
    color: var(--gray);
    font-weight:300;
    font-size:10px;
}
.badge {
    padding:7px;
    border-radius: 10px;
    margin: 4px;
    background: #F1F7FF 0% 0% no-repeat padding-box;
    color: #011828;
    font-size: 12px;
    font-weight:400;
}

</style>