<template>
<div id="patient-edit">
<b-modal id="edit-patient" @show="resetModal" @hide="resetModal" title="Edit Patient" hide-footer size="md">
<b-form>
    <b-alert
        v-model="showAlert"
        variant="danger"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <div>
        <div class="d-flex">
            <div class="flex-fill mr-2">
                <b-form-group
                id="given_name"
                label="First Name">
                <b-input-group>
                    <b-form-input v-model="new_patient.given_name"></b-form-input>
                </b-input-group>
                </b-form-group>
            </div>
            <div class="flex-fill ml-2">
                <b-form-group
                id="family_name"
                label="Last Name">
                <b-input-group>
                    <b-form-input v-model="new_patient.family_name"></b-form-input>
                </b-input-group>
                </b-form-group>
            </div>
        </div>
        <div class="d-flex">
            <div class="flex-fill mr-2">
                <b-form-group
                id="account_no"
                label="Account Number">
                <b-input-group>
                    <b-form-input v-model="new_patient.account_no"></b-form-input>
                </b-input-group>
                </b-form-group>
            </div>
            <div class="flex-fill ml-2">
                <b-form-group
                id="date_of_birth"
                label="Date of Birth">
                <b-input-group>
                    <b-form-input type="date"
                    today-button
                    reset-button
                    close-button
                    v-model="new_patient.date_of_birth"></b-form-input>
                </b-input-group>
                </b-form-group>
            </div>
        </div>
        <b-card class="mt-4 mb-4">
            <div class="avo-header-text">
                Contant Info
            </div>
            <hr style="margin-right:-20px;margin-left:-20px">
            <div class="d-flex">
                <div class="mr-2">
                    <b-form-group
                    id="telephone"
                    label="Primary Phone">
                    <b-input-group>
                        <b-form-input v-model="new_address.telephone"></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </div>
                <div class="ml-2">
                    <b-form-group
                    id="email"
                    label="Email">
                    <b-input-group>
                        <b-form-input v-model="new_address.email"></b-form-input>
                    </b-input-group>
                    </b-form-group>
                </div>
            </div>
        </b-card>
        <div class="mt-4" style="text-align:right">
            <b-button @click="validatePatientInputs" variant="outline-primary" class="avo-primary-btn" :disabled="submitting">
                <b-overlay :show="submitting" variant="white">Submit Patient Changes</b-overlay>
            </b-button>
        </div>
    </div>
</b-form>
</b-modal>
</div>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from '../../common.js'

export default {
  name: 'PatientEdit',
  props: ['patientId', 'patient', 'claimId', 'issues'],
  mixins: [alertsMixin],
  data: function(){
      return {
            new_patient: {
                given_name: null,
                family_name: null,
                account_no: null,
                date_of_birth: null,
            },
            new_address: {
                telephone: null,
                email: null,
            },
            
            submitting: false,
      }
  },
  methods: {
    clearData(){
        if (this.patient){
            this.new_patient = {
                given_name: this.patient.given_name,
                family_name: this.patient.family_name,
                account_no: this.patient.account_no,
                date_of_birth: this.datetimeToDate(this.patient.date_of_birth),
            };
            this.new_address = {
                telephone: this.patient.telephone,
                email: this.patient.email,
            };
        }
        this.$emit("loaded");
        this.hideError();
    },
    validatePatientInputs(){
        this.submitting = true;
        this.hideError();
        
        if ((this.new_patient.given_name == "") || (this.new_patient.given_name == null)){
            this.showError("Please enter a patient first name");
            this.submitting = false;
            return false;
        }
        if ((this.new_patient.family_name == "") || (this.new_patient.family_name == null)){
            this.showError("Please enter a patient last name");
            this.submitting = false;
            return false;
        }
        
        var data = {
            'patient': null,
            'address': null
        }
        var patient_data = {}
        var address_data = {}
        var key;
        for (key in this.patient){
            if (key != "telephone" && key != "email"){
                if (this.new_patient[key] != this.patient[key]){
                    patient_data[key] = this.new_patient[key];
                }
            }
            else{
                if (this.new_address[key] != this.patient[key]){
                    address_data[key] = this.new_address[key];
                }
            }
        }
        if (Object.keys(patient_data).length > 0){
            data['patient'] = patient_data;
        }
        if (Object.keys(address_data).length > 0){
            data['address'] = address_data;
        }
        if ((data['patient'] == null) && (data['address'] == null)){
            this.submitting = false;
            this.$bvModal.hide('edit-patient');
            return
        }
        
        this.editPatient(data);
    },
    editPatient(data){
        const url = make_url("/forms/patient_with_address/" + this.patientId);
        const vm = this;
        const promise = authenticated_request({
            method: "put",
            url: url,
            data: data
        }).then(function(response){
            if (response) {
                if (vm.claimId){
                    vm.refreshWorkedClaimData();
                }
                else{
                    vm.submitting = false;
                    vm.$bvModal.hide('edit-patient');
                }
            }
        }).catch(function(error){
            vm.submitting = false;
            vm.showError(error);
        });
        return promise;
    },
    // Claim functions will only run if claimId is passed in
    refreshWorkedClaimData(){
        var vm = this;
        const url = make_url("/reports/claims");
        authenticated_request({
            method: "get",
            url: url,
            params: {
                'claim_id': vm.claimId,
                'issues_claims': vm.issues
            }
        }).then(function(response){
            var claim = response.data.claims[0];
            vm.$store.commit("setWorkedClaim", claim);
            vm.$bvModal.hide('edit-patient');
        }).catch(function(errors){
            vm.showError(errors);
        }).finally(()=>{
            vm.submitting = false;
        });
    },
    datetimeToDate(datetime){
        if (datetime != null){
            datetime = datetime.split("T")[0];
        }
        return datetime;
    },
    resetModal(){
        this.clearData();
    },
    showError(alertMessage){
        this.setShowAlert(true, alertMessage);
    },
    hideError(){
        this.setShowAlert(false);
    },
  },
  created(){
    this.clearData();
  }
}
</script>

<style scoped>

</style>