<template>
<b-form-group>
    <b-button 
        :pressed="innerValue==option.value" 
        @click="innerValue=option.value" 
        v-for="option in options" 
        class="avo-basic-btn mx-1"
        :key="option.value">
        {{ option.text }}
    </b-button>
</b-form-group>
</template>

<script>
export default {
    'name': 'ButtonSelect',
    props: ['value', 'options'],
    data: function() {
        return {
            innerValue: this.value,
        }
    },
    watch: {
        innerValue: function(newValue, oldValue) {
            this.$emit('input', newValue);
        },
    },
}
</script>