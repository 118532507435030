<template>
<div>
    <b-row>
        <b-col>
            <b-alert
                v-model="showAlert"
                variant="danger"
                dismissible>
                <b>{{alertMessage}}</b>
            </b-alert>
        </b-col>
    </b-row>
    <b-row class="mt-4">
        <b-col>
            <div class="avo-header-text-med mb-3">CPT Summary Table</div>
            <b-overlay :show="codesLoading" variant="white" style="height:75%">
                <b-table-simple sticky-header borderless class="avo-simple-table mb-0">
                    <b-thead style="border-bottom:solid 1px #E2E4EB;font-size:14px">
                        <b-tr>
                            <b-th>
                                CODE
                            </b-th>
                            <b-th>
                                DESC
                            </b-th>
                            <b-th>
                                LAST PAYMENT ID
                            </b-th>
                            <b-th>
                                RESPONSIBLE
                            </b-th>
                            <b-th class="text-right">
                                CHARGED
                            </b-th>
                            <b-th class="text-right">
                                PAID
                            </b-th>
                            <b-th class="text-right">
                                ADJ.
                            </b-th>
                            <b-th class="text-right">
                                BALANCE
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="code in cptCodeReportData.codes" :key="code.claim_code_id" style="border-bottom:solid 1px #E2E4EB">
                            <b-td>
                                {{code.code}}
                            </b-td>
                            <b-td>
                                <span class="avo-text-light-small">{{code.short}}</span>
                            </b-td>
                            <b-td>
                                <span v-if="code.latest_payment_id">#{{code.latest_payment_id}}</span>
                                <span v-else>N/A</span>
                            </b-td>
                            <b-td>
                                {{code.responsibility_type || code.responsibility_type == 0 ? cptCodeReportData.responsibility_map[code.responsibility_type] : 'N/A'}}
                            </b-td>
                            <b-td class="text-right">
                                ${{calculateChargedAmount(code)}}
                            </b-td>
                            <b-td class="text-right">
                                ${{(Number(code.total_patient_payments || 0) + Number(code.total_payer_payments || 0)).toFixed(2)}}
                            </b-td>
                            <b-td class="text-right">
                                ${{(Number(code.total_adjustments || 0)).toFixed(2)}}
                            </b-td>
                            <b-td class="text-right">
                                ${{calculateCodeBalance(code)}}
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
                <b-table-simple>
                <b-tfoot style="border:solid 1px #E2E4EB;border-top:0px;font-size:14px;">
                        <b-tr>
                            <b-th colspan="11">
                                Current Totals
                            </b-th>
                            <b-th>
                            </b-th>
                            <b-th>
                            </b-th>
                            <b-th>
                            </b-th>
                            <b-th>
                            </b-th>
                            <b-th>
                            </b-th>
                            <b-th>
                            </b-th>
                            <b-th>
                            </b-th>
                            <b-th>
                            </b-th>
                            <b-th>
                            </b-th>
                            <b-th>
                            </b-th>
                            <b-th>
                            </b-th>
                            <b-th>
                            </b-th>
                            <b-th>
                            </b-th>
                            <b-th class="text-right">
                                ${{groupedCodesAndTotals.totalCharged}}
                            </b-th>
                            <b-th class="text-right">
                                ${{groupedCodesAndTotals.totalPaid.toFixed(2)}}
                            </b-th>
                            <b-th class="text-right">
                                ${{groupedCodesAndTotals.totalAdjustments}}
                            </b-th>
                            <b-th class="text-right">
                                ${{groupedCodesAndTotals.totalBalance}}
                            </b-th>
                        </b-tr>
                    </b-tfoot>
                </b-table-simple>
            </b-overlay>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <div class="d-flex justify-content-between align-items-center my-4">
                <div class="d-flex flex-column">
                    <div class="avo-header-text-med">Itemized Payments</div>
                </div>
            </div>
        </b-col>
    </b-row>
    <itemized-payment-history></itemized-payment-history>
</div>
</template>

<script>
// import Multiselect from 'vue-multiselect'
import { make_url, authenticated_request, alertsMixin } from '../../common.js'
import ItemizedPaymentHistory from './ItemizedPaymentHistory.vue'


export default {
    name: 'PaymentsBilling',
    props: [],
    mixins: [alertsMixin],
    components: {
        ItemizedPaymentHistory
    },
    data: function(){
            return {
                codesLoading: true,
                cptCodeReportData: {},
                eras: [],
            }
    },
    methods: {
        getCodes: function(){
            const url = make_url("/reports/claims/" + this.claim.claim_id + "/cpt_codes");
            const self = this;
            const promise = authenticated_request({
                method: "get",
                url: url
            }).then(function(response){
                self.cptCodeReportData = response.data;
            }).catch(function(error){
                console.log(error.response)
            }).finally(()=>{
                self.codesLoading = false;
            });
            return promise;
        },
        calculateChargedAmount(code){
            return Number(((code.unit_charge || 0) * (code.units || 0))).toFixed(2)
        },
        calculateCodeBalance(code){
            return Number(((code.unit_charge || 0) * (code.units || 0)) - (Number(code.total_adjustments || 0) + Number(code.total_patient_payments || 0) + Number(code.total_payer_payments || 0))).toFixed(2)
        },

        //error methods
        showError(alertMessage){
            this.setShowAlert(true, alertMessage);
        },
        hideError(){
            this.setShowAlert(false);
        },
        
    },
    computed: {
        claim() {
            return this.$store.state.claims.workedClaim;
        },
        groupedCodesAndTotals(){
            var returnObj = {
                payerResponsibilityCodes: [],
                payerResponsibilityTotal: 0,
                patientResponsibilityCodes: [],
                patientResponsibilityTotal: 0,
                otherCodes: [],
                otherTotal: 0,

                totalCharged: 0,
                totalPaid: 0,
                totalAdjustments: 0,
                totalBalance: 0,
            }
            if(!this.cptCodeReportData.codes){
                return returnObj 
            }

            this.cptCodeReportData.codes.forEach(function(code){
                returnObj.totalAdjustments += (Number(code.total_adjustments || 0))
                returnObj.totalPaid += (Number(code.total_patient_payments || 0) + Number(code.total_payer_payments || 0))
                if(code.responsibility_type == 1){
                    returnObj.patientResponsibilityCodes.push(code);
                    returnObj.patientResponsibilityTotal += Number(code.patient_responsibility || 0)
                } else if(code.responsibility_type == 2){
                    returnObj.payerResponsibilityCodes.push(code);
                    returnObj.payerResponsibilityTotal += Number(code.payer_responsibility || 0)
                } else if(code.responsibility_type == 3) {
                    returnObj.payerResponsibilityCodes.push(code);
                    returnObj.patientResponsibilityCodes.push(code);
                    returnObj.payerResponsibilityTotal += Number(code.payer_responsibility || 0)
                    returnObj.patientResponsibilityTotal += Number(code.patient_responsibility || 0)
                } else {
                    returnObj.otherCodes.push(code);
                    returnObj.otherTotal += Number(((code.unit_charge || 0) * (code.units || 0)))
                }
            });
            returnObj.totalCharged = (returnObj.payerResponsibilityTotal + returnObj.patientResponsibilityTotal + returnObj.otherTotal).toFixed(2)
            returnObj.totalBalance = (returnObj.totalCharged - returnObj.totalPaid - returnObj.totalAdjustments).toFixed(2)
            returnObj.totalAdjustments = (returnObj.totalAdjustments).toFixed(2)
            return returnObj
        },
        updateBilling: function(){
            return this.$store.state.claims.updateBilling;
        }
    },
    watch:{
        updateBilling: function() {
            if (this.updateBilling){
                this.getCodes();
                this.$store.commit("setUpdateBilling", false);
            }
        },
    },
    created() {
    },
    mounted: function() {
        this.getCodes();
        const self = this;
        authenticated_request({
            method: "get",
            url: make_url("/claims/" + this.claim.claim_id + "/payments/eras")
        }).then(function(response){
            self.eras = (response.data.eras || [])
        }).catch(function(errors){
            self.showError(errors);
        });
}
}
</script>

<style scoped>
.alert {
    background: #FDEEED 0% 0% no-repeat padding-box;
    border: 1px solid var(--red);
    border-radius: 5px;
    text-align: center;
    letter-spacing: 0px;
    color: var(--red);
}
.claim-sidebar {
    border-right: 1px solid #c9d2df;
}
.nav {
    display: unset;
}
.nav-claim .nav-link {
    color: var(--gray);
}
.nav-claim .nav-link:hover{
    border:none;
    color: var(--dark);
}
.avo-primary-btn {
    margin: 5px;
    min-width: 0px;
    width: 50px;
}
/* nav bar styling (overrides) */
.nav-section {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #E6EEF9;
    margin-left: -35px;
    margin-right: -35px;
    padding-right: 35px;
    padding-left: 35px;
}
.avo-simple-table {
    border:solid 1px #E2E4EB;
    font-size:14px;
    max-height:100%
}
.billing-summary {
    border: 1px solid #c9d2df;
    border-radius: 5px;
    background: #F8FAFD;
    padding:16px;
    padding-bottom:8px;
}
.total-paid-billing {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: -8px;
    margin-right: -8px;
    padding-right: 8px;
    height: 32px;
    border-radius: 10px;
    font-size: 14px;
    color:white;
    background: transparent linear-gradient(102deg, #33487D 0%, #223053 100%) 0% 0% no-repeat padding-box;
}
.effective-box{
    padding:5px;
    padding-left: 10px;
    padding-right: 10px;
    background:#eef1fa;
    border-radius:5px;
}
</style>